import { Button, CircularProgress, FormControl, FormErrorMessage, FormLabel, GridItem, HStack, Heading, Input, SimpleGrid, Stack, Textarea } from '@chakra-ui/react'
import emailjs from '@emailjs/browser'
import { Select } from 'chakra-react-select'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import React, { useState } from 'react'
import {
    browserName,
    deviceType, engineName, engineVersion,
    isBrowser, isMobile,
    osName, osVersion,
} from 'react-device-detect'
import { Controller, useForm } from 'react-hook-form'
import { db } from '../../firebase/config'
import { useCustomToast } from '../../hooks/customToast'
import { COUNTRIES, sendNotification, teamEmailIds } from '../../utils'
import { inputProps } from '../OldReviewProcess/components/step3'

const Contact = () => {

    const { addToast } = useCustomToast()
    const [isSubmitting, setIsSubmitting] = useState(false)


    const countryOptions = COUNTRIES.map(({ name, iso }) => ({
        label: name,
        value: iso,
    }))

    const forOptions = [
        {
            label: 'Development',
            value: 'development',
        },
        {
            label: 'Support',
            value: 'support',
        },
        {
            label: 'Sales',
            value: 'sales',
        },
        {
            label: 'Admin',
            value: 'admin',
        },
    ]

    const initialInputs = {
        name: '',
        email: '',
        for: '',
        phoneNumber: '',
        message: '',

    }
    const {
        handleSubmit,
        register,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ...initialInputs,
        },
    })

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true)
            const resip = await fetch('https://api.ipify.org/?format=json')
            const { ip } = await resip.json()
            teamEmailIds.forEach(teamEmail => {
                const notificationPayload = {
                    title: `Contact Page`,
                    message: `
                    <b>${values?.name} (${values?.email || values?.phoneNumber})</b> has sent you a message from contact page for the "${values?.for?.label}":
                    <b>Message: "${values?.message}"</b></br>
                    <b>Browser: ${browserName}</b></br> 
                    <b>OS-Version: ${osName}-(${osVersion})</b></br> 
                    <b>Device: ${deviceType}</b></br> 
                    <b>IP: ${ip}</b>
                    `,
                    sender_id: values.email,
                    receiver_id: teamEmail,
                }
                sendNotification(notificationPayload)
            })
            const templateParams = {
                to_email: 'team@sendmea.io',
                from_name: values?.name,
                message: `
                <b>${values?.name} (${values?.email || values?.phoneNumber})</b> has sent you a message from contact page for the "${values?.for?.label}":
                <b>Message: "${values?.message}"</b></br> 
                <b>Browser: ${browserName}</b></br> 
                <b>OS-Version: ${osName}-(${osVersion})</b></br> 
                <b>Device: ${deviceType}</b></br> 
                <b>IP: ${ip}</b>
                `,
            }

            const response = await emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_CONTACT_SUPPORT_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_PUBLIC_KEY
            )
            if (response.status === 200) {
                addToast({
                    title: 'Message Status!',
                    description: `Message Sent Successfully`,
                    status: 'success',
                    variant: 'left-accent',
                })
                await addDoc(collection(db, 'logs'), {
                    createdAt: serverTimestamp(),
                    name: values?.name,
                    for: values?.for?.label,
                    message: values?.message,
                    type: 'contact_support',
                    userEmail: values?.email || ' ',
                    userPhone: values?.phoneNumber || ' ',
                    ip, isBrowser, isMobile, browserName,
                    deviceType, engineName, engineVersion, osName, osVersion,
                })
                reset()
            }
            setIsSubmitting(false)
        } catch (error) {
            setIsSubmitting(false)
            console.log(error)
            addToast({
                title: 'Error',
                description: error?.message || error,
                status: 'error',
                variant: 'left-accent',
            })
        }
    }

    return (
        <Stack
            py={{ base: 4, sm: 6, md: 8 }}
            px={{ base: 2, sm: 4, md: 8 }}
            width='full'
            maxW='container.md'
            mx={'auto'}
            mt='10'
        >
            <Stack
                py={{ base: 4, sm: 6, md: 8 }}
                px={{ base: 2, sm: 4, md: 8 }}
                bg={'gray.50'}
                rounded={'xl'}
                spacing={'4'}
            >
                <HStack w='full' justifyContent='space-between' alignItems='flex-start'>
                    <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
                        Contact Us
                    </Heading>
                </HStack>
                <SimpleGrid
                    as={'form'}
                    mt={10}
                    onSubmit={handleSubmit(onSubmit)}
                    columns={12}
                    spacing={[2, 4, 6]}
                    sx={{
                        'input, select, textarea': {
                            color: 'gray.700',
                        },
                    }}
                >
                    <FormControl isInvalid={!!errors?.name} as={GridItem} colSpan={[12]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                            Name
                        </FormLabel>
                        <Input
                            size={['sm', 'md', 'lg']}
                            placeholder='Your name'
                            bg={'gray.100'}
                            border={0}
                            color={'gray.500'}
                            _placeholder={{
                                color: 'gray.500',
                            }}
                            {...register('name', {
                                required: 'This is required',
                            })}
                        />

                        {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={!!errors?.email} mb={'2'} as={GridItem} colSpan={[12]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                            Email Address
                        </FormLabel>
                        <Input
                            placeholder='Email'
                            bg={'gray.100'}
                            border={0}
                            color={'gray.800'}
                            _placeholder={{
                                color: 'gray.500',
                            }}
                            {...register('email', {
                                required: 'This is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                            })}
                        />
                        {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={!!errors?.phoneNumber} mb={'2'} as={GridItem} colSpan={[12]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                            Phone Number
                        </FormLabel>
                        <Input
                            w={'100%'}
                            mt={{ base: '2', sm: 0 }}
                            type='tel'
                            isInvalid={errors?.phoneNumber}
                            placeholder={'2021233456'}
                            bg={'gray.100'}
                            border={0}
                            color={'gray.800'}
                            _placeholder={{
                                color: 'gray.500',
                            }}
                            {...register('phoneNumber', {
                                maxLength: { value: 10, message: 'Not a valid phone number, too many digits' },
                                setValueAs: (value) => {
                                    let newInput = value.replace(/^\+[0-9]/, '')
                                    value = newInput.replace(/\D/g, '')
                                    value.replace(/^\+[0-9]{1,3}(\s|\-)/, '')
                                    return value
                                },
                                pattern: !watch('email')
                                    ? {
                                        value: /^\d{3}-\d{3}-\d{4}$|^\d{10}$/i,
                                        message: 'Invalid phone number',
                                    }
                                    : null,
                            })}
                        />
                        {(errors.phoneNumber) && (
                            <FormErrorMessage>
                                {errors.phoneNumber?.message}
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <Controller
                        control={control}
                        name='for'
                        rules={{ required: 'Select one' }}
                        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                            <FormControl colSpan={[12]} as={GridItem} isInvalid={!!error}>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                    For
                                </FormLabel>
                                <Select
                                    name={name}
                                    ref={ref}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    options={forOptions}
                                    placeholder='Select purpose'
                                    closeMenuOnSelect={false}
                                />
                                {(error) && (
                                    <FormErrorMessage>
                                        {error?.message}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                        )}
                    />
                    <FormControl isInvalid={!!errors?.message} as={GridItem} colSpan={[12]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                            Message:
                        </FormLabel>
                        <Textarea
                            {...inputProps}
                            placeholder={'Your Message'}
                            py={2}
                            noOfLines={4}
                            resize={"none"}
                            {...register('message', {
                                required: 'This is required',
                                maxLength: {
                                    value: 170,
                                    message: 'should be less than 170 characters',
                                },
                            })}
                        />
                        {errors.message && <FormErrorMessage>{errors.message.message}</FormErrorMessage>}
                    </FormControl>

                    <GridItem colSpan={[12]}>
                        <HStack justifyContent='flex-start' alignItems='center' spacing={3} mt={3}>
                            <Button
                                type='submit'
                                disabled={isSubmitting}
                                leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
                                variant='solid'
                            >
                                Submit
                            </Button>
                        </HStack>
                    </GridItem>
                </SimpleGrid>
            </Stack>

        </Stack >
    )
}

export default Contact