import { Text } from '@chakra-ui/react'

const About = () => {
  return (
    <>
      <div>
        <Text p={16} minH={'md'}>
          About
        </Text>
      </div>
    </>
  )
}

export default About
