import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Circle,
  Flex,
  HStack,
  Icon,
  useColorModeValue as mode,
  Text,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react'
import React from 'react'
import { FiMinus } from 'react-icons/fi'
import { faqs } from './data'

function Faq() {
  return (
    <>

      <Flex justify={'center'} alignItems='center' direction={'column'}>
        <Flex
          direction={useBreakpointValue({ base: 'column', md: 'row', sm: 'column' })}
          w='100%'
          py='6'
          mb='16'
          justify='center'
          alignContent={'center'}
        >
          <Box px='4' w={useBreakpointValue({ base: 'full', md: '22rem', sm: 'full' })}>
            <Text
              color='brand.1'
              fontSize={['1.85rem', '2rem']}
              fontWeight={'500'}
              my={'8'}
              textAlign={'center'}
            >
              Send<chakra.span color={'brand.2'}>mea </chakra.span> FAQS
            </Text>
          </Box>
          <Box
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'md'}
            p={8}
          >
            <Accordion defaultIndex={[0]} allowMultiple>
              {faqs.map((faq) => (
                <AccordionItem p={['2', '4']} key={faq.id}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton _expanded={{ bg: 'brand.1', color: 'white' }}>
                        <Box as='section' flex='1' textAlign='left'>
                          {isExpanded ? (
                            <Text fontSize={['1rem', '1.2rem']} color='white' fontWeight={'400'}>
                              {' '}
                              {faq.question}
                            </Text>
                          ) : (
                            <Text fontSize={['1rem', '1.2rem']} color='gray.500' fontWeight={'400'}>
                              {faq.question}
                            </Text>
                          )}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel bg={'rgba(180, 224, 226, 0.1)'} pb={4}>
                        {faq.answers.map((answer) => (
                          <HStack key={answer} as='li' spacing='3'>
                            <Circle size='6' bg={mode('blue.50', 'whiteAlpha.50')}>
                              <Icon as={FiMinus} color='accent' />
                            </Circle>
                            <Text color='muted'>{answer}</Text>
                          </HStack>
                        ))}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default Faq
