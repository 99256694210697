import { Text, chakra } from '@chakra-ui/react'
import React, { useRef } from 'react'
import RecordView from './recorder';
import { convertToMb } from '../../../utils';
import { useCustomToast } from '../../../hooks/customToast';

function Step1({ setStep, step, useForm, forRecorder, campaign }) {
    const { setValue } = useForm;
    const uploadInputRef = useRef(null)
    const { addToast } = useCustomToast()

    const handleUploadVideo = async (e) => {
        let fileObj = e.target.files[0];
        try {
            let sizeInMB = convertToMb(fileObj);
            if (fileObj && !fileObj.type.includes("video")) {
                return addToast({ title: 'Video', description: "Can only upload videos", status: 'error' })
            }
            else if ((+sizeInMB) > 150 || (+sizeInMB) < 0.230) {
                addToast({ title: 'Video', description: sizeInMB < 0.230 ? "Size is less than 230kb" : "Size is greater than 150mb", status: 'error' })
            }
            else {
                if (typeof (fileObj) === 'object') {
                    fileObj = URL.createObjectURL(fileObj)
                }
                setValue("testimonial", fileObj)
            }
        } catch (error) {
            addToast({ title: 'Video', description: error.message, status: 'error' })
        }
    }

    return (
        <chakra.div >
            <RecordView
                setStep={setStep}
                step={step}
                forRecorder={forRecorder}
                campaign={campaign}
                useForm={useForm}
                handleUploadVideo={handleUploadVideo}
            />

            <Text
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                cursor={'pointer'}
                textAlign={'center'}
            >Already have a video <chakra.span color={'brand.2'}>
                    click here to upload?
                </chakra.span></Text>
            <input
                ref={uploadInputRef}
                type='file'
                accept='video/*'
                style={{ display: 'none' }}
                onChange={handleUploadVideo}
            />
        </chakra.div >
    )
}

export default Step1