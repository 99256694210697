import React, { useEffect } from 'react'
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent'
import * as ReactGA from 'react-ga'

export const initGA = (id) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(id)
  }
}

const handleAcceptCookie = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
  }
  loadNewChatbot()
}

const handleDeclineCookie = () => {
  Cookies.remove('_ga')
  Cookies.remove('_gat')
  Cookies.remove('_gid')
  loadNewChatbot()
}

const loadNewChatbot = () => {
  const BASE_URL = 'https://app.fastbots.ai/embed.js'
  const script = document.createElement('script')
  script.src = BASE_URL
  script.setAttribute('data-bot-id', 'cllmwwo5z00bfpr90hget50q7')
  script.setAttribute(
    'data-fabicon',
    'http://d7keiwzj12p9.cloudfront.net/chatbubbles/reduced-sendmea-plane-1697163818624.webp'
  )
  script.defer = true
  script.async = true

  document.head.appendChild(script)
}


export const ConsentCookie = () => {
  useEffect(() => {
    if (getCookieConsentValue() !== undefined) {
      loadNewChatbot()
    }
  }, [getCookieConsentValue()])
  return (
    <div>
      <CookieConsent
        // uncomment below to test
        // debug={true}
        style={{
          background: '#00989d',
          fontSize: '1rem',
          padding: '15px 5px',
        }}
        buttonStyle={{
          background: '#edf2f6',
          color: '#282c34',
          fontWeight: '5px',
          padding: '10px',
          borderRadius: '5px',
          margin: '10px',
        }}
        disableButtonStyles={{
          background: '#edf2f6',
          color: '#282c34',
          fontWeight: '5px',
          padding: '10%',
          borderRadius: '5px',
          margin: '20%',
        }}
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This website uses cookies to enhance the user experience. View{' '}
        <span style={{ color: 'red' }}>
          <a href='/policy'>Privacy Policy </a>
        </span>
        to learn more.
      </CookieConsent>
    </div>
  )
}
