import { chakra, AspectRatio, Box, Button, Container, Icon, Image, Stack, Text, Input, useDisclosure, } from "@chakra-ui/react";

import { useEffect, useRef, useState } from "react";
import { BsStopCircleFill, BsFillRecordCircleFill } from 'react-icons/bs'
import { useReactMediaRecorder } from "react-media-recorder";
import { RecorderAlertBox } from "../../OldReviewProcess/components/alertBox";
import HelpRecorderModal from "../../OldReviewProcess/components/helpModal";

const videoLength = 120;
const RecordView = ({ recorderView, nextStep, handleVideo, state, getData }) => {
    const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, error } = useReactMediaRecorder({ video: recorderView, askPermissionOnMount: recorderView });
    const videoRef = useRef(null);
    const [time, setTime] = useState(0)
    const [myInterval, setMyInterval] = useState('')
    const [stream, setStream] = useState(null)
    const [title, setTitle] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        handleStreamOn()
        return () => {
            handleStreamOff()
        }
    }, [])

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);


    const handleStartRec = () => {
        startRecording()
        setTime(1)
        setMyInterval(setInterval(() => {
            setTime(prev => prev + 1)
        }, 1000))
    }

    useEffect(() => {
        if (time === videoLength) {
            stopRecording()
            setTime(0)
        }
    }, [time])

    const handleStopRec = () => {
        stopRecording()
        setTime(0)
        clearInterval(myInterval)
    }

    const handleSave = async () => {
        if (mediaBlobUrl) {
            const file = await fetch(mediaBlobUrl).then(r => r.blob());
            handleVideo(null, file, title)
            handleStreamOff()
        }
        (state?.welcomeVideo) && nextStep()
    }

    useEffect(() => {
        return () => {
            handleStreamOff()
        }
    }, [])

    const handleStreamOff = () => {
        stream?.getVideoTracks().forEach(stream => stream.stop());
        stream?.getAudioTracks().forEach(stream => stream.stop());
        setStream(null)
    }

    const handleStreamOn = async () => {
        const mediaStream = await navigator?.mediaDevices?.getUserMedia({
            video: true
        })
        setStream(mediaStream)
    }

    return (
        <chakra.div position={'relative'}>
            <div>
                <Container maxW={'400px'} position={'relative'} my={'4'} px={{ base: 0 }}>
                    <Input
                        size={['sm', 'md', 'lg']}
                        placeholder='Welcome Video Title'
                        bg={'gray.100'}
                        border={0}
                        _placeholder={{
                            color: 'gray.500',
                        }}
                        mb='2'
                        onChange={(e) => setTitle(e.target.value)}
                        value={title || state?.welcomeVideoTitle}
                    />
                    {!!time && <Text display={'inline'} color={'red.500'} fontWeight={'semibold'} textAlign={'center'} sx={{ position: 'absolute', bottom: 0, right: 0, left: 0, mx: 'auto', zIndex: 2 }}>{new Date((time - 1) * 1000).toISOString().slice(14, 19)}</Text>}
                    {!error && status === 'idle' && !state?.welcomeVideo && <Icon as={BsFillRecordCircleFill} onClick={handleStartRec} color={'brand.1'} width={'50px'} height={'50px'} borderRadius={'50%'} _hover={{ cursor: 'pointer', transform: 'scale(1.2)', boxShadow: '0 0 10px 0px #00989e', }} sx={{ transition: 'all .2s ease-in-out', position: 'absolute', bottom: '1.5rem', right: 0, left: 0, mx: 'auto', zIndex: 1 }} />}
                    {!error && ['recording'].includes(status) && <Icon as={BsStopCircleFill} onClick={handleStopRec} color={'red.500'} width={'50px'} height={'50px'} borderRadius={'50%'} _hover={{ cursor: 'pointer', transform: 'scale(1.2)', boxShadow: '0 0 10px 0px #e53e3e', }} sx={{ transition: 'all .2s ease-in-out', position: 'absolute', bottom: '1.5rem', right: 0, left: 0, mx: 'auto', zIndex: 2 }} />}
                    {(mediaBlobUrl || state?.welcomeVideo) && <AspectRatio ratio={{ base: 6 / 7.1, sm: 6 / 4 }} bg={'blackAlpha.300'} borderRadius={'8px'}>
                        <Image
                            borderRadius={'8px'}
                            objectFit='cover'
                            w={'100%'}
                            as={'video'} src={mediaBlobUrl || state?.welcomeVideo} autoPlay loop controls playsInline />
                    </AspectRatio>}
                    <AspectRatio ratio={{ base: 6 / 7.1, sm: 6 / 4 }} bg={'blackAlpha.300'} borderRadius={'8px'} display={(mediaBlobUrl || state?.welcomeVideo) && "none"}>
                        <video style={{
                            borderRadius: '8px', width: "100%",
                            objectPosition: 'center'
                        }} ref={videoRef} id="liveVideo" autoPlay loop playsInline />
                    </AspectRatio>
                    {(status === "stopped" || state?.welcomeVideo) && <Box my={'4'}
                        sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;" }}
                        p={'4'}
                        bg={'teal.50'}
                        borderRadius={'8px'}
                    >
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Button type='button' variant={'outline'} borderRadius={'10'} minW={'40%'} onClick={() => { clearBlobUrl(); getData({ 'welcomeVideo': '' }) }}>Try again</Button>

                            <Button type='button' variant={'solid'} borderRadius={'10'} minW={'40%'} onClick={handleSave}>Save</Button>
                        </Stack>
                    </Box>}
                </Container>
                {(error) && <RecorderAlertBox onOpen={onOpen} />}
                <HelpRecorderModal onClose={onClose} isOpen={isOpen} />
            </div>

        </chakra.div>
    )
};

export default RecordView;