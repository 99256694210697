import {
  Button,
  FormControl,
  FormErrorMessage,
  Flex,
  Input,
  Stack,
  Text,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  IconButton,
  AlertDescription,
  AlertTitle,
  AlertIcon,
  Alert,
  Box,
  chakra,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { auth, db } from '../../firebase/config'
import { confirmPasswordReset, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { useCustomToast } from '../../hooks/customToast'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { FaLock, FaLockOpen } from 'react-icons/fa'
import { doc, getDoc } from 'firebase/firestore'
import { saveLoginLogs } from '../Login/utils'
import { userState } from '../../recoil/atoms/auth'
import { useSetRecoilState } from 'recoil'

const SuccessAlert = () => {
  return (
    <Alert status='success'>
      <AlertIcon />
      <Box>
        <AlertTitle>Password Changed!</AlertTitle>
        <AlertDescription>
          Your old password has been replaced successfully!
        </AlertDescription>
      </Box>
    </Alert>
  )
}

export default function CustomResetPassword() {
  const { addToast } = useCustomToast()

  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      newPassword: '',
    },
  })
  const setUser = useSetRecoilState(userState)
  const [resetCode, setResetCode] = useState('')
  const [showsSuccessAlert, setShowSuccessAlert] = useState(false)
  const searchParams = new URLSearchParams(window.location.search)
  const mode = searchParams.get('mode')
  const [showPass, setShowPass] = useState({
    newPassword: false,
  })

  const handleShowPass = (name) => {
    return setShowPass((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const setNewPassword = async (values) => {
    try {
      await confirmPasswordReset(auth, resetCode, values.newPassword)
      setShowSuccessAlert(true)
    } catch (error) {
      console.log(error)
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
    }
  }

  useEffect(() => {

    const resetCode = searchParams.get('oobCode')
    if (resetCode && mode) {
      setResetCode(resetCode)
    } else {
      addToast({
        title: 'Error',
        description: 'Invalid URL',
        status: 'error',
        variant: 'left-accent',
        duration: 5000,
      })
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])
  const colorUseHook = useColorModeValue('white', 'gray.700')

  const saved_email = window.localStorage.getItem("emailForSignIn");

  useEffect(() => {
    if (mode === 'signIn') {
      !!saved_email && reset({ 'email': saved_email })
      handleEmailLink()
    }
  }, []);

  const handleEmailLink = async (email = null) => {
    try {
      if (isSignInWithEmailLink(auth, window.location.href) && (!!saved_email || !!email)) {
        const { user } = await signInWithEmailLink(auth, (saved_email || email), window.location.href);

        const docRef = doc(db, 'users', user.uid)
        const userSnap = await getDoc(docRef)

        const docId = await saveLoginLogs({
          userEmail: user.email || '',
          userId: user.uid
        })
        setUser({ ...userSnap.data(), loginId: docId })
      }
    } catch (err) {
      setError('email', {
        type: 'custom',
        message: err.message
      })
    }
    window.localStorage.setItem("emailForSignIn", '');
  }

  const handleLinkSignIn = (values) => {
    handleEmailLink(values.email)
  }

  return (
    <Flex
      minH={'50vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('white', 'gray.800')}
      spacing={8}
      w='full'
      maxW={['full', '550px']}
      px={[2, 6]}
      mx={'auto'}
    >
      {mode === 'signIn' ?
        <Stack>
          {/* <Text>Password less login</Text> */}
          <Stack as={'form'} onSubmit={handleSubmit(handleLinkSignIn)} w='full'>
            <Stack
              spacing={4}
              w={'full'}
              bg={colorUseHook}
              rounded={'xl'}
              boxShadow={
                '-1px -6px 25px -5px rgba(0, 0, 0, 0.1), 2px 11px 10px -5px rgba(0, 0, 0, 0.04)'
              }
              py={6}
              px={[3, 6]}
            >
              <Text
                color='brand.1'
                fontWeight='bold'
                lineHeight={1.1}
                fontSize={{ base: 'lg', sm: '2xl' }}
              >
                Magic Link
                <chakra.span ml={2} color='brand.2'>
                  Login!
                </chakra.span>
              </Text>
              <Text
                fontSize={{ base: 'sm', sm: 'md' }}
                color={'gray.800'}
              >
                Enter Email Address
              </Text>
              <FormControl isInvalid={!!errors?.email} as={Stack} spacing={4}>

                <Input
                  placeholder='Email'
                  type={'email'}
                  bg={'gray.100'}
                  border={0}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  {...register('email', {
                    required: 'This is required',
                  })}
                />

                {errors.email && (
                  <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                )}
              </FormControl>
              <Stack spacing={6}>
                <Button
                  type='submit'
                  isLoading={isSubmitting || saved_email}
                  variant={'solid'}
                  disabled={resetCode.length < 5}
                >
                  Sign In
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        : mode === 'resetPassword' ? <Stack as={'form'} onSubmit={handleSubmit(setNewPassword)} w='full'>
          <Stack
            spacing={4}
            w={'full'}
            bg={colorUseHook}
            rounded={'xl'}
            boxShadow={
              '-1px -6px 25px -5px rgba(0, 0, 0, 0.1), 2px 11px 10px -5px rgba(0, 0, 0, 0.04)'
            }
            py={6}
            px={[3, 6]}
          >
            {showsSuccessAlert ? (
              <>
                <SuccessAlert navigate={navigate} />
                <Button variant='solid' onClick={() => navigate('/login')}>
                  Go To Login
                </Button>
              </>
            ) : (
              <>
                <Text
                  color='brand.1'
                  fontWeight='bold'
                  lineHeight={1.1}
                  fontSize={{ base: 'lg', sm: '2xl' }}
                >
                  Reset Your
                  <chakra.span ml={2} color='brand.2'>
                    Password!
                  </chakra.span>
                </Text>
                <Text
                  fontSize={{ base: 'sm', sm: 'md' }}
                  color={'gray.800'}
                >
                  Enter the new password
                </Text>
                <FormControl isInvalid={!!errors?.newPassword} as={Stack} spacing={4}>
                  <InputGroup>
                    <Input
                      placeholder='New Password'
                      type={showPass.newPassword ? 'text' : 'password'}
                      bg={'gray.100'}
                      border={0}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      {...register('newPassword', {
                        required: 'This is required',
                        minLength: { value: 6, message: 'Minimum length should be 6' },
                      })}
                    />
                    <InputRightElement>
                      <IconButton
                        variant='ghost'
                        color='brand.1'
                        size='sm'
                        onClick={() => handleShowPass('newPassword')}
                      >
                        {showPass.newPassword ? <FaLockOpen /> : <FaLock />}
                      </IconButton>
                    </InputRightElement>
                  </InputGroup>

                  {errors.newPassword && (
                    <FormErrorMessage>{errors.newPassword.message}</FormErrorMessage>
                  )}
                </FormControl>
                <Stack spacing={6}>
                  <Button
                    type='submit'
                    isLoading={isSubmitting}
                    variant={'solid'}
                    disabled={resetCode.length < 5}
                  >
                    Confirm New Password
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Stack> : <Stack>
          <Text>Page not found | 404</Text>
        </Stack>}
    </Flex>
  )
}
