import { Button, chakra, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import { doc, updateDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../../firebase/config"

export const WaitModal = ({ isOpen, onClose, watch }) => {
    const [time, setTime] = useState(0)

    const handleDownload = async () => {
        try {
            let anchor = document.createElement('a')
            anchor.href = watch('testimonial')
            anchor.download = `your review.mp4`
            anchor.click()
            anchor.remove()
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (isOpen) {
            const interval = setInterval(() => {
                setTime(prev => prev + 1)
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isOpen]);

    useEffect(() => {
        if (time === 60) {
            handleDownload()
        }
    }, [time])

    const handleUpdateNameEmail = async () => {
        const docId = localStorage.getItem('LogId')
        if (docId) {
            const docRef = doc(db, 'logs', docId)
            await updateDoc(docRef, {
                userEmail: watch('email'),
                userName: watch('name'),
            })
        }
    }

    useEffect(() => {
        if (isOpen && watch('email') && watch('name')) {
            handleUpdateNameEmail()
        }
    }, [watch, isOpen])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent bg={'teal.50'}>
                    <ModalHeader></ModalHeader>
                    <ModalBody>
                        <Text fontSize={'lg'} textAlign={'center'}>
                            Thank you for submitting your video review, depending on internet speed it can take a up to 60 seconds to render and upload. This will close when it is done.
                        </Text>
                        {time > 60 &&
                            <Stack>
                                <Text fontSize={'lg'} textAlign={'center'}>
                                    It looks like your internet connection is slow at the moment download the video locally so you can upload later.
                                </Text>
                                <chakra.div textAlign={'center'}>
                                    <Button
                                        size='xs'
                                        variant={'solid'}
                                        mx={'auto'}
                                        mt={'5'}
                                        onClick={handleDownload}
                                    >
                                        Download video
                                    </Button>
                                </chakra.div>
                            </Stack>}
                    </ModalBody>
                    <VStack alignItems={'center'} m={3}>
                        <Spinner color={'brand.1'} size={'lg'} />
                    </VStack>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}