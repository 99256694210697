import React from 'react'
import { HStack, IconButton, Tooltip } from '@chakra-ui/react'

import { useRecoilValue } from 'recoil'
import { businessDataAtom } from '../../../../recoil/atoms/businessData'
import {
	FaTwitter,
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaGlobe,
	FaTiktok,
	FaYoutube,
} from 'react-icons/fa'

export const MobileBar = () => {
	const businessData = useRecoilValue(businessDataAtom)

	return (
		<>
			<HStack
				spacing={7}
				px={5}
				m={'4'}
				color='white'
				justifyContent='center'
				direction='column'
			>
				{businessData?.social?.youtube && (
					<a href={businessData?.social?.youtube}>
						<Tooltip
							bg='teal.200'
							borderColor='teal.700'
							color='teal.900'
							placement='top'
							label='youtube'
						>
							<IconButton
								aria-label='youtube'
								variant='ghost'
								size='md'
								isRound={true}
								bg={'gray.500'}
								_hover={{
									bg: '#cd486b',
									transform: 'scale(1.05)',
								}}
								icon={<FaYoutube size='25px' />}
							/>
						</Tooltip>
					</a>
				)}
				{businessData?.social?.instagram && (
					<a href={businessData?.social?.instagram}>
						<Tooltip
							bg='teal.200'
							borderColor='teal.700'
							color='teal.900'
							placement='top'
							label='instagram'
						>
							<IconButton
								aria-label='instagram'
								variant='ghost'
								size='md'
								isRound={true}
								bg={'gray.500'}
								_hover={{
									bg: '#cd486b',
									transform: 'scale(1.05)',
								}}
								icon={<FaInstagram size='25px' />}
							/>
						</Tooltip>
					</a>
				)}
				{businessData?.social?.facebook && (
					<a href={businessData?.social?.facebook}>
						<Tooltip
							bg='teal.200'
							borderColor='teal.700'
							color='teal.900'
							placement='top'
							label='facebook'
						>
							<IconButton
								aria-label='facebook'
								variant='ghost'
								size='md'
								isRound={true}
								bg={'gray.500'}
								_hover={{
									bg: '#4267B2',
									transform: 'scale(1.05)',
								}}
								icon={<FaFacebookF size='25px' />}
							/>
						</Tooltip>
					</a>
				)}
				{businessData?.social?.twitter && (
					<a href={businessData?.social?.twitter}>
						<Tooltip
							bg='teal.200'
							borderColor='teal.700'
							color='teal.900'
							placement='top'
							label='twitter'
						>
							<IconButton
								aria-label='twitter'
								variant='ghost'
								size='md'
								isRound={true}
								bg={'gray.500'}
								_hover={{
									transform: 'scale(1.05)',
									bg: 'twitter.500',
								}}
								icon={<FaTwitter size='25px' />}
							/>
						</Tooltip>
					</a>
				)}
				{businessData?.social?.tickTock && (
					<a href={businessData?.social?.tickTock}>
						<Tooltip
							bg='teal.200'
							borderColor='teal.700'
							color='teal.900'
							placement='top'
							label='tiktok'
						>
							<IconButton
								aria-label='ticktock'
								variant='ghost'
								size='md'
								isRound={true}
								bg={'gray.500'}
								_hover={{
									bg: ' #00f2ea',
									color: '#000',
									transform: 'scale(1.05)',
								}}
								icon={<FaTiktok size='25px' />}
							/>
						</Tooltip>
					</a>
				)}
				{businessData?.social?.linkedIn && (
					<a href={businessData?.social?.linkedIn}>
						<Tooltip
							bg='teal.200'
							borderColor='teal.700'
							color='teal.900'
							placement='top'
							label='linkedIn'
						>
							<IconButton
								aria-label='linkedin'
								variant='ghost'
								size='md'
								isRound={true}
								bg={'gray.500'}
								_hover={{
									bg: '#0077b5',
									transform: 'scale(1.05)',
								}}
								icon={<FaLinkedinIn size='25px' />}
							/>
						</Tooltip>
					</a>
				)}
				{businessData?.social?.other && (
					<a href={businessData?.social?.other}>
						<Tooltip
							bg='teal.200'
							borderColor='teal.700'
							color='teal.900'
							placement='top'
							label='other'
						>
							<IconButton
								aria-label='other'
								variant='ghost'
								size='md'
								isRound={true}
								bg={'gray.500'}
								_hover={{
									bg: 'gray.900',
									transform: 'scale(1.05)',
								}}
								icon={<FaGlobe size='25px' />}
							/>
						</Tooltip>
					</a>
				)}
			</HStack>
		</>
	)
}
