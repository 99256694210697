import {
    Divider,
    Flex,
    Icon,
    Link,
    Stack,
    Text,
    Tooltip,
    useColorModeValue,
    VStack
} from '@chakra-ui/react';
import * as React from 'react';
import {
    MdOutlineEmail,
    MdPhone
} from 'react-icons/md';
import Social from '../ProfileModal/SocialMedia';

import { useRecoilValue } from 'recoil';
import { businessDataAtom } from '../../../../recoil/atoms/businessData';

export const Sidebar = () => {
    const businessData = useRecoilValue(businessDataAtom)
    const emailLink = `mailto:${businessData?.email}`
    const phoneLink = `tel:${businessData?.phone}`

    return (

        <Flex as="section" bg="bg-canvas">
            <Flex
                flex="1"
                bg={'rgba(180, 224, 226, 0.1)'}
                overflowY="auto"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                maxW={{
                    base: 'md',
                    sm: 'md',
                }}

                py={{
                    base: '4',
                    sm: '6',
                }}
                px={{
                    base: '4',
                    sm: '2',
                }}
            >
                <Stack justify="space-between" align='center' spacing="1">

                    <VStack>
                        <Tooltip
                            label='email'
                            placement='right'
                            fontSize='md'
                            bg='teal.400'
                            rounded='md'
                            hasArrow
                            closeOnPointerDown>
                            <Link href={emailLink}>

                                <Icon
                                    variant="solid"
                                    as={MdOutlineEmail}
                                    aria-label="Open Menu"
                                    color={'#03989E'}

                                    width="40px"
                                    height="40px"
                                />

                            </Link>
                        </Tooltip>
                        <Tooltip
                            label='phone'
                            placement='right'
                            fontSize='md'
                            bg='teal.400'
                            rounded='md'
                            hasArrow
                            closeOnPointerDown>
                            <Link href={phoneLink}>

                                <Icon
                                    variant="solid"
                                    as={MdPhone}
                                    aria-label="Open Menu"
                                    color={'#03989E'}

                                    width="40px"
                                    height="40px"
                                />


                            </Link>
                        </Tooltip>
                    </VStack>

                    <Stack
                        spacing={{
                            base: '5',
                            sm: '6',
                        }}
                        px={'2'}
                    >

                        <Text color={'#03989E'} fontSize='lg' fontWeight={'bold'} href={phoneLink} ><Link >{businessData?.phoneNumber}</Link></Text>

                        <Divider />
                        <Social />
                    </Stack>
                </Stack>
            </Flex>
        </Flex>
    )
}