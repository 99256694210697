import {
  AspectRatio,
  Box,
  Button,
  chakra,
  Container,
  Icon,
  Image,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import {
  browserName,
  isMobile,
  osName
} from 'react-device-detect'
import { BiTimer } from 'react-icons/bi'
import { BsFillRecordCircleFill, BsStopCircleFill, } from 'react-icons/bs'
import { useRecoilState } from 'recoil'
import { reviewState } from '../../../recoil/atoms/review'
import { secToMin } from '../../../utils'
import GivePermissionModal from './GivePermissionModal'
import { RecorderAlertBox } from './alertBox'
import HelpRecorderModal from './helpModal'

const RecordView = ({
  step,
  setStep,
  useForm: { setValue, watch },
  forRecorder: { campaign, isOpenWelcomeVideo },
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenPermission,
    onOpen: onOpenPermission,
    onClose: onClosePermission,
  } = useDisclosure()
  const videoLength = 121
  const cancelRef = useRef()
  const videoRef = useRef(null)

  const [review, setReview] = useRecoilState(reviewState)

  const [time, setTime] = useState(0)
  const [myInterval, setMyInterval] = useState('')
  const [countdownInterval, setCountdownInterval] = useState('')
  const [stream, setStream] = useState(null)
  const [mediaRecorderState, setMediaRecorder] = useState({})
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null)
  const [error, setError] = useState('')
  const [countdown, setCountdown] = useState(
    campaign?.videoDetails?.countdown ? campaign?.videoDetails?.countdown + 1 : false
  )

  const clearBlobUrl = () => {
    setMediaBlobUrl(null)
  }

  useEffect(() => {
    if (error) {
      onOpenPermission()
    }
  }, [stream, error])

  const handleStreamOn = async () => {
    try {


      const mediaStream = await navigator?.mediaDevices?.getUserMedia({
        video: true,
        audio: true
      })
      const mediaRecorder = new MediaRecorder(mediaStream, MediaRecorder.isTypeSupported('video/webm') ? {
        mimeType: 'video/webm'
      } : {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
        mimeType: 'video/mp4'
      });

      mediaRecorder.addEventListener('dataavailable', event => {
        setMediaBlobUrl(URL.createObjectURL(event.data))
      })

      mediaRecorder.addEventListener("error", (event) => {
        console.error(`error recording stream: ${event.error.name}`);
      });
      setMediaRecorder(mediaRecorder)
      setStream(mediaStream)

    } catch (err) {
      setError(err.message)
    }
  }

  useEffect(() => {
    handleStreamOn()
    return () => {
      handleStreamOff()
    }
  }, [])

  useEffect(() => {
    if (mediaBlobUrl) {
      setValue('testimonial', mediaBlobUrl)
    }
  }, [mediaBlobUrl])

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream
    }
  }, [stream])

  const handleStartRec = () => {
    if (!countdown) {
      mediaRecorderState?.start()
      setTime(1)
      setMyInterval(
        setInterval(() => {
          setTime((prev) => prev + 1)
        }, 1000)
      )
    } else {
      setCountdown(3)
      setCountdownInterval(
        setInterval(() => {
          setCountdown((prev) => prev - 1)
        }, 1000)
      )
      setTimeout(() => {
        mediaRecorderState?.start()
        setTime(1)
        setMyInterval(
          setInterval(() => {
            setTime((prev) => prev + 1)
          }, 1000)
        )
      }, 3 * 1000)
    }
  }

  useEffect(() => {
    if (time === videoLength) {
      handleStopRec(time - 2)
    }
  }, [time])

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(countdownInterval)
    }
  }, [countdown])

  const handleStopRec = (duration = null) => {
    mediaRecorderState?.stop()
    setCountdown(4)
    setTime(0)
    clearInterval(myInterval)
    setValue('duration', duration || time - 1 || 0)
    handleStreamOff()
  }

  const handleBack = async (_) => {
    handleStreamOff()
    if (videoRef.current) {
      videoRef.current.srcObject = null
    }
  }

  const handleNext = async (_) => {
    if (!(mediaRecorderState?.state !== 'paused' && !watch('testimonial') && !mediaBlobUrl)) {
      handleStreamOff()
      setReview({ ...review, testimonial: mediaBlobUrl })
      setStep((prev) => prev + 1)
    }
  }

  const handleStreamOff = () => {
    stream?.getVideoTracks().forEach((stream) => stream.stop())
    stream?.getAudioTracks().forEach((stream) => stream.stop())
    setStream(null)
  }

  const handleTryAgain = async () => {
    clearBlobUrl()
    setCountdown(campaign?.videoDetails?.countdown ? campaign?.videoDetails?.countdown + 1 : false)
    setReview({ ...review, testimonial: '', duration: '' })
    handleStreamOff()
    handleStreamOn()
    setValue('testimonial', '')
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  const videoPreviewRef = useRef(null)
  const [isFirstPlay, setIsFirstPlay] = useState(true)
  const [timeoutId, setTimeoutId] = useState(null)
  const [openPreviewTooltip, setOpenPreviewTooltip] = useState(false)


  useEffect(() => {
    const video = videoPreviewRef.current

    const handlePlay = () => {
      setOpenPreviewTooltip(false)
      if (isFirstPlay) {
        setTimeout(() => {
          setIsFirstPlay(false)
        }, 5000)
      }
    }

    const handlePause = () => {
      setOpenPreviewTooltip(true)
      clearTimeout(timeoutId)
      const newTimeoutId = setTimeout(() => {
        setOpenPreviewTooltip(false)
      }, 5000)
      setTimeoutId(newTimeoutId)
    }

    const handleEnded = () => {
      setOpenPreviewTooltip(false)
    }

    if (video) {
      video.addEventListener('play', handlePlay)
      video.addEventListener('pause', handlePause)
      video.addEventListener('ended', handleEnded)

      return () => {
        video.removeEventListener('play', handlePlay)
        video.removeEventListener('pause', handlePause)
        video.removeEventListener('ended', handleEnded)
      }
    }
  }, [videoPreviewRef.current, mediaRecorderState?.state])

  return (
    <chakra.div position={'relative'} minH={'58vh'} mt={2}>
      <chakra.div id='screenRecorder'>
        <Container
          maxW={'500px'}
          my={{ base: 0, sm: '4' }}
          px={'4'}
          py={'2'}
          border='1px solid'
          borderColor={'gray.200'}
          borderRadius={'8px'}
          sx={{
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
          }}
          position={'relative'}
        >
          {!mediaBlobUrl && !watch('testimonial') && (
            <VStack w='full' mx={'2'} spacing={1} my={2}>
              <Text fontSize={['22px']} color='brand.2' fontWeight='bold' textAlign={'center'}>
                <chakra.span color='teal.500' mr={1}>
                  Record Video Review for
                </chakra.span>
                <chakra.span
                  cursor='pointer'
                  fontWeight='700'
                  fontStyle='oblique'
                  onClick={() =>
                    window.open(`/wall/${campaign?.customUrlName || campaign?.id}`, '_blank')
                  }
                >
                  {campaign?.campaignDetails?.publicCampaignName ||
                    campaign?.campaignDetails?.testimonialCampaignName}
                </chakra.span>
              </Text>
              <Text fontSize={['md', 'xl']} color='gray.500' fontWeight='500' textAlign={"center"}>
                Record a video up to <chakra.span fontWeight={'bold'}>2 minutes </chakra.span>
              </Text>
            </VStack>
          )}
          <Box
            direction={{ base: 'column', sm: 'row' }}
            pb={'2'}
            borderRadius={'8px'}
            position={'relative'}
          >
            {!watch('testimonial') &&
              !mediaBlobUrl &&
              !error &&
              mediaRecorderState?.state === 'inactive' &&
              (countdown === 4 || countdown === false) && (
                <Tooltip
                  label='record'
                  color='white'
                  bg='red.500'
                  hasArrow
                  arrowSize={16}
                  fontSize='1.3rem'
                  fontWeight='medium'
                  rounded='md'
                  defaultIsOpen={!isOpenWelcomeVideo}
                >
                  <chakra.span
                    sx={{
                      position: 'absolute',
                      bottom: ['8%', '10%', '10%'],
                      right: 0,
                      left: 0,
                      mx: 'auto',
                      zIndex: 1,
                    }}
                  >
                    <Icon
                      as={BsFillRecordCircleFill}
                      onClick={handleStartRec}
                      color={'teal.400'}
                      width={'50px'}
                      height={'50px'}
                      borderRadius={'50%'}
                      sx={{
                        transition: 'all .2s ease-in-out',
                        position: 'absolute',
                        bottom: ['6%', '10%', '10%'],
                        right: 0,
                        left: 0,
                        mx: 'auto',
                        zIndex: 1,
                      }}
                      _hover={{
                        cursor: 'pointer',
                        transform: 'scale(1.2)',
                        boxShadow: '0 0 10px 0px #00989e',
                      }}
                    />
                  </chakra.span>
                </Tooltip>
              )}
            {!error && ['recording'].includes(mediaRecorderState?.state) && (
              <Icon
                as={BsStopCircleFill}
                onClick={() => handleStopRec()}
                color={'red.400'}
                width={'50px'}
                height={'50px'}
                borderRadius={'50%'}
                sx={{
                  transition: 'all .2s ease-in-out',
                  position: 'absolute',
                  bottom: ['6%', '10%', '10%'],
                  right: 0,
                  left: 0,
                  mx: 'auto',
                  zIndex: 2,
                }}
                _hover={{
                  cursor: 'pointer',
                  transform: 'scale(1.2)',
                  boxShadow: '0 0 10px 0px #e53e3e',
                }}
              />
            )}
            {(mediaBlobUrl || watch('testimonial')) && (
              <Box>
                <Text color={'brand.3'} fontWeight={'bold'} fontSize={'small'} mx={2}>
                  1/2 step
                </Text>
                <Text fontSize={['22px']} color='brand.2' mx={2} fontWeight='bold'>
                  <chakra.span color='teal.500'>Watch The Video</chakra.span> You Recorded
                </Text>
                <Box position={'relative'}>
                  <Tooltip
                    label='Double check your video before pressing send!'
                    color='white'
                    bg='teal.400'
                    hasArrow
                    arrowSize={16}
                    fontSize={['0.8rem', '1rem']}
                    fontWeight='medium'
                    textAlign='center'
                    rounded='md'
                    isOpen={openPreviewTooltip || isFirstPlay}
                  >
                    <AspectRatio
                      ratio={{ base: 6 / 7.1, sm: 6 / 4 }}
                      bg={'blackAlpha.300'}
                      borderRadius={'20px'}
                      my={2}
                      mx={'2'}
                    >
                      <Image
                        ref={videoPreviewRef}
                        w={'100%'}
                        as={'video'}
                        autoPlay
                        controls
                        playsInline
                        objectFit='contain !important'
                        borderRadius='13.0523px'
                        src={mediaBlobUrl || watch('testimonial')}
                      />
                    </AspectRatio>
                  </Tooltip>
                </Box>
              </Box>
            )}
            <chakra.div position={'relative'}>
              <AspectRatio
                ratio={{ base: 6 / 7.1, sm: 6 / 4 }}
                bg={'blackAlpha.300'}
                borderRadius={'20px'}
                display={(!!mediaBlobUrl || !!watch('testimonial')) && 'none'}
                my={'4'}
                mx={'2'}
              >
                <Box width='full' height='full' borderRadius='13.0523px'>
                  <chakra.video
                    width='100%'
                    height='100%'
                    objectFit='cover'
                    objectPosition={'center'}
                    style={{
                      filter:
                        countdown !== 4 &&
                        countdown !== false &&
                        countdown !== 0 &&
                        'brightness(.4)',
                      borderRadius: 8,
                      width: '100%',
                    }}
                    ref={videoRef}
                    autoPlay
                    loop
                    playsInline
                    muted
                  />
                  {time > 1 && time < videoLength && (
                    <Tag
                      sx={{ position: 'absolute', top: '3%', right: '2%', zIndex: 2 }}
                      size={['md']}
                      fontWeight='bold'
                      bg={time <= 100 ? 'teal.400' : time <= 110 ? 'yellow.600' : 'red.500'}
                      color={'white'}
                    >
                      {secToMin(time - 1)}
                    </Tag>
                  )}
                </Box>
              </AspectRatio>
              <AnimatePresence>
                {countdown === 0 || countdown === 4 || ['recording'].includes(mediaRecorderState?.state) ? null : (
                  <chakra.p
                    as={motion.p}
                    textAlign={'center'}
                    position={'absolute'}
                    key={countdown}
                    initial={{ y: '50%', opacity: 0, scale: 0.5 }}
                    animate={{ y: 0, opacity: 1, scale: 1 }}
                    exit={{ y: 0, opacity: 0 }}
                    transition={{ duration: 0.3, ease: 'ease-out' }}
                    fontSize={'8rem'}
                    color={'white'}
                    top={'15%'}
                    bottom={0}
                    right={0}
                    left={0}
                    m={'auto'}
                    textShadow={'0px 0px 15px #00989e'}
                  >
                    {countdown}
                  </chakra.p>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {error && mediaRecorderState?.state === 'inactive' && (
                  <chakra.p
                    as={motion.p}
                    textAlign={'center'}
                    position={'absolute'}
                    fontSize={['2rem', '3rem']}
                    color={'white'}
                    top={['30%', '15%']}
                    bottom={0}
                    right={0}
                    left={0}
                    textShadow={'0px 0px 15px #f44336'}
                    p={'3'}
                    margin={['auto 20px']}
                  >
                    Permission to record is required
                  </chakra.p>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {mediaBlobUrl || watch('testimonial') || (time > 1 && time < videoLength) ? null : (
                  <chakra.div
                    as={motion.div}
                    position='absolute'
                    right={5}
                    top={2.5}
                    zIndex={'10'}
                  >
                    <Icon
                      as={BiTimer}
                      color={countdown !== false ? 'teal.500' : 'grey.500'}
                      onClick={() => setCountdown(countdown !== 0 && countdown !== 4 ? 4 : false)}
                      fontSize={35}
                      cursor={'pointer'}
                    />
                  </chakra.div>
                )}
              </AnimatePresence>
            </chakra.div>
          </Box>
          {(error || (isMobile && browserName === 'Facebook' && osName === 'Android')) && (
            <RecorderAlertBox onOpen={onOpen} />
          )}
          <Box
            my={'2'}
            sx={{
              boxShadow:
                'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
            }}
            p={'4'}
            bg={'teal.50'}
            borderRadius={'8px'}
          >
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              {mediaRecorderState?.state === 'paused' || watch('testimonial') ? (
                <Button
                  type='button'
                  variant={'outline'}
                  borderRadius={'10'}
                  minW={'40%'}
                  onClick={() => {
                    setIsFirstPlay(true)
                    mediaRecorderState?.state === 'paused' || watch('testimonial') ? handleTryAgain() : handleBack()
                  }}
                >
                  Try Again
                </Button>
              ) : (
                <div />

              )}

              <Button
                type='button'
                variant={'solid'}
                borderRadius={'10'}
                minW={'40%'}
                onClick={handleNext}
                disabled={mediaRecorderState?.state !== 'paused' && !watch('testimonial') && !mediaBlobUrl}
                isDisabled={mediaRecorderState?.state !== 'paused' && !watch('testimonial') && !mediaBlobUrl}
              >
                Send
              </Button>
            </Stack>
          </Box>
        </Container>
      </chakra.div>
      <chakra.div minH={'20'}>
        <AnimatePresence>
          {!mediaBlobUrl && !watch('testimonial') && countdown && (
            <chakra.p
              as={motion.p}
              textAlign={'center'}
              key={'timerOn'}
              initial={{ y: '50%', opacity: 0, scale: 0.5 }}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              exit={{ y: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: 'ease-out' }}
              fontWeight={'bold'}
            >
              Timer is On
            </chakra.p>
          )}
        </AnimatePresence>
      </chakra.div>
      <HelpRecorderModal onClose={onClose} isOpen={isOpen} />
      <GivePermissionModal
        onClose={() => {
          onClosePermission()
        }}
        isOpen={isOpenPermission}
        leastDestructiveRef={cancelRef}
        error={error}
        stream={stream}
        onOpen={onOpen}
        campaign={campaign}
      />
    </chakra.div>
  )
}

export default RecordView
