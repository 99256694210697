import { CopyIcon } from '@chakra-ui/icons'
import {
  Icon,
  chakra,
  Tooltip,
  Text,
  useColorModeValue,
  Box,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ModalFooter,
  Spinner,
  HStack,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  Stack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillCopy, AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useRecoilValue } from 'recoil'
import { useCustomToast } from '../../hooks/customToast'
import { userState } from '../../recoil/atoms/auth'
import { firstPromoterApi } from '../../utils'
import Customize from './components/customize'

const PartnerData = () => {
  const user = useRecoilValue(userState)
  const [wallLinkCopied, setWallLinkCopied] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [tempPass, setTempPass] = useState(false)
  const [data, setData] = useState(null)
  const [promoData, setPromoData] = useState(null)
  const [leadsData, setLeadsData] = useState(null)
  const [clicksData, setClicksData] = useState(null)
  const { addToast } = useCustomToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const iconTeal = useColorModeValue('teal.400', 'teal.400')

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      changePassword: '',
    },
  })

  useEffect(() => {
    if (user?.email) {
      let myHeaders = new Headers()
      myHeaders.append('x-api-key', process.env.REACT_APP_FIRSTPROMOTORER_API_KEY)
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

      let urlencoded = new URLSearchParams()
      urlencoded.append('promoter_email', user?.email)

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch('https://firstpromoter.com/api/v1/promoters/show?' + urlencoded, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result)
          res?.error ? setData(undefined) : setData(res)
        })
        .catch((error) => console.log('error', error))
    }
  }, [user?.email])

  useEffect(() => {
    if (user?.email) {
      let myHeaders = new Headers()
      myHeaders.append('x-api-key', process.env.REACT_APP_FIRSTPROMOTORER_API_KEY)
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch('https://firstpromoter.com/api/v1/reports/promoters', requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result)
          res?.error ? setPromoData(undefined) : setPromoData(res)
        })
        .then((result) => {
          const fil = promoData?.find((name) => {
            return name.promoter_id === data?.id
          })

          setClicksData(fil)
        })
        .catch((error) => console.log('error', error))

      fetch(' https://firstpromoter.com/api/v1/leads/list', requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result)
          res?.error ? setLeadsData(undefined) : setLeadsData(res.length)
        })
        .catch((error) => console.log('error', error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user?.email])

  const onSubmit = async (state, change = false) => {
    handleEnroll(state)
  }

  const handleEnroll = async (state) => {
    try {
      setLoading(true)
      const payload = {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        cust_id: user.uid,
        temp_password: state.password,
      }

      const PromoterSignup = await firstPromoterApi(
        'https://firstpromoter.com/api/v1/promoters/create',
        payload
      )
      if (PromoterSignup?.promotions?.length > 0) {
        setData(PromoterSignup)
        addToast({
          title: 'Welcome!',
          description: 'You have become a Promoter',
          status: 'success',
          variant: 'left-accent',
        })
      } else {
        addToast({
          title: 'Error',
          description:
            'Email ' + PromoterSignup?.['company_user.user.email']?.[0] || 'Something went wrong!',
          status: 'error',
          variant: 'left-accent',
        })
      }
      setLoading(false)
      onClose()
      reset()
    } catch ({ message }) {
      setLoading(false)
      addToast({ title: 'Error', description: message, status: 'error', variant: 'left-accent' })
    }
  }

  return data === null ? (
    <VStack justifyContent='center' alignItems='center' width='full' minH='calc(100vh - 20vh)'>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='teal.500'
        size={['md', 'xl']}
      />
    </VStack>
  ) : data === undefined ? (
    <VStack justifyContent='center' alignItems='center' p={4} mt={10} minH='calc(100vh - 35vh)'>
      <Text fontSize={['xl', '3xl']} color='brand.1' fontWeight='bold'>
        You are not enrolled!
      </Text>
      <Button onClick={onOpen} disabled={loading} isLoading={loading}>
        Enroll
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent my={'auto'}>
          <ModalHeader>Set Temporary password</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={'form'} onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors?.password} colSpan={[12, 6]} order={6}>
              <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                Password
              </FormLabel>
              <Input
                size={['sm', 'md', 'lg']}
                placeholder='Password'
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                type={'password'}
                autoComplete='new-password'
                {...register('password', {
                  required: 'This is required!',
                  minLength: { value: 6, message: 'Minimum length should be 6' },
                })}
              />
              {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
            </FormControl>
            <ModalFooter px={'0'}>
              <Button variant='solid' type='submit' isLoading={loading}>
                Submit
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  ) : (
    <Box px='4' overflowY='auto' bg='gray.20'>
      <HStack
        justifyContent={'space-between'}
        flexDirection={{ base: 'column', lg: 'row' }}
        mb={'3'}
        alignItems={'center'}
      >
        <Box pb={5}>
          <Text
            fontSize={['2xl', '3xl']}
            fontWeight='600'
            lineHeight={'38px'}
            color='#474747'
            my={'3'}
          >
            Welcome {user.firstName} to <chakra.span color={'brand.1'}>Sendmea</chakra.span>
            <chakra.span color={'brand.2'} ml={2}>
              Promotions!
            </chakra.span>
          </Text>
          <Text fontSize={['sm', 'md']} fontWeight='600'>
            You are earning $30 recurring commission on all direct referrals!
          </Text>
        </Box>

        <VStack pt='4' alignItems={{ base: 'center', lg: 'end' }} gap={'2'}>
          <Button
            mb='2'
            variant={'solid'}
            as={'a'}
            href={'https://codeshock.firstpromoter.com/login'}
            target='_blank'
          >
            Advanced Details Dashboard
          </Button>

          {data?.temp_password && (
            <InputGroup
              size={['md']}
              maxW={'max-content'}
              mt={{ base: '3!important', lg: '0!important' }}
            >
              <InputLeftAddon
                children={
                  <>
                    Dashboard Password &nbsp;
                    <Tooltip
                      placement='bottom'
                      color='white'
                      bg='teal.400'
                      px={2}
                      rounded='md'
                      hasArrow
                      label={!tempPass ? 'Copy' : 'Copied'}
                    >
                      <Icon
                        as={CopyIcon}
                        cursor={'pointer'}
                        onClick={() => {
                          navigator.clipboard.writeText(data?.temp_password)
                          setTempPass(true)
                          setTimeout(() => {
                            setTempPass(false)
                          }, 5000)
                        }}
                      />
                    </Tooltip>
                  </>
                }
                fontSize={'xs'}
              />
              <Tooltip
                placement='bottom'
                color='white'
                bg='teal.400'
                px={2}
                rounded='md'
                hasArrow
                label={showPass ? 'Hide' : 'Show'}
              >
                <InputRightElement
                  children={
                    <Icon
                      as={showPass ? AiFillEyeInvisible : AiFillEye}
                      color={'brand.1'}
                      w={6}
                      h={6}
                      cursor={'pointer'}
                      onClick={() => setShowPass((prev) => !prev)}
                    />
                  }
                />
              </Tooltip>
              <Input
                color={'brand.1'}
                _focusVisible={{ borderColor: 'brand.1' }}
                size={['md']}
                borderRadius={'0 4px 4px 0'}
                type={showPass ? 'text' : 'password'}
                value={data?.temp_password}
                onChange={() => {}}
                onClick={() => {
                  navigator.clipboard.writeText(data?.temp_password)
                  setTempPass(true)
                  setTimeout(() => {
                    setTempPass(false)
                  }, 5000)
                }}
              />
            </InputGroup>
          )}
        </VStack>
      </HStack>

      <Stack pb='2' pr='4' justify='space-between' direction={{ base: 'column', md: 'row' }}>
        <chakra.div
          display={'flex'}
          rowGap={'10px'}
          fontWeight={'400'}
          alignItems={{ base: 'baseline', sm: 'center' }}
          flexDirection={{ base: 'column', sm: 'row' }}
        >
          <Text mr={'1'}>Referral Link :</Text>
          <Tooltip
            placement='bottom'
            bg={iconTeal}
            px={2}
            rounded='md'
            hasArrow
            label={wallLinkCopied ? 'Copied' : 'Copy'}
          >
            <chakra.button
              bg={iconTeal}
              borderRadius={'20'}
              minW={{ base: '200px', sm: '310px' }}
              p={'3'}
              display={'flex'}
              justifyContent={'space-between'}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/register?fpr=${data?.promotions[0]?.ref_id}`
                )
                setWallLinkCopied(true)
                setTimeout(() => {
                  setWallLinkCopied(false)
                }, 5000)
              }}
            >
              <chakra.p
                textDecoration={'underline'}
                fontSize={['sm', 'md']}
                color={'white'}
                mr={'3'}
              >
                {window.location.origin}/register?fpr={data?.promotions[0]?.ref_id}
                {/* {data?.promotions?.[0]?.referral_link} */}
              </chakra.p>
              <span>
                {' '}
                <Icon as={AiFillCopy} color={'white'} mr={'2'} />
              </span>
            </chakra.button>
          </Tooltip>
          <Box>
            <Customize
              addToast={addToast}
              user={user}
              refId={data?.promotions[0]?.ref_id}
              setData={setData}
            />
          </Box>
        </chakra.div>

        <chakra.div
          display={'flex'}
          rowGap={'10px'}
          fontWeight={'400'}
          alignItems={{ base: 'baseline', sm: 'center' }}
          mt={'3'}
          flexDirection={{ base: 'column', sm: 'row' }}
        >
          <Text mr={'4'}>Referral ID :</Text>
          <Tooltip
            placement='bottom'
            color='white'
            bg='teal.400'
            px={2}
            rounded='md'
            hasArrow
            label={wallLinkCopied ? 'Copied' : 'Copy'}
          >
            <chakra.button
              bg={iconTeal}
              borderRadius={'20'}
              p={'3'}
              display={'flex'}
              justifyContent={'space-between'}
              onClick={() => {
                navigator.clipboard.writeText(`${data?.promotions[0]?.ref_id}`)
                setWallLinkCopied(true)
                setTimeout(() => {
                  setWallLinkCopied(false)
                }, 5000)
              }}
            >
              <chakra.p
                textDecoration={'underline'}
                fontSize={['sm', 'md']}
                color={'white'}
                mr={'3'}
              >
                {data?.promotions?.[0]?.ref_id}
              </chakra.p>
              <span>
                {' '}
                <Icon as={AiFillCopy} color={'white'} mr={'2'} />
              </span>
            </chakra.button>
          </Tooltip>
        </chakra.div>
      </Stack>
    </Box>
  )
}

export default PartnerData
