import { useEffect, useState } from 'react'
import {
	Box,
	Slider,
	SliderMark,
	SliderTrack,
	SliderFilledTrack,
	Tooltip,
	SliderThumb,
	Stack,
	Text,
	chakra,
} from '@chakra-ui/react'
import { growFont } from './grow'

const earn = 30

export const AffiliateSlider = () => {
	const [sliderValue, setSliderValue] = useState(1)
	const [showTooltip, setShowTooltip] = useState(false)
	const [s, setS] = useState(null)

	const sliderIncrement = () => {
		if (sliderValue <= 125) {
			setS(1)
		} else {
			setS(5)
		}
	}

	const commissionMonthlyValue = () => {
		const v = sliderValue * earn
		let parts = v.toFixed(2).split('.')
		let num =
			parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
			(parts[1] ? '.' + parts[1] : '')
		return num
	}
	const commissionAnnualValue = () => {
		const v = sliderValue * earn * 12
		let parts = v.toFixed(2).split('.')
		let num =
			parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
			(parts[1] ? '.' + parts[1] : '')
		return num
	}
	useEffect(() => {
		sliderIncrement()
	}, [sliderValue])

	return (
		<Stack
			w='100%'
			h='60%'
			justify='space-between'
			alignItems={'center'}
		>
			<Stack alignItems='center'>
				<Box
					pb='6'
					textAlign={'center'}
				>
					<Text
						fontSize={growFont(sliderValue)}
						fontWeight={'bold'}
					>
						${commissionMonthlyValue()}/mo
					</Text>
					<Text fontSize='2xl'>
						Monthly commission through referrals
					</Text>
				</Box>

				<Text fontSize='xl'>
					{sliderValue === 1
						? `${sliderValue} referral `
						: `${sliderValue} referrals `}{' '}
					is{' '}
					<chakra.span
						fontWeight='bold'
						fontSize='2xl'
					>
						${commissionAnnualValue()}
					</chakra.span>{' '}
					a year!
				</Text>
			</Stack>
			<Slider
				id='slider'
				defaultValue={0}
				min={0}
				max={650}
				colorScheme='teal'
				onChange={(v) => setSliderValue(v)}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
				pb='4'
				step={s}
			>
				<SliderMark
					value={1}
					mt='5'
					ml='-2.5'
					fontSize='sm'
				></SliderMark>
				<SliderMark
					value={650}
					mt='5'
					ml='-2.5'
					fontSize='md'
				>
					{Math.round(sliderValue)}
				</SliderMark>
				<SliderTrack h='1.5rem'>
					<SliderFilledTrack />
				</SliderTrack>

				<Tooltip
					hasArrow
					bg='teal.500'
					color='white'
					placement='top'
					isOpen={showTooltip}
					label={`${Math.round(sliderValue)}`}
				>
					<SliderThumb
						h='1.57em'
						w='1.7rem'
					/>
				</Tooltip>
			</Slider>
		</Stack>
	)
}
