import { Box, Text, chakra, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import React, { useState } from 'react'
import CreateWidget from './ReviewWidget'
import VideosWidget from './VideoReview'

function Widgets() {
    const [tabIndex, setTabIndex] = useState(0)
    return (
        <Box>
            <Text fontSize={["20px", "24px"]} fontWeight="600" lineHeight={"38px"} color="brand.2">
                <chakra.span color='teal.500'>Embed Code</chakra.span> Widgets
            </Text>
            <Tabs onChange={(index) => setTabIndex(index)} sx={{ m: 2 }}>
                <TabList gap="4px" justifyContent={["center", "center", "flex-start"]} mx={["auto", "auto", 0]}
                    sx={{
                        width: "max-content",
                        border: "2px solid",
                        borderRadius: "3px",
                    }}
                    borderColor={theme => theme.colors.teal["500"] + " !important"}
                >
                    <Tab
                        fontWeight="medium"
                        borderRadius="0px 2px 2px 0px"
                        mb={0}
                        _selected={{ color: 'white', bg: 'teal.500' }}>
                        Review Record
                    </Tab>
                    <Tab
                        fontWeight="medium"
                        borderRadius="2px 0 0 2px"
                        mb={0}
                        _selected={{ color: 'white', bg: 'teal.500' }}>
                        Video Review
                    </Tab>
                </TabList>

                <TabPanels >
                    <TabPanel p={0}><CreateWidget /></TabPanel>
                    <TabPanel p={0}>
                        <Text mt={3}><VideosWidget /></Text>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}

export default Widgets