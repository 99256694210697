import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userState = atom({
    key: 'user',
    default: null,
    effects_UNSTABLE: [
        persistAtom,
    ],

})

export const userLoggedIn = atom({
    key: 'userLoggedIn',
    default: false,
})

export const stripePlanId = atom({
    key: 'stripePlanId',
    default: '',
    effects_UNSTABLE: [persistAtom]
})

export const sidebarState = atom({
    key: 'sidebarState',
    default: false,
    effects_UNSTABLE: [persistAtom]
})
export const permissionTimerState = atom({
    key: 'sidebarState',
    default: false,
    effects_UNSTABLE: [persistAtom]
})