import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react'

export const SignUp = () => (
    <Box
      bg="brand.1"
      color="white"
      borderRadius="xl"
      mx={20}
      my={20}
      px={{ base: '10', lg: '16' }}
      py={{ base: '10', lg: '20' }}
    >
      <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} justify="space-between">
        <Stack spacing="4" maxW="xl">
          <Heading size="sm">Ready to Grow?</Heading>
          <Text color="fg.accent.muted" fontSize={{ base: 'lg', lg: 'xl' }}>
          Say goodbye to fake reviews and hello to a powerful tool that helps you stand out from the competition.
          </Text>
        </Stack>
        <Stack spacing="8" py='8' direction={{ base: 'column', sm: 'row' }} justify={{ base: 'end', sm: 'center' }}>
          <Button color="white"  p={2} >
            Book Meeting
          </Button>
          <Button px={6} bg={"brand.2"} >
            Sign Up
          </Button>
        </Stack>
      </Stack>
    </Box>
)