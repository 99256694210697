import { Box, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from "@chakra-ui/react"
import { MdGraphicEq } from "react-icons/md"

export function RangeSliderMarkExample({ start, end, setValue }) {
    return (
        <RangeSlider aria-label={['min', 'max']} value={[start, end]} my={3} onChange={(val) => {
            setValue('start_offset', val[0])
            setValue('end_offset', val[1])
        }
        }>
            <RangeSliderTrack bg='teal.100'>
                <RangeSliderFilledTrack bg='teal' />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} bgColor={'transparent'}>
                <Box color='blackAlpha.900' as={MdGraphicEq} />
            </RangeSliderThumb>
            <RangeSliderThumb boxSize={6} index={1} bgColor={'transparent'}>
                <Box color='blackAlpha.900' as={MdGraphicEq} />
            </RangeSliderThumb>
        </RangeSlider>
    )
}