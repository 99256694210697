import {
  Box,
  Button,
  chakra,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
  Input,
  InputLeftAddon,
  InputGroup,
} from '@chakra-ui/react'
import React from 'react'
import InputField from './InputField'
import Rating from '../../../components/Rating'

export const inputProps = {
  size: ["sm", "md", "lg"],
  bg: 'gray.100',
  border: 0,
  color: 'gray.500',
  _placeholder: {
    textTransform: 'capitalize',
    color: 'gray.500',
  },
}

function Step3({
  useForm: { register, setValue, watch, clearErrors },
  errors,
  isSubmitting,
  formDetails,
  testimonialState,
  setStep,
  submitRef,
}) {
  const handleDownload = async () => {
    try {
      let anchor = document.createElement('a')
      anchor.href = watch('testimonial')
      anchor.download = `your review.mp4`
      anchor.click()
      anchor.remove()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <chakra.div>
      <Container py={'10'} minH={'60vh'}>
        <Divider />
        <Box
          direction={{ base: 'column', sm: 'row' }}
          pt={'5'}
          pb={'4'}
          px={'4'}
          borderRadius={'8px'}
          sx={{
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
          }}
        >
          <Text color={'brand.3'} fontWeight={'bold'} fontSize={'small'}>
            2/2 step
          </Text>
          <Text color={'brand.3'} fontWeight={'bold'} fontSize={'lg'} mt={'2'}>
            Thank you! Your video is loading. There is just one last step left!
          </Text>
          <Text color={'brand.1'} fontWeight={'bold'} fontSize={'xx-large'}>
            Please, fill in the fields below to submit your review
          </Text>
          <SimpleGrid
            mt={10}
            columns={12}
            spacing={[2, 3, 4]}
            sx={{
              'input, select, textarea': {
                color: 'gray.700',
              },
            }}
          >
            {formDetails?.askUsers
              ?.filter((v) => v.checked)
              ?.map((v, i) => {
                if (v?.name === 'address') {
                  return (
                    <React.Fragment key={i}>
                      <FormControl key={'street'} isInvalid={!!errors['address']?.street} as={GridItem} colSpan={[12]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                          Street {v?.required && <chakra.span color={'red.500'}>{'*'}</chakra.span>}
                        </FormLabel>
                        <Input
                          isInvalid={!!errors['address']?.street}
                          {...inputProps}
                          {...register('address.street', {
                            required: v?.required && 'This is required',
                          })}
                        />
                        {errors['address']?.street && <FormErrorMessage>{errors['address']?.street?.message}</FormErrorMessage>}
                      </FormControl>
                      <FormControl key={'apt'} isInvalid={!!errors['address.apt']} as={GridItem} colSpan={[12]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                          Apt / Box
                        </FormLabel>
                        <Input
                          {...inputProps}
                          isInvalid={!!errors['address.apt']}
                          {...register('address.apt', {
                          })}
                        />
                        {errors['address']?.apt && <FormErrorMessage>{errors['address']?.apt?.message}</FormErrorMessage>}
                      </FormControl>
                      <FormControl key={'city'} isInvalid={!!errors['address']?.city} as={GridItem} colSpan={[6]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                          City {v?.required && <chakra.span color={'red.500'}>{'*'}</chakra.span>}
                        </FormLabel>
                        <Input
                          {...inputProps}
                          isInvalid={!!errors['address.city']}
                          {...register('address.city', {
                            required: v?.required && 'This is required',
                          })}
                        />
                        {errors['address']?.city && <FormErrorMessage>{errors['address']?.city?.message}</FormErrorMessage>}
                      </FormControl>
                      <FormControl key={'state'} isInvalid={!!errors['address']?.state} as={GridItem} colSpan={[6]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                          State {v?.required && <chakra.span color={'red.500'}>{'*'}</chakra.span>}
                        </FormLabel>
                        <Input
                          {...inputProps}
                          isInvalid={!!errors['address.state']}
                          {...register('address.state', {
                            required: v?.required && 'This is required',
                          })}
                        />
                        {errors['address']?.state && <FormErrorMessage>{errors['address']?.state?.message}</FormErrorMessage>}
                      </FormControl>
                      <FormControl key={'zip'} isInvalid={!!errors['address']?.zip} as={GridItem} colSpan={[6]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                          Zip {v?.required && <chakra.span color={'red.500'}>{'*'}</chakra.span>}
                        </FormLabel>
                        <Input
                          {...inputProps}
                          isInvalid={!!errors['address.zip']}
                          {...register('address.zip', {
                            required: v?.required && 'This is required',
                            minLength: { value: 4, message: 'Invalid zip code' },
                            pattern: {
                              value: /^\d{5}(-\d{4})?$/,
                              message: 'Invalid zip code'
                            },
                          })}
                          onInput={(e) => {
                            const { value } = e.target;
                            const numericValue = value.replace(/[^0-9]/g, '');
                            setValue('address.zip', numericValue);
                          }}
                        />
                        {errors['address']?.zip && <FormErrorMessage>{errors['address']?.zip?.message}</FormErrorMessage>}
                      </FormControl>
                      <FormControl key={'phone'} isInvalid={!!errors['address']?.phone} as={GridItem} colSpan={[6]}>
                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                          Phone {v?.required && <chakra.span color={'red.500'}>{'*'}</chakra.span>}
                        </FormLabel>
                        <Input
                          {...inputProps}
                          type='tel'
                          placeholder={'2021233456'}
                          isInvalid={!!errors['address.phone']}
                          {...register('address.phone', {
                            maxLength: { value: 10, message: 'Not a valid phone number, too many digits' },
                            required: v?.required && 'This is required',
                            setValueAs: (value) => {
                              let newInput = value.replace(/^\+[0-9]/, '')
                              value = newInput.replace(/\D/g, '')
                              value.replace(/^\+[0-9]{1,3}(\s|\-)/, '')
                              return value
                            },
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$|^\d{10}$/i,
                              message: 'Invalid phone number',
                            }
                          })}
                          onInput={(e) => {
                            let { value } = e.target;
                            let newInput = value.replace(/^\+[0-9]/, '')
                            value = newInput.replace(/\D/g, '')
                            value.replace(/^\+[0-9]{1,3}(\s|\-)/, '')
                            setValue('address.phone', value);
                          }}
                        />
                        {errors['address']?.phone && <FormErrorMessage>{errors['address']?.phone.message}</FormErrorMessage>}
                      </FormControl>
                    </React.Fragment>
                  )
                }
                return (
                  <FormControl
                    key={i}
                    isInvalid={!!errors[v.name]}
                    as={GridItem}
                    colSpan={[12]}
                  >
                    <FormLabel
                      fontSize={['sm', 'md']}
                      color='gray.500'
                      ml={0.5}
                      textTransform='capitalize'
                    >
                      {v.name === 'reviewMessage' ? 'Review Title' : v.label}
                      <chakra.span color={'red.500'}>{v?.required && '*'}</chakra.span>
                    </FormLabel>
                    {v.name !== 'rating' && v.name !== 'webPage' && v.name !== 'profileURL' ? (
                      <InputField
                        name={v.name}
                        required={v.required}
                        label={v.name === 'reviewMessage' ? 'Review Title' : v.label}
                        register={register}
                        testimonialState={testimonialState}
                      />
                    ) : v.name !== 'webPage' && v.name !== 'profileURL' ? (
                      <Box>
                        <chakra.input
                          type='number'
                          defaultValue={0}
                          {...register('rating', {
                            required: v.required,
                            validate: (val) => {
                              if (Number(val) < 1 || typeof val === 'string')
                                return 'Please give a rating'
                            },
                          })}
                          display='none'
                        />
                        <Rating
                          total={5}
                          value={watch('rating')}
                          setValue={(e) => {
                            setValue('rating', e)
                            clearErrors('rating')
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        <InputGroup maxW={{ md: '3xl' }}>
                          <InputLeftAddon color='gray.500'>https://</InputLeftAddon>
                          <Input
                            placeholder={'www.sendmea.io'}
                            {...register(v.name, {
                              required: v.required,
                              pattern: {
                                value: /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/,
                                message: 'Invalid URL',
                              },
                            })}
                            variant='filled'
                          />
                        </InputGroup>
                      </>
                    )}
                    {(v.name === 'webPage' || v.name === 'profileURL') && (
                      <Text fontSize='sm' color='red' fontWeight='medium' textAlign={'left'} mt={2}>
                        Note: Url will be public.{' '}
                      </Text>
                    )}
                    {errors[v.name] && <FormErrorMessage>{errors[v.name].message}</FormErrorMessage>}
                    {i === formDetails?.askUsers?.filter((v) => v.checked)?.length - 1 && (
                      <VStack alignItems={'end'}>
                        <Text color={'red.500'} fontSize={'sm'} mt={1}>
                          *Required
                        </Text>
                      </VStack>
                    )}
                  </FormControl>
                )
              }
              )}
          </SimpleGrid>
          <Button
            mt={5}
            variant='outline'
            ml='auto'
            mr={'3'}
            onClick={(_) => setStep((prev) => prev - 1)}
          >
            Back
          </Button>
          <Button
            type='submit'
            ref={submitRef}
            isLoading={isSubmitting}
            mt={5}
            variant='solid'
            ml='auto'
          >
            Save
          </Button>
        </Box>
        <chakra.div textAlign={'center'}>
          <Button
            size='xs'
            variant={'ghost'}
            color={'gray.400'}
            mx={'auto'}
            mt={'5'}
            onClick={handleDownload}
          >
            Download video
          </Button>
        </chakra.div>
      </Container>
    </chakra.div>
  )
}

export default Step3
