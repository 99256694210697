import React from 'react'
import { Box, Button, chakra, Image, SimpleGrid, Text } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

function NewHero() {
  return (
    <chakra.div
      bg={'rgba(180, 224, 226, 0.1)'}
      mx={[-2.5, -4]}
      px={[2.5, 4]}
    >
      <SimpleGrid ml={{ base: 'auto', lg: '10%' }} minH={'sm'} px='4' py={'14'} columns={{ sm: 1, md: 2 }}>
        <Box px={['2', '5', '8']} my={'auto'}>
          <Text
            as='h1'
            size='lg'
            fontSize={['44px', '50px', '60px']}
            fontWeight={'600'}
            mb={'8'}
            lineHeight={'normal'}
          >
            <chakra.span color={'brand.1'}>Quickly & Easily Collect Reviews for Your</chakra.span>{' '}
            <chakra.span color={'brand.2'}>Business or Personal Name.</chakra.span>
          </Text>
          <Text mb={'8'} fontSize={['md', 'lg']}>
            Explode Your Revenue with Authentic Social Proof from Real Customers. Nothing Will SEO
            Your Business Faster – Guaranteed!
          </Text>
          <Button w={'36'} as={Link} to={'/register'} variant={'solid'} mb={8} size={['md', 'lg']}>
            Sign up <ArrowForwardIcon w={'10'} />
          </Button>
        </Box>
        <Box px={'8'} mt={[6, 0]} maxW={'550px'}>
          <Image src='/images/hero-image.png' alt='hero image' />
        </Box>
      </SimpleGrid>
    </chakra.div>
  )
}

export default NewHero
