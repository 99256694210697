import { Box, Button, Flex, HStack, Image, Stack } from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Slogo from '../../../assets/images/sendmea-logo.png'

export const CampNav = ({ referral, wallTheme }) => {
  const navigate = useNavigate()

  return (
    <Box as='header' bg={referral ? wallTheme['navBg'] : 'transparent'}>
      <Box px={referral ? '4' : ['0', '0', '4', '8']} bg='bg-surface'>
        <Flex align='center' justify='space-between' height='4.5rem'>{' '}
          <Stack
            direction='row'

            cursor={'pointer'}
            onClick={() => navigate('/')}
          >
            <Image maxH={referral ? '50px' : ['30px', '40px']} src={Slogo} alt='logo' />
          </Stack>
          <HStack spacing='2'>
            <Button
              display={{ base: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              variant={'outline'}
              color={'brand.1'}
              sx={{
                border: (theme) => `1px solid ${theme.colors.brand['1']}!important`,
              }}
              colorScheme='blue'
              size='sm'
              href='#'
              as={RouterLink}
              {...(referral && ({
                to: `/register${referral && `?fpr=${referral}`}`,
              }))}
              {...(!referral && ({
                _hover: {
                  background: 'transparent',
                }
              }))}
            >
              Sign Up
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Box>
  )
}
