import { chakra } from '@chakra-ui/react'
import React from 'react'
import RecordView from './recorder';
import { convertToMb, getVideoDuration } from '../../../utils';
import { useCustomToast } from '../../../hooks/customToast';

function Step1({ setStep, step, useForm, forRecorder, campaign,
}) {
    const { setValue } = useForm;

    const { addToast } = useCustomToast()

    const handleUploadVideo = async (e) => {
        const fileObj = e.target.files[0];
        try {
            let sizeInMB = convertToMb(fileObj);
            if (fileObj && !fileObj.type.includes("video")) {
                return addToast({ title: 'Video', description: "Can only upload videos", status: 'error' })
            }
            else if (sizeInMB > 200 || sizeInMB < 0.230) {
                addToast({ title: 'Video', description: sizeInMB < 0.230 ? "Size is less than 230kb" : "Size is greater than 200mb", status: 'error' })
            }
            else {
                let duration = await getVideoDuration(fileObj)
                if (duration === Infinity || (Math.floor(duration) > 150)) {
                    addToast({ title: 'Video', description: duration === Infinity ? `Problem with this file` : `Duration is greater than 150 seconds`, status: 'error' })
                }
                else {
                    setValue("testimonial", fileObj)
                    setValue('duration', duration)
                }
            }
        } catch (error) {
            addToast({ title: 'Video', description: error.message, status: 'error' })
        }
    }

    return (
        <chakra.div >
            <RecordView
                setStep={setStep}
                step={step}
                forRecorder={forRecorder}
                campaign={campaign}
                useForm={useForm}
                handleUploadVideo={handleUploadVideo}
            />
        </chakra.div >
    )
}

export default Step1