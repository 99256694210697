import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  theme,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { AiFillYoutube } from 'react-icons/ai'
import { BiSupport } from 'react-icons/bi'
import { BsPatchQuestionFill } from 'react-icons/bs'
import { MdVideoLibrary } from 'react-icons/md'
import { IoMdHelpCircle } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import ContactSupportModal from './ContactSupportModal'

const HelpMenu = () => {

  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()


  const links = [
    {
      title: 'Free Sendmea Course',
      icon: MdVideoLibrary,
      link: 'https://u.codeshock.dev/courses/sendmea-getting-started-fast',
    },
    {
      title: 'Watch our YouTube videos',
      icon: AiFillYoutube,
      link: 'https://www.youtube.com/@sendmea',
    },
    { title: 'Checkout our FAQ Section', icon: IoMdHelpCircle, link: '/faq' },
    { title: 'Contact Support', icon: BiSupport, onClick: true },
  ]

  const handleOpenLink = (link) => {
    return String(link).startsWith('http') ? window.open(link) : navigate(link)
  }

  return (
    <Box>
      <Menu placement='bottom-end'>
        <Tooltip
          label='Need Help ?'
          placement='bottom'
          color='white'
          bg='teal.400'
          px={2}
          rounded='md'
          hasArrow
        >
          <MenuButton
            as={IconButton}
            variant='ghost'
            icon={<BsPatchQuestionFill />}
            color='teal.300'
            aria-label='information'
            size='md'
            fontSize='1.6rem'
            _hover={{
              bg: 'whiteAlpha.300',
              color: 'brand.2',
              type: 'ghost',
            }}
          />
        </Tooltip>
        <MenuList
          sx={{
            minH: '80px',
            overflow: 'auto',
            py: 0,
          }}
        >
          {links.map((link, i) => (
            <MenuItem
              key={link.title}
              sx={{
                bg: 'white',
                maxW: 'xs',
                flexWrap: 'wrap',
                py: 3.5,
              }}
              _hover={{
                bg: 'teal.50',
              }}
              _focus={{
                bg: 'white',
              }}
              cursor='pointer'
              borderBottom={i === 0 ? `1px solid ${theme.colors.gray[100]}` : 'none'}
              onClick={() => {
                if (link.onClick) {
                  onOpen()
                } else {
                  handleOpenLink(link.link)
                }

              }}
            >
              <HStack alignItems='center' spacing={2}>
                <Icon as={link.icon} fontSize={'22px'} color={theme.colors.teal[500]} />
                <Text textTransform='capitalize' fontWeight='500' fontSize={['15px']}>
                  {link.title}
                </Text>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <ContactSupportModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  )
}

export default HelpMenu
