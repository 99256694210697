import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { db } from '../../firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import { useCustomToast } from '../../hooks/customToast'
import { campaignDataAtom } from '../../recoil/atoms/campaignData'
import { CustomMessageForm } from './CustomeMessageForm'

export const CustomRequestMessage = () => {
  const campaignId = useRecoilValue(campaignDataAtom)
  const [campaignD, setCampaignD] = useState(null)
  const [dataLoading, setDataLoading] = useState(true)
  const { addToast } = useCustomToast()
  const [cm, setCm] = useState(null)
  const [isEditable, setIsEditable] = useState(false)

  const getCampaignById = async () => {
    setDataLoading(true)

    try {
      if (campaignId) {
        const docRef = doc(db, 'campaigns', campaignId)
        let campaignDoc = await getDoc(docRef)
        if (campaignDoc.exists()) {
          let campaignData = { ...campaignDoc.data(), id: campaignDoc.campaignId }
          setCampaignD(campaignData)
          setCm(campaignD?.customRequestMessage)
          setDataLoading(false)
          setIsEditable(false)
        } else {
          addToast({
            title: 'Error',
            description: 'campaign not found',
            status: 'error',
            variant: 'left-accent',
          })
        }
      }
      setDataLoading(false)
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setDataLoading(false)
    }
  }

  useEffect(() => {
    getCampaignById()
  }, [])

  return (
    <CustomMessageForm
      dataLoading={dataLoading}
      cm={cm}
      isEditable={isEditable}
      campaignD={campaignD}
      setIsEditable={setIsEditable}
    />
  )
}
