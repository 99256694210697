import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  HStack,
  Heading,
  Hide,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  Tooltip,
  VStack,
  chakra,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'

import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillCopy } from 'react-icons/ai'
import { BiUpvote } from 'react-icons/bi'
import { BsPatchCheckFill } from 'react-icons/bs'
import { FaVoteYea } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Sticky from 'react-stickynode'
import { useRecoilValue } from 'recoil'
import { db } from '../../firebase/config'
import { useCustomToast } from '../../hooks/customToast'
import { userState } from '../../recoil/atoms/auth'
import { teamEmailIds } from '../../utils'
import ApproveRoadmapModal from './ApproveModal'

const SuggestedUser = ({ val, user, text, isTeamMember }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const { addToast } = useCustomToast()

  return (
    <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} placement='top-start'>
      <PopoverTrigger>
        <Tag
          colorScheme={'teal'}
          size={['sm', 'md']}
          fontWeight='bold'
          onClick={onToggle}
          _hover={{
            boxShadow: 'md',
          }}
        >
          {text}
        </Tag>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ boxShadow: 'md' }}
        borderColor='teal.300'
        bg='teal.500'
        maxW='250px'
      >
        <PopoverArrow bg='teal.500' />
        <PopoverCloseButton color={'white'} />
        <PopoverBody py={0}>
          <HStack direction='row' spacing='10px' alignItems='center' p={[3]} px={[1]} maxW='250px'>
            <Avatar
              src={val?.user?.photoURL}
              size={'md'}
              border={(theme) => '1px solid' + theme.colors.gray['200']}
            />
            {!user ? (
              <VStack spacing={'0px'} alignItems='flex-start'>
                <Text fontSize={['15px']} fontWeight='bold' color='white' whiteSpace='nowrap'>
                  {val?.user?.firstName + ' ' + val?.user?.lastName}
                </Text>
              </VStack>
            ) : (
              <VStack spacing={'0px'} alignItems='flex-start'>
                <Text fontSize={['15px']} fontWeight='bold' color='white' whiteSpace='nowrap'>
                  {val?.user?.firstName + ' ' + val?.user?.lastName}
                </Text>
                <HStack spacing={1}>
                  <Text fontSize={['12px']} fontWeight='500' color='white' overflowWrap='anywhere'>
                    {val?.user?.email}
                  </Text>
                  {isTeamMember && (
                    <Box
                      height={'20px'}
                      onClick={() => {
                        navigator.clipboard.writeText(val?.user?.email)
                        addToast({
                          title: 'Email',
                          description: 'Email Copied',
                          status: 'success',
                          variant: 'left-accent',
                        })
                      }}
                      cursor={'pointer'}
                    >
                      <Icon
                        color={'white'}
                        transition={'all 0.4s ease'}
                        _hover={{
                          color: 'teal.100',
                        }}
                        as={AiFillCopy}
                      />
                    </Box>
                  )}
                </HStack>
              </VStack>
            )}
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const ApprovedTag = ({ onClickApprove, val, isTeamMember, user }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

  return (
    <Tooltip
      hasArrow
      border='1px solid'
      borderColor='teal.300'
      bg='teal.500'
      borderRadius='md'
      placement={'top'}
      isOpen={isOpen}
      label={user ? (!val?.approved ? 'Click to Approve' : 'Already Approved') : ''}
    >
      <Tag
        colorScheme={'green'}
        size={['sm', 'md']}
        fontWeight='bold'
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        transition='all 0.3s ease'
        _hover={{
          boxShadow: 'md',
        }}
        {...(isTeamMember && {
          cursor: 'pointer',
          onClick: () => {
            onToggle()
            onClickApprove()
          },
        })}
      >
        {val?.approved ? 'Approved' : 'Unapproved'}
      </Tag>
    </Tooltip>
  )
}

const ApprovedTooltip = ({ val }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

  return (
    <Tooltip
      hasArrow
      arrowShadowColor={'#7ed957b5'}
      border='1px solid'
      borderColor='#7ed957b5'
      bg='teal.600'
      borderRadius='md'
      placement={'top-end'}
      isOpen={isOpen}
      maxW={'230px'}
      textAlign='center'
      rounded='md'
      filter={'brightness(1.2) drop-shadow(3px 3px 6px gray)'}
      label={
        <VStack p={1.2} gap={0.8} alignItems='flex-start'>
          <VStack spacing={0} alignItems='flex-start'>
            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>
              Comment:
            </Text>
            <Text color='whiteAlpha.900' fontStyle='italic' textAlign='left' fontSize='sm'>
              {val?.comment}
            </Text>
          </VStack>
          <VStack spacing={0} alignItems='flex-start'>
            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>
              Approved At:
            </Text>
            <Text color='whiteAlpha.900' fontStyle='italic' textAlign='left' fontSize='sm'>
              {val?.approvedAt?.toDate()?.toDateString()}
            </Text>
          </VStack>
        </VStack>
      }
    >
      <IconButton
        color={'brand.2'}
        variant={'ghost'}
        cursor={'pointer'}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={onToggle}
      >
        <Icon as={BsPatchCheckFill} w={['7', '8']} h={['7', '8']} />
      </IconButton>
    </Tooltip>
  )
}

const VotingArrow = ({ val, user, handleVote, i }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

  return (
    <Tooltip
      placement='bottom'
      color='white'
      bg='teal.400'
      px={2}
      rounded='md'
      hasArrow
      isOpen={isOpen}
      label={
        !user
          ? 'Login to vote!'
          : val?.userWhoVoted?.includes(user?.uid)
          ? 'Already Voted!'
          : 'Vote!'
      }
    >
      {val?.userWhoVoted?.includes(user?.uid) ? (
        <IconButton
          color={'brand.2'}
          variant={'ghost'}
          cursor={'pointer'}
          onClick={() => {
            onToggle()
            if (!val?.userWhoVoted?.includes(user?.uid)) {
              handleVote(i)
            }
          }}
          display={['inline-flex', 'none']}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          <Icon as={FaVoteYea} w={['7', '8']} h={['7', '8']} />
        </IconButton>
      ) : (
        <IconButton
          color={'brand.2'}
          variant={'ghost'}
          cursor={'pointer'}
          onClick={() => handleVote(i)}
          disabled={!user || val?.userWhoVoted?.includes(user?.uid)}
        >
          <Icon as={BiUpvote} w={['7', '8']} h={['7', '8']} />
        </IconButton>
      )}
    </Tooltip>
  )
}

function Roadmap() {
  const user = useRecoilValue(userState)
  const { addToast } = useCustomToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [allVotes, setAllVotes] = useState(null)
  const [roadmapTask, setRoadmapTask] = useState(null)
  const {
    isOpen: isApproveModalOpen,
    onOpen: onOpenApproveModal,
    onClose: onCloseApproveModal,
  } = useDisclosure()

  const isMobile = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
  })

  const isTeamMember = (emailId) => {
    return teamEmailIds.includes(emailId)
  }

  useEffect(() => {
    ;(async () => {
      if (db) {
        const featureRef = collection(db, 'roadmap')
        const approvedQuery = query(
          featureRef,
          orderBy('approvedAt', 'desc'),
          where('approved', '==', true),
          limit(10)
        )
        const unapprovedQuery = query(
          featureRef,
          where('approved', '==', false),
          orderBy('votes', 'desc'),
          limit(10)
        )

        const unsubApproved = onSnapshot(approvedQuery, async (approvedSnap) => {
          const unsubNonApproved = onSnapshot(unapprovedQuery, async (unapprovedSnap) => {
            const combineArr = [...approvedSnap.docs, ...unapprovedSnap.docs]
            let results = await Promise.all(
              combineArr.map(async (docSnap, i) => {
                const docRef = doc(db, 'users', docSnap.data()?.userId)
                const userSnap = await getDoc(docRef)
                const user = { ...userSnap.data() }
                let description = docSnap.data()?.description
                description = description.charAt(0).toUpperCase() + description.slice(1)
                return { ...docSnap.data(), description, user, id: docSnap.id, value: i }
              })
            )
            const filteredData = results.filter((feature) => {
              return feature?.teamOnly ? isTeamMember(user?.email) : true
            })
            setAllVotes(filteredData)

            return () => unsubNonApproved()
          })
        })
        return () => unsubApproved()
      }
    })()
  }, [user])

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      teamOnly: false,
    },
  })

  const onSubmit = async (state) => {
    try {
      setIsSubmitting(true)
      const roadmapPayload = {
        ...state,
        approved: false,
        userId: user?.uid,
        votes: 1,
        userWhoVoted: [user?.uid],
        createdAt: serverTimestamp(),
      }
      const roadmapRef = collection(db, 'roadmap')
      await addDoc(roadmapRef, roadmapPayload)
      reset()
      setIsSubmitting(false)
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setIsSubmitting(false)
    }
  }

  const handleVote = async (i) => {
    try {
      const roadmapRef = doc(db, 'roadmap', allVotes[i].id)
      await updateDoc(roadmapRef, {
        votes: allVotes[i].votes + 1,
        userWhoVoted: arrayUnion(user?.uid),
      })
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
    }
  }

  const [height, setHeight] = useState(0)
  const elementRef = useRef(null)

  const resizeHandler = () => {
    const height = elementRef.current.clientHeight
    setHeight(height)
  }
  useEffect(() => {
    if (elementRef?.current) {
      resizeHandler()
      window.addEventListener('scroll', resizeHandler)
    }
    return () => window.removeEventListener('scroll', resizeHandler)
  }, [])

  const handleOpenApproveModal = (roadmap) => {
    onOpenApproveModal()
    setRoadmapTask(roadmap)
  }
  const handleCloseApproveModal = () => {
    onCloseApproveModal()
    setRoadmapTask(null)
  }

  return (
    <>
      <Container maxW={['container.xl']} px={{ base: 0, sm: 2 }} py={[6]} mb={14}>
        <Stack
          mt={5}
          bg={'gray.50'}
          rounded={'lg'}
          py={{ base: 6, sm: 6 }}
          px={{ base: 4, sm: 4, md: 8 }}
          width='full'
          spacing={8}
          minH={['80vh']}
          ref={elementRef}
        >
          <Heading
            color={'brand.1'}
            lineHeight={1.1}
            fontSize={{ base: '4xl' }}
            fontWeight={'bold'}
          >
            Roadmap
          </Heading>
          <SimpleGrid columns={12} spacing={[6, 8]}>
            <GridItem colSpan={[12, 12, 7]} order={[2, 2, 1]}>
              {!allVotes ? (
                <VStack
                  justifyContent='center'
                  alignItems='center'
                  width='full'
                  minH='calc(40vh - 20vh)'
                >
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='teal.500'
                    size={['md', 'xl']}
                  />
                </VStack>
              ) : (
                allVotes.map((val, i) => (
                  <VStack
                    key={i}
                    flexDir={'row'}
                    gap={'4'}
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                    mb={2}
                  >
                    <Box
                      w={['6', '10']}
                      textAlign={'center'}
                      mt={'5px'}
                      display={['none', 'inline-flex']}
                    >
                      {val?.approved ? (
                        <ApprovedTooltip val={val} />
                      ) : (
                        <Tooltip
                          placement='bottom'
                          color='white'
                          bg='teal.400'
                          px={2}
                          rounded='md'
                          hasArrow
                          label={
                            !user
                              ? 'Login to vote!'
                              : val?.userWhoVoted?.includes(user?.uid)
                              ? 'Already Voted!'
                              : 'Vote!'
                          }
                        >
                          {val?.userWhoVoted?.includes(user?.uid) ? (
                            <IconButton color={'brand.2'} variant={'ghost'} cursor={'pointer'}>
                              <Icon as={FaVoteYea} w={['7', '8']} h={['7', '8']} />
                            </IconButton>
                          ) : (
                            <IconButton
                              color={'brand.2'}
                              variant={'ghost'}
                              cursor={'pointer'}
                              onClick={() => handleVote(i)}
                              disabled={!user || val?.userWhoVoted?.includes(user?.uid)}
                            >
                              <Icon as={BiUpvote} w={['7', '8']} h={['7', '8']} />
                            </IconButton>
                          )}
                        </Tooltip>
                      )}
                    </Box>
                    <Tooltip
                      placement='bottom'
                      color='white'
                      bg='teal.400'
                      px={2}
                      rounded='md'
                      hasArrow
                      label={`${val.votes}${val.votes > 1 ? ' votes' : ' vote'}`}
                    >
                      <HStack
                        w={'35px !important'}
                        h={'30px'}
                        alignItems='center'
                        justifyContent='center'
                        textAlign={'center'}
                        rounded='full'
                        border={'1px solid'}
                        borderColor={'teal.900'}
                        bg={'teal.500'}
                        mt={'10px !important'}
                      >
                        <Text fontWeight={'500'} fontSize={'lg'} color={'white'}>
                          {val.votes}
                        </Text>
                      </HStack>
                    </Tooltip>
                    <VStack w={'100%'} spacing={['2']} alignItems='flex-start' width='100%'>
                      <VStack alignItems='flex-start' width='100%'>
                        <Heading
                          fontSize={['xl', '2xl']}
                          color={'blackAlpha.800'}
                          textTransform='capitalize'
                        >
                          {val.title}
                        </Heading>
                        <Text color={'gray.500'} maxW={['400px']} fontSize={['md']}>
                          {val.description}
                        </Text>
                      </VStack>
                      <VStack
                        alignItems='flex-start'
                        justifyContent='space-between'
                        maxW={['400px']}
                        width='100%'
                        spacing={[4, 2]}
                      >
                        {' '}
                        <HStack
                          w='full'
                          justifyContent='space-between'
                          alignItems='center'
                          spacing={[0]}
                        >
                          <Text color={'gray.400'} fontSize={['sm']} fontWeight='bold' mt={1}>
                            {val?.createdAt?.toDate()?.toDateString()}
                          </Text>
                          <Hide above='sm'>
                            {val?.approved ? (
                              <ApprovedTooltip val={val} />
                            ) : (
                              <VotingArrow user={user} handleVote={handleVote} val={val} i={i} />
                            )}
                          </Hide>
                        </HStack>
                        <HStack
                          w='full'
                          justifyContent='flex-start'
                          alignItems='center'
                          flexWrap='wrap'
                          gap={[2, 4]}
                          spacing={[0]}
                        >
                          <SuggestedUser
                            val={val}
                            isTeamMember={isTeamMember(user?.email)}
                            user={user}
                            text='Suggested by'
                          />
                          {val?.teamOnly && (
                            <Tag
                              colorScheme={'messenger'}
                              size={['sm', 'md']}
                              fontWeight='bold'
                              _hover={{ boxShadow: 'md' }}
                            >
                              Team Only
                            </Tag>
                          )}
                          {(isTeamMember(user?.email) || val?.approved) && (
                            <ApprovedTag
                              onClickApprove={() => handleOpenApproveModal(val)}
                              val={val}
                              isTeamMember={isTeamMember(user?.email)}
                              user={user}
                            />
                          )}
                        </HStack>
                      </VStack>
                    </VStack>
                  </VStack>
                ))
              )}
            </GridItem>
            <GridItem colSpan={[12, 12, 5]} order={[1, 1, 2]} position='relative'>
              <Sticky top={70} bottomBoundary={height} enabled={isMobile}>
                <VStack
                  alignItems='flex-start'
                  spacing={4}
                  maxWidth={['450px', '450px', '100%']}
                  mx={['auto', 'auto', '0']}
                  sx={{
                    width: ['100%', '100%'],
                  }}
                  as='form'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Heading
                    color={'brand.1'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: '2xl' }}
                    fontWeight={'bold'}
                  >
                    Feature Recommendation
                  </Heading>
                  <FormControl isInvalid={!!errors?.title}>
                    <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                      Feature Title
                    </FormLabel>
                    <Input
                      size={['sm', 'md', 'lg']}
                      placeholder='Feature Title'
                      bg={'gray.100'}
                      border={0}
                      color={'gray.500'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      {...register('title', {
                        required: 'This is required',
                      })}
                    />
                    {errors.title && <FormErrorMessage>{errors.title.message}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={!!errors?.description}>
                    <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                      Feature Description
                    </FormLabel>
                    <Textarea
                      size={['sm', 'md', 'lg']}
                      placeholder='Feature Description'
                      bg={'gray.100'}
                      border={0}
                      color={'gray.500'}
                      resize='none'
                      h={'180px !important'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      {...register('description', {
                        required: 'This is required',
                      })}
                    />
                    {errors.description && (
                      <FormErrorMessage>{errors.description.message}</FormErrorMessage>
                    )}
                  </FormControl>
                  {isTeamMember(user?.email) && user?.role === 'premium' && (
                    <Checkbox
                      type='checkbox'
                      colorScheme='teal'
                      {...register(`teamOnly`)}
                      userSelect='none'
                    >
                      <Text color='gray.600' fontSize={['sm', 'md']} userSelect='none'>
                        Private <chakra.span color='gray.500'>(only team will see)</chakra.span>
                      </Text>
                    </Checkbox>
                  )}
                  <Box display={'flex'}>
                    <Button
                      variant={'solid'}
                      type={'submit'}
                      ml={'auto'}
                      disabled={!user || user?.role !== 'premium'}
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                  {!user ? (
                    <Text fontSize='sm' color='gray.500' fontWeight='medium' textAlign={'right'}>
                      Note: Login to recommend feature.{' '}
                      <Text to='/login' as={Link} color={'teal.500'} fontWeight={'400'}>
                        Click here.
                      </Text>
                    </Text>
                  ) : (
                    user?.role !== 'premium' && (
                      <Text fontSize='sm' color='gray.500' fontWeight='medium' textAlign={'right'}>
                        Note: Only premium user has access to this feature.
                      </Text>
                    )
                  )}
                </VStack>
              </Sticky>
            </GridItem>
          </SimpleGrid>
        </Stack>
        <ApproveRoadmapModal
          isOpen={isApproveModalOpen && roadmapTask}
          onClose={handleCloseApproveModal}
          roadmapTask={roadmapTask}
        />
      </Container>
    </>
  )
}

export default Roadmap
