/* eslint-disable no-useless-escape */
import {
  Box,
  Heading,
  Stack,
  chakra,
  Flex,
  Text,
  theme,
  VStack,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  HStack,
  Fade,
  Textarea,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { campaignFormRenderState } from '../../../recoil/atoms/campaignForm'
import { InformationModal } from '../../../components/InformationModal'
import { placeHolder } from './modalData'
import { BsPatchQuestionFill } from 'react-icons/bs'

const Circle = ({ no }) => (
  <chakra.div
    minWidth={'26px'}
    minHeight={'25px'}
    bg='brand.1'
    borderRadius='full'
    color='white'
    textAlign='center'
    display={'flex'}
    justifyContent='center'
    alignItems={'center'}
    mr='4px'
    fontSize={['sm', 'md']}
  >
    {no}
  </chakra.div>
)

const ReviewPageDetails = ({ state, stepMethods, getData, submitStepRef3 }) => {
  let { reviewPageDetails } = state
  let { nextStep, prevStep, activeStep } = stepMethods
  const setCampaignRender = useSetRecoilState(campaignFormRenderState)


  const initialRenders = {
    redirectLinkForButton: !!reviewPageDetails.redirectLinkForButton,
    thanksForLeavingText: !!reviewPageDetails.thanksForLeavingText,
  }
  const [shouldRenders, setShouldRenders] = useState(initialRenders)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      buttonText: '',
      redirectLinkForButton: '',
      thanksForLeavingText:
        'Thank you for your time! We appreciate your review and hope to see you again soon.',
      ...state?.reviewPageDetails,
    },
  })

  const onSubmit = async (data) => {
    getData({ reviewPageDetails: data })
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    nextStep()
  }

  useEffect(() => {
    if (activeStep === 3 && state.id) {
      reset({ ...state?.reviewPageDetails })
    }
  }, [state, activeStep, reset])

  const inputsDivRef = useRef(null)

  const scrollToLastChild = (index) => {
    inputsDivRef.current?.children[index === 4 ? 5 : index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  useEffect(() => {
    if (!Object.keys(shouldRenders).some((key) => !shouldRenders[key])) {
      setCampaignRender((prev) => ({ ...prev, reviewPageDetails: true }))
    } else {
      setCampaignRender((prev) => ({ ...prev, reviewPageDetails: false }))
    }
  }, [setCampaignRender, shouldRenders])

  return (
    <Stack width='full' spacing={4} my={[8, '10']}>
      <Heading
        color={'brand.1'}
        lineHeight={1.1}
        fontSize={{ base: 'xl', sm: '3xl', md: '4xl' }}
        textAlign='center'
        width={['full', '90%', '80%']}
        mx='auto'
        mb={6}
      >
        Help users create a video review
      </Heading>
      <Stack
        spacing={10}
        as='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          '& .chakra-text': {
            textAlign: 'left',
          },
        }}
        ref={inputsDivRef}
      >
        <VStack spacing={2} alignItems='stretch' maxWidth={'700px'}>
          <Flex direction={'row'} alignItems={['center']}>
            <Circle no={1} />
            <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
              Button text after submitting review
            </Text>
            <InformationModal
              icon={<BsPatchQuestionFill />}
              iconColor='teal.300'
              iconSize='1.7rem'
              modalData={placeHolder}
            />
          </Flex>
          <Box
            ml={'12px !important'}
            pl='24px'
            borderLeft={`2px solid ${theme.colors.gray['200']}`}
            pb='4'
          >
            <Text pb='16px' textAlign='left'>
              After a review is recorded, you can have custom text and link that is presented
              following video submission.
            </Text>
            <FormControl isInvalid={!!errors?.buttonText}>
              <Input
                size={['sm', 'md', 'lg']}
                placeholder='e.g. Go to website, Get a free lesson'
                bg={'gray.100'}
                border={0}
                _placeholder={{
                  color: 'gray.500',
                }}
                {...register('buttonText', {
                  required: 'This is required',
                })}
                onFocus={(_) => {
                  setShouldRenders((prev) => ({
                    ...prev,
                    redirectLinkForButton: true,
                  }))
                  scrollToLastChild(shouldRenders.redirectLinkForButton ? 0 : 1)
                }}
              />
              {errors.buttonText && (
                <FormErrorMessage>{errors.buttonText.message}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </VStack>

        <Fade in={shouldRenders.redirectLinkForButton}>
          <VStack
            spacing={2}
            alignItems='stretch'
            maxWidth={'700px'}
            display={!shouldRenders.redirectLinkForButton ? 'none' : 'flex'}
          >
            <Flex direction={'row'} alignItems={['center']}>
              <Circle no={2} />
              <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                Provide a URL that reviewer can click the button in step 1
              </Text>
              <InformationModal
                icon={<BsPatchQuestionFill />}
                iconColor='teal.300'
                iconSize='1.7rem'
                modalData={placeHolder}
              />
            </Flex>
            <Box
              ml={'12px !important'}
              pl='24px'
              borderLeft={`2px solid ${theme.colors.gray['200']}`}
              pb='4'
            >
              <Text pb='16px' textAlign='left'>
                Users will be able to go to this URL after they submit your video review.
              </Text>
              <FormControl isInvalid={!!errors?.redirectLinkForButton}>
                <Input
                  size={['sm', 'md', 'lg']}
                  placeholder='e.g. https://sendmea.io'
                  bg={'gray.100'}
                  border={0}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  {...register('redirectLinkForButton', {
                    required: 'You must add any referral link for button',
                    pattern: {
                      value: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                      message: 'Invalid URL',
                    },
                  })}
                  onFocus={(_) => {
                    setShouldRenders((prev) => ({
                      ...prev,
                      thanksForLeavingText: true,
                    }))
                    scrollToLastChild(shouldRenders.thanksForLeavingText ? 1 : 2)
                  }}
                />
                {errors.redirectLinkForButton && (
                  <FormErrorMessage>{errors.redirectLinkForButton.message}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </VStack>
        </Fade>

        <Fade in={shouldRenders.thanksForLeavingText}>
          <VStack
            spacing={2}
            alignItems='stretch'
            maxWidth={'700px'}
            display={!shouldRenders.thanksForLeavingText ? 'none' : 'flex'}
          >
            <Flex direction={'row'} alignItems={['center']}>
              <Circle no={3} />
              <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                Thanks for leaving a review
              </Text>
              <InformationModal
                icon={<BsPatchQuestionFill />}
                iconColor='teal.300'
                iconSize='1.7rem'
                modalData={placeHolder}
              />
            </Flex>
            <Box
              ml={'12px !important'}
              pl='24px'
              borderLeft={`2px solid ${theme.colors.gray['200']}`}
              pb='4'
            >
              <Text pb='16px' textAlign='left'>
                This is your message that is seen by customers who successfully leave a review. You
                can leave the default text in the field below (click on it)
              </Text>
              <FormControl isInvalid={!!errors?.thanksForLeavingText}>
                <Textarea
                  size={['sm', 'md', 'lg']}
                  placeholder='Check my website, Get a free lesson'
                  bg={'gray.100'}
                  border={0}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  _focus={{
                    color: 'gray.800',
                  }}
                  resize='none'
                  minH="100px"
                  {...register('thanksForLeavingText', {
                    required: 'This is required',
                  })}
                  onFocus={(_) => {
                    setTimeout(() => {
                      scrollToLastChild(3)
                    }, 150)
                  }}
                />
                {errors?.thanksForLeavingText && (
                  <FormErrorMessage>{errors?.thanksForLeavingText.message}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </VStack>
        </Fade>

        <HStack width='100%' justify='flex-end'>
          {!state.id && (
            <Button
              isDisabled={activeStep === 0}
              mr={0}
              onClick={prevStep}
              size='sm'
              variant='outline'
              minWidth='70px'
            >
              Prev
            </Button>
          )}
          <Button
            size='sm'
            type='submit'
            variant='solid'
            minWidth={state.id ? '80px' : '70px'}
            disabled={!shouldRenders.thanksForLeavingText}
            id='next-btn'
            ref={submitStepRef3}
          >
            Next
          </Button>
        </HStack>
      </Stack>
    </Stack>
  )
}

export default ReviewPageDetails
