import { Box, chakra, Text, Tooltip, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

function VideosWidget() {
    const { state } = useLocation()
    const { wallId } = useParams()
    const [Copied, setCopied] = useState(false)
    const [timeoutInst, setTimeoutInst] = useState(null)

    useEffect(() => {
        return () => {
            clearTimeout(timeoutInst)
            setTimeoutInst(null)
        }
    }, [timeoutInst])

    return (
        <Box boxShadow='2xl' rounded='md' m='10' minH={{ base: '100%', md: '70vh' }}>
            <Flex p='6' direction={'column'} alignItems={'center'} justify={'center'}>
                <Text fontWeight={'bold'} color={'red'} fontSize={'3xl'} my={3}>
                    CURRENTLY IN DEVELOPMENT - BETA ONLY
                </Text>
                <Text fontWeight={'bold'} color={'orange.400'} fontSize={'md'} my={1} _hover={{ color: 'orange.500' }}
                    as='a' href={'/codeshock-embed'} target='_blank'
                >
                    Preview Embed
                </Text>
                <Text fontWeight={'bold'} color={'brand.1'} fontSize={'3xl'} my={3}>
                    For Embedded Last Three Reviews:
                </Text>
                <Text fontSize={['lg', 'lg']} my={3} p='4'>
                    Add the following JavaScript code snippet to your website where you want the Video Reviews to appear.
                </Text>

                <Box
                    m='6'
                    w={{ base: '90%', md: '60%' }}
                    border='1px solid'
                    borderColor={'gray.500'}
                    p={8}
                    bg={'gray.100'}
                    position='relative'
                    mt={1}
                    pt={8}
                >
                    <chakra.span
                        position={'absolute'}
                        top={0}
                        right={0}
                        left={0}
                        cursor={'pointer'}
                        textAlign={'right'}
                        px={2}
                        pb={1}
                        bg={'blackAlpha.800'}
                        color={'white'}
                        onClick={() => {
                            setCopied(true)
                            setTimeoutInst(
                                setTimeout(() => {
                                    setCopied(false)
                                }, 5000)
                            )
                            navigator.clipboard.writeText(`
                        <!-- Sendmea Video Reviews Widget -->
                        <iframe
                        src="${window.location.origin}/wall/${wallId}?embeded=true"
                        width="100%"
                        height="680"
                        style="border: 0"
                    />
                        <!-- End Sendmea Video Reviews Widget -->
                        `)
                        }}
                    >
                        <Tooltip
                            placement='bottom'
                            color='white'
                            bg='teal.400'
                            px={2}
                            rounded='md'
                            hasArrow
                            label={Copied ? 'Copied' : 'Copy'}
                        >
                            copy code
                        </Tooltip>
                    </chakra.span>
                    <div id="sendmea_container"></div>
                    {` 
                <!-- Sendmea Video Reviews Widget -->
                <iframe
                src="${window.location.origin}/wall/${wallId}?embeded=true"
                width="100%"
                height="680"
                style="border: 0"
            />
                <!-- End Sendmea Video Reviews Widget -->
                `}
                </Box>
            </Flex>
        </Box>
    )
}

export default VideosWidget
