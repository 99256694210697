import { selector } from "recoil";
import { userState } from "./auth";
import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../firebase/config";

export const getMyCampaigns = selector({
    key: 'getMyCampaigns',
    get: async ({ get }) => {
        try {
            const userId = get(userState)?.uid
            const collRef = collection(db, "campaigns")
            let campaignRef = query(collRef, where("userId", "==", userId))
            const getCampaigns = await getDocs(campaignRef)
            const campaigns = getCampaigns.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            return ({ campaigns, size: getCampaigns.size })
        } catch (error) {
            throw error
        }
    }
})


