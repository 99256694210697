import React, { useEffect, useRef } from 'react'
import {
  Box,
  Stack,
  Heading,
  Text,
  Input,
  Button,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  GridItem,
  CircularProgress,
  chakra,
  FormLabel,
  HStack,
  Tooltip,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { db, storage } from '../../../firebase/config'
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore'
import { BsCamera } from 'react-icons/bs'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useCustomToast } from '../../../hooks/customToast'
import { convertToMb } from '../../../utils'

const BusinessDetails = ({
  isEditable,
  setIsEditAble,
  fetchBusiness,
  setFetchBusiness,
  tabIndex,
  user,
}) => {
  const { addToast } = useCustomToast()
  const [progress, setProgress] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const initialInputs = {
    businessName: '',
    businessType: '',
    businessAddress: '',
    businessPhone: '',
    businessEmail: '',
    businessDescription: '',
    businessUrl: '',
    businessLogo: '',
    country: '',
    city: '',
    state: '',
    zipCode: '',
    publicVisible: {
      businessPhone: true,
      businessEmail: true,
      businessAddress: true,
    },
  }
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialInputs,
      id: '',
    },
  })

  const uploadToFirestore = async (values, businessLogo) => {
    if (!values.id) {
      const collRef = collection(db, 'businesses')
      let result = await addDoc(collRef, { ...values, businessLogo, userId: user.uid })
      reset({ id: result.id })
      setFetchBusiness((prev) => ({ ...prev, ...values, id: result.id }))
      addToast({
        title: 'Business Details!',
        description: 'Added successfully',
        status: 'success',
        variant: 'left-accent',
      })
    } else {
      const docRef = doc(db, 'businesses', values.id)
      await updateDoc(docRef, { ...values, businessLogo })
      setFetchBusiness((prev) => ({ ...prev, ...values }))
      addToast({
        title: 'Business Details!',
        description: 'Updated successfully',
        status: 'success',
        variant: 'left-accent',
      })
      setIsEditAble(false)
    }
    setIsSubmitting(false)
  }

  const onSubmit = async (state) => {
    setIsSubmitting(true)
    let { businessLogo, ...values } = state
    try {
      if (typeof businessLogo === 'object') {
        uploadFile(businessLogo, (e) => uploadToFirestore(values, e))
      } else {
        uploadToFirestore(values, businessLogo)
      }
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setIsSubmitting(false)
    }
  }

  const handleImage = (e) => {
    const fileObj = e.target.files[0]
    let sizeInMB = convertToMb(fileObj)
    if (!fileObj) return
    if (!fileObj.type.includes('image')) {
      return addToast({
        title: 'Image',
        description: 'Can only upload images',
        status: 'error',
        variant: 'left-accent',
      })
    } else if (sizeInMB > 5) {
      addToast({
        title: 'Video',
        description: 'Size is greater than 5mb',
        status: 'error',
        variant: 'left-accent',
      })
    } else {
      setValue('businessLogo', fileObj)
    }
  }

  const uploadInputRef = useRef(null)

  const uploadFile = (file, callback = () => {}) => {
    if (!file) return
    const storageRef = ref(storage, `business_pics/${user.uid}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        setProgress(progress)
      },
      (error) => {
        addToast({
          title: 'Error',
          description: error.message,
          status: 'error',
          variant: 'left-accent',
        })
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          addToast({
            title: 'Image!',
            description: 'Image uploaded',
            status: 'success',
            variant: 'left-accent',
          })
          setValue('photoURL', downloadURL)
          callback(downloadURL)
        })
      }
    )
  }

  useEffect(() => {
    const { socialLinks, ...others } = fetchBusiness
    reset({ ...initialInputs, ...others })
  }, [fetchBusiness, reset])

  useEffect(() => {
    if (tabIndex === 1 && fetchBusiness?.id) {
      setIsEditAble(false)
    }
  }, [setIsEditAble, tabIndex])

  return (
    <Stack
      mt={10}
      bg={'gray.50'}
      rounded={'xl'}
      py={{ base: 4, sm: 6, md: 8 }}
      px={{ base: 2, sm: 4, md: 8 }}
      width='full'
      spacing={8}
    >
      <HStack justifyContent='space-between' w='full'>
        <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
          Business Details
        </Heading>
      </HStack>

      <SimpleGrid
        as={'form'}
        mt={10}
        onSubmit={handleSubmit(onSubmit)}
        columns={12}
        spacing={[2, 3, 4]}
        sx={{
          'input, select, textarea': {
            color: 'gray.700',
          },
        }}
      >
        <FormControl
          isInvalid={!!errors?.businessName}
          as={GridItem}
          colSpan={[12, 9, 10]}
          order={[2, 1]}
        >
          <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
            Business Name
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='Business Name'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('businessName')}
            disabled={!isEditable}
          />
          {errors.businessName && (
            <FormErrorMessage>{errors.businessName.message}</FormErrorMessage>
          )}
        </FormControl>
        <GridItem
          colSpan={[12, 3, 2]}
          order={[1, 2]}
          rowSpan={[1, 2]}
          textAlign='center'
          justifySelf={['center', 'stretch']}
        >
          <Box
            width={['100px', '95px', '110px']}
            minH={['100px', '100px']}
            display='flex'
            justifyContent={['center', 'flex-start']}
            alignItems='center'
            sx={{ position: 'relative' }}
            mb={[4, 0]}
            border='2px solid'
            borderColor='gray.300'
            bg={'rgb(0 0 0 / 5%)'}
            zIndex={1}
            _hover={{
              '& > .upload': {
                display: isEditable ? 'flex' : 'none',
                zIndex: 2,
                opacity: 1,
              },
            }}
          >
            {watch('businessLogo') ? (
              <chakra.img
                size='full'
                name='Kola Tioluwani'
                rounded={'none'}
                borderRadius='none'
                src={
                  typeof watch('businessLogo') === 'object'
                    ? URL.createObjectURL(watch('businessLogo'))
                    : watch('businessLogo')
                }
                width='100%'
                maxH={['100px', '120px']}
              />
            ) : (
              <Text w='full' fontSize={['1.5rem', '2rem', '2.5rem']} textAlign='center'>
                {user.firstName.charAt(0) + ' ' + user.lastName.charAt(0)}
              </Text>
            )}

            <Box
              className='upload'
              sx={{
                zIndex: -1,
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgb(0 0 0 / 30%)',
                overflow: 'hidden',
                width: 'full',
                height: 'full',
                transition: 'all 0.4s ease',
                opacity: 0,
              }}
            >
              {!(progress > 0 && progress < 100) ? (
                <Button
                  color='white'
                  variant='solid'
                  size='small'
                  sx={{ fontSize: '16px', display: 'flex', alignItems: 'center', gap: '5px' }}
                  onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                  p={1.5}
                  mx={4}
                  w='90%'
                  fontWeight='normal'
                >
                  <BsCamera /> <Text fontSize={'12px'}>Upload</Text>
                  <input
                    ref={uploadInputRef}
                    type='file'
                    accept='image/*'
                    style={{ display: 'none' }}
                    onChange={handleImage}
                  />
                </Button>
              ) : (
                <CircularProgress value={progress} color='teal.400' />
              )}
            </Box>
          </Box>
          <Text
            fontSize='sm'
            fontStyle={'italic'}
            color='gray.400'
            mt={1.5}
            width={['100px', '95px', '110px']}
          >
            Upload
          </Text>
        </GridItem>
        <FormControl isInvalid={!!errors?.businessPhone} as={GridItem} colSpan={[12, 6]} order={4}>
          <FormLabel
            fontSize={['sm', 'md']}
            color='gray.500'
            ml={0.5}
            display='flex'
            as={HStack}
            alignItems='center'
            spacing={3}
          >
            <Text>Business Phone</Text>
            <Tooltip hasArrow label='Publicly Visible' bg='teal.600' mb={2} placement='top'>
              <chakra.input
                color='teal'
                type='checkbox'
                w='16px'
                h='16px'
                sx={{
                  accentColor: '#00989D',
                }}
                disabled={!isEditable}
                {...register('publicVisible.businessPhone')}
                onChange={(e) => setValue('publicVisible.businessPhone', e.target.checked)}
              />
            </Tooltip>
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='Business Phone'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('businessPhone', {
              pattern: {
                value: /^[0-9]*$/i,
                message: 'Invalid phone address',
              },
            })}
            disabled={!isEditable}
          />
          {errors.businessPhone && (
            <FormErrorMessage>{errors.businessPhone.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors?.businessEmail} as={GridItem} colSpan={[12, 6]} order={5}>
          <FormLabel
            fontSize={['sm', 'md']}
            color='gray.500'
            ml={0.5}
            display='flex'
            as={HStack}
            alignItems='center'
            spacing={3}
          >
            <Text>Business Email ** REQUIRED </Text>
            <Tooltip hasArrow label='Publicly Visible' bg='teal.600' mb={2} placement='top'>
              <chakra.input
                color='teal'
                type='checkbox'
                w='16px'
                h='16px'
                sx={{
                  accentColor: '#00989D',
                }}
                disabled={!isEditable}
                {...register('publicVisible.businessEmail')}
                onChange={(e) => setValue('publicVisible.businessEmail', e.target.checked)}
              />
            </Tooltip>
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='Business Email'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('businessEmail', {
              required: 'This is required',
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: 'Invalid email address',
              },
            })}
            disabled={!isEditable}
          />
          {errors.businessEmail && (
            <FormErrorMessage>{errors.businessEmail.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors?.businessAddress} as={GridItem} colSpan={[12]} order={6}>
          <FormLabel
            fontSize={['sm', 'md']}
            color='gray.500'
            ml={0.5}
            display='flex'
            as={HStack}
            alignItems='center'
            spacing={3}
          >
            <Text> Business Address</Text>
            <Tooltip hasArrow label='Publicly Visible' bg='teal.600' mb={2} placement='top'>
              <chakra.input
                color='teal'
                type='checkbox'
                w='16px'
                h='16px'
                sx={{
                  accentColor: '#00989D',
                }}
                disabled={!isEditable}
                {...register('publicVisible.businessAddress')}
                onChange={(e) => setValue('publicVisible.businessAddress', e.target.checked)}
              />
            </Tooltip>
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='Business Address'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('businessAddress')}
            disabled={!isEditable}
          />
          {errors.businessAddress && (
            <FormErrorMessage>{errors.businessAddress.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors?.country} as={GridItem} colSpan={[6, 6]} order={7}>
          <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
            Country
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='Country'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('country')}
            disabled={!isEditable}
          />
          {errors.country && <FormErrorMessage>{errors.country.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.state} as={GridItem} colSpan={[6, 6]} order={8}>
          <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
            State
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='State'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('state')}
            disabled={!isEditable}
          />
          {errors.state && <FormErrorMessage>{errors.state.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.city} as={GridItem} colSpan={[6, 6]} order={9}>
          <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
            City
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='City'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('city')}
            disabled={!isEditable}
          />
          {errors.city && <FormErrorMessage>{errors.city.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors?.zipCode} as={GridItem} colSpan={[6, 6]} order={10}>
          <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
            Zip Code
          </FormLabel>
          <Input
            size={['sm', 'md', 'lg']}
            placeholder='Zip Code'
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('zipCode', {
              minLength: {
                value: 4,
                message: 'Zip Code must be at least 4 digits',
              },
              validate: (value) => {
                if (isNaN(value)) {
                  return 'It must be a number'
                }
              },
            })}
            disabled={!isEditable}
          />
          {errors.zipCode && <FormErrorMessage>{errors.zipCode.message}</FormErrorMessage>}
        </FormControl>

        <GridItem colSpan={[12]} order={14}>
          {!fetchBusiness?.id ? (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
              variant='solid'
              ml='auto'
            >
              Save
            </Button>
          ) : !isEditable ? (
            <Box
              type='button'
              as='button'
              name='edit'
              mt={3}
              w={'20%'}
              borderRadius='8'
              py='2'
              bg='brand.1'
              variant='solid'
              ml='auto'
              color='white'
              fontWeight={'bold'}
              onClick={() => setIsEditAble(true)}
            >
              Edit
            </Box>
          ) : (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
              variant='solid'
              ml='auto'
            >
              Update
            </Button>
          )}
        </GridItem>
      </SimpleGrid>
    </Stack>
  )
}

export default BusinessDetails