import { Flex } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { useRecoilState } from 'recoil'
import { db } from '../../../firebase/config'
import { businessDataAtom } from '../../../recoil/atoms/businessData.js'
import ForestDarkTheme from './CampaignBanner/ForestDarkTheme'
import LivelyTheme from './CampaignBanner/LivelyTheme'

export const CampaignBanner = ({ dataLoading, campaignData, wallTheme }) => {
  const [loading, setLoading] = useState(true)
  const [_, setBusinessData] = useRecoilState(businessDataAtom)

  const { campaignDetails, userId } = campaignData

  const getBusinessData = async () => {
    if (!userId) {
      setLoading(true)
    } else {
      const ref = await query(
        collection(db, 'businesses'),
        where('userId', '==', userId)
      )

      try {
        const docSnap = await getDocs(ref)

        await docSnap.docs.map((doc) => {
          let d = { ...doc.data(), id: doc.id }
          let object = {
            name: d.businessName,
            email: d.businessEmail,
            address: d.businessAddress,
            city: d.city,
            country: d.country,
            phone: d.businessPhone,
            social: d.socialLinks,
            zipCode: d.zipCode,
            description: d.businessDescription,
            type: d.businessType,
          }
          setBusinessData(object)
        })

        setLoading(false)
      } catch {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    getBusinessData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, loading])

  const avgRating = useMemo(() => !!campaignData?.reviews?.length ? (campaignData?.avgRating || 0) : 0, [campaignData])

  return (
    <Flex
      id='campaignData'
      flexDirection='row'
      borderRadius='md'
      py={{ base: '0', sm: '4' }}
      mx={{ base: '0', md: '16' }}
      justify={'center'}
    >
      {!campaignDetails?.wallTheme ?
        <LivelyTheme
          avgRating={avgRating}
          campaignData={campaignData}
          loading={loading}
        /> : (
          campaignDetails?.wallTheme === 'forest_dark' ?
            <ForestDarkTheme
              avgRating={avgRating}
              campaignData={campaignData}
              loading={loading}
              wallTheme={wallTheme}
            />
            : null
        )
      }
    </Flex >
  )
}