import { defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { StepsTheme, } from 'chakra-ui-steps';

const CustomSteps = {
    ...StepsTheme,
    sizes: {
        ...StepsTheme.sizes,
        lg: {
            ...StepsTheme.sizes.lg,
            description: {
                ...StepsTheme.sizes.lg.description,
                textAlign: 'left',
            }
        },
    }
};


const Button = defineStyleConfig({
    baseStyle: {
        borderRadius: '5px'
    },
    sizes: {
        sm: {
            fontSize: 'sm',
            px: 4,
            py: 3
        },
        md: {
            fontSize: 'md',
            px: 6,
            py: 4
        },
    },
    variants: {
        outline: {
            border: '1px solid',
            borderColor: 'brand.1',
            color: 'brand.1'
        },
        solid: {
            bg: 'brand.1',
            color: 'white',
            _hover: {
                backgroundColor: "brand.10"
            }
        }
    },
    defaultProps: {
        size: 'md',
        fontSize: 'md',
        fontWeight: 400,
        color: 'white',
        variant: 'outline',
    },
})

const Tooltip = defineStyleConfig({
    baseStyle: {
        placement: "bottom",
        color: "white",
        bg: "teal.400",
        px: [1, 2],
        rounded: "md",
    },
})

export const colors = {
    brand: {
        1: '#03989E',
        2: '#7ED957',
        3: '#999999',
        4: '#D9D9D9',
        10: "#03989ede"
    },
}

const theme = extendTheme({
    colors,
    components: {
        Button,
        Tooltip,
        Steps: CustomSteps,
    }
})

export default theme;