import React, { useRef } from 'react'
import {
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Button,
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react'
import RecordView from './recorder'
import { useCustomToast } from '../../../hooks/customToast'
import { convertToMb } from '../../../utils'
import ProfessionalVideoModal from '../components/ProfessionalVideoModal'

const WelcomePage = ({ stepMethods, getData, state }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isProfessionalOpen,
    onOpen: onProfessionalOpen,
    onClose: onCloseProfessional,
  } = useDisclosure()

  let { nextStep } = stepMethods
  const { addToast } = useCustomToast()

  const uploadInputRef = useRef(null)

  const handleVideo = (e, file = null, title) => {
    const fileObj = file || e?.target?.files?.[0]
    try {
      if (!fileObj) return
      let sizeInMB = convertToMb(fileObj)
      if (fileObj && !fileObj.type.includes('video')) {
        return addToast({ title: 'Video', description: 'Can only upload videos', status: 'error' })
      } else if (sizeInMB > 200 || sizeInMB < 0.01) {
        addToast({
          title: 'Video',
          description: sizeInMB < 0.23 ? 'Size is less than 10kb' : 'Size is greater than 200mb',
          status: 'error',
          variant: 'left-accent',
        })
      } else {
        getData({ welcomeVideo: fileObj, welcomeVideoTitle: title || state?.welcomeVideoTitle })
        nextStep()
      }
    } catch (error) {
      addToast({
        title: 'Video',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
    }
  }

  const handleRecorderClose = (_) => {
    onClose()
  }

  return (
    <Stack width='full' spacing={10} my={[8, '10']}>
      <Heading
        color={'brand.1'}
        lineHeight={1.1}
        fontSize={{ base: 'lg', sm: 'xl', md: '3xl' }}
        textAlign='center'
        width={['full', '90%', '80%']}
        mx='auto'
      >
        Record or upload a finished video asking your customers to leave a video testimonial
      </Heading>

      <Flex justifyContent='center'>
        <SimpleGrid
          columns={12}
          spacing={[4, 8]}
          justifyItems={{ base: 'center', lg: 'center' }}
          width={['full', '90%']}
        >
          <GridItem border={'0px solid'} colSpan={[12, 12, 12, 6]} alignSelf='center'>
            <chakra.img
              boxSize={'500px'}
              borderRadius={'8px'}
              width='100%'
              height='100%'
              maxWidth={'400px'}
              src={'https://sendmea.io/images/sendmeaimage.png'}
            >
            </chakra.img>
          </GridItem>
          <GridItem border={'0px solid'} colSpan={[12, 12, 12, 6]} maxWidth='355px'>
            <Stack
              spacing={[4]}
              px={[2, 4]}
              py={[4]}
              bg={'gray.100'}
              rounded='md'
              width={'full'}
              maxWidth={['300px', 'full']}
              height={'100%'}
              justifyContent='center'
            >
              <Text textAlign={'center'} fontWeight='bold' fontSize={['md', 'lg']}>
                Create a custom greeting for your campaign
              </Text>
              <Text
                textAlign={'center'}
                fontWeight='bold'
                fontSize={['sm', 'md']}
                color={state?.welcomeVideo ? 'teal.500' : 'red.400'}
              >
                {state?.welcomeVideo
                  ? 'A campaign video is added! visit step 3 for video.'
                  : 'A campaign video has not been added'}
              </Text>
              <Stack spacing={4}>
                <Button
                  variant='outline'
                  color='brand.1'
                  size={['sm', 'md']}
                  _hover={{
                    bg: 'brand.1',
                    color: 'white',
                  }}
                  rounded='3xl'
                  onClick={onOpen}
                >
                  Record a welcome video
                </Button>
                <Button
                  variant='outline'
                  color='brand.1'
                  size={['sm', 'md']}
                  _hover={{
                    bg: 'brand.1',
                    color: 'white',
                  }}
                  rounded='3xl'
                  onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >
                  <input
                    ref={uploadInputRef}
                    type='file'
                    accept='video/*'
                    style={{ display: 'none' }}
                    onChange={handleVideo}
                  />
                  Upload a video
                </Button>
                <Button
                  variant='outline'
                  color='brand.1'
                  size={['sm', 'md']}
                  _hover={{
                    bg: 'brand.1',
                    color: 'white',
                  }}
                  rounded='3xl'
                  onClick={(_) => onProfessionalOpen()}
                >
                  Select a Professional Video
                </Button>
              </Stack>
            </Stack>
          </GridItem>
        </SimpleGrid>
      </Flex>

      <ProfessionalVideoModal
        isOpen={isProfessionalOpen}
        onOpen={onProfessionalOpen}
        onClose={onCloseProfessional}
      />

      <Modal isOpen={isOpen} onClose={handleRecorderClose}>
        <ModalOverlay />
        <ModalContent my={'auto'}>
          <ModalHeader>Record</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecordView
              getData={getData}
              nextStep={nextStep}
              recorderView={isOpen}
              handleVideo={handleVideo}
              state={state}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default WelcomePage
