
import { HStack, IconButton, Tag, TagLabel, TagLeftIcon, Text, Tooltip, VStack, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { format } from 'date-fns'
import { BiErrorAlt, BiLogInCircle, BiLogOutCircle, BiWorld } from 'react-icons/bi'
import { AnimatePresence, motion } from 'framer-motion'
import { FaExclamation } from "react-icons/fa";

const SimpleLogCard = ({ log }) => {

    const { ...data } = log;

    const iconMap = {
        login: BiLogOutCircle,
        logout: BiLogInCircle,
        error: BiErrorAlt,
    };

    const colorScheme = {
        login: 'green',
        logout: 'blackAlpha',
        error: 'red',
    };

    const TypeToolTip = ({ val, children }) => {

        const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

        return (
            <Tooltip
                hasArrow
                arrowShadowColor={'blue.500'}
                bg='blue.500'
                borderRadius='md'
                placement={'top'}
                isOpen={isOpen}
                maxW={'400px'}
                textAlign='center'
                rounded='md'
                filter={'brightness(1.2) drop-shadow(3px 3px 6px gray)'}
                label={
                    <VStack p={1.2} spacing={1.5} alignItems='flex-start' >
                        <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Log Screen:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{(val?.originatedFrom)?.split("_")?.join(' ')}</Text>
                        </VStack>
                        <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>ip:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{(val?.ip)}</Text>
                        </VStack>
                        {val?.userName && <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Reviewer Name:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{(val?.userName)}</Text>
                        </VStack>}
                        {val?.userEmail && <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Reviewer Email:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' flexWrap={'wrap'}>{(val?.userEmail?.toLowerCase())}</Text>
                        </VStack>}
                        {val?.start && <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Recording Started {val?.numOfTimesStart?.length ? `(${val?.numOfTimesStart?.length})` : ''} at:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{format(new Date(val?.startTime?.toDate()), "do MMM, yyyy 'at' h:mm:ss a")}</Text>
                        </VStack>}
                        {val?.stop && <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Recording Stoped {val?.numOfTimesStop?.length ? `(${val?.numOfTimesStop?.length})` : ''} at:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{format(new Date(val?.stopTime?.toDate()), "do MMM, yyyy 'at' h:mm:ss a")}</Text>
                        </VStack>}
                        {val?.savedAt && <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Recording Saved at:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{format(new Date(val?.savedAt?.toDate()), "do MMM, yyyy 'at' h:mm:ss a")}</Text>
                        </VStack>}
                    </VStack>
                }
            >
                <span onMouseEnter={onOpen} onMouseLeave={onClose} onClick={onToggle}>
                    {children}
                </span>
            </Tooltip>
        )
    }

    return (
        <VStack w='full'
            key={data.id}
            p={{ base: 4, md: 4, }}
            alignItems='flex-start'
            bg="white"
            shadow="lg"
            rounded="lg"
            spacing={0}
            gap={'3'} maxW={'380px'}
        >
            <VStack w='full' alignItems='center' spacing={1.5}>
                <VStack spacing={2} alignItems={'center'}>
                    <VStack alignItems={'center'} spacing={0.4}>
                        <Text fontSize={['sm']} fontWeight={'500'}>{'User Name'}</Text>
                        <Text fontSize={['md']} fontWeight={'500'} color='teal' >{data?.userData?.firstName + (data?.userData?.lastName || "")}</Text>
                    </VStack>
                    <VStack alignItems={'center'} justifyContent={'center'} spacing={0.4}>
                        <Text fontSize={['sm']} fontWeight={'500'}>{'Campaign Name'}</Text>
                        <Text fontSize={['md']} fontWeight={'500'} textAlign={'center'} color='teal' as={'a'} href={`${window.location.origin}/wall/${data?.campaignData?.vanity || data?.campaignLinkId}`} target='_blank'>{data?.campaignName}</Text>
                    </VStack>
                    <VStack spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'} textTransform={'capitalize'}>Created At:</Text>
                        <Text fontSize={['xs']} textTransform='capitalize' color='gary.400' textAlign='center'>
                            {format(new Date(data?.createdAt?.toDate()), "do MMM, yyyy 'at' h:mm a")}
                        </Text>
                    </VStack>
                    <HStack spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'}>Type:</Text>
                        <AnimatePresence>
                            <motion.span
                                animate={{
                                    scale: [0.8, 1, 0.8, 1],
                                }}
                                transition={{
                                    duration: 0.75,
                                    delay: 0.5,
                                    repeat: 2,
                                    repeatType: "reverse",
                                }}
                            >
                                <TypeToolTip val={data}>
                                    <Tag mt={1} size={['md']} variant='subtle' colorScheme={colorScheme[data?.type]}>
                                        <TagLabel textTransform={'capitalize'}>{data?.type}</TagLabel>
                                        <TagLeftIcon boxSize='12px' as={iconMap[data?.type]} mt='2px !important' ml='5px !important' mr='0 !important' />
                                    </Tag>
                                </TypeToolTip>
                            </motion.span>
                        </AnimatePresence>
                        {data?.reviewUrl && <IconButton
                            variant={"link"}
                            icon={<BiWorld fontSize={"18px"} />}
                            cursor="pointer"
                            size="sm"
                            p={0}
                            color="teal.500"
                            as={'a'}
                            href={data?.reviewUrl}
                            target='_blank'
                        />}
                        {data?.permission && <Tooltip
                            label={data?.permission}
                            hasArrow
                            arrowShadowColor={'red.300'}
                            bg='red.500'
                            borderRadius='md'
                            placement={'top'}
                            maxW={'400px'}
                            textAlign='center'
                            rounded='md'
                        >
                            <IconButton
                                variant={"link"}
                                icon={<FaExclamation fontSize={"18px"} />}
                                cursor="pointer"
                                size="sm"
                                p={2}
                                color="red.500"
                                bg='red.200'
                            /></Tooltip>}

                    </HStack>
                    <HStack spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'}>Browser:</Text>
                        <Text fontSize={['sm']} textTransform='capitalize' color='gary.400'>{data?.browserName}</Text>
                    </HStack>
                    <HStack spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'}>OS:</Text>
                        <Text fontSize={['sm']} textTransform='capitalize' color='gary.400'>{data?.osName} {data?.osVersion}</Text>
                    </HStack>
                </VStack>
            </VStack>

        </VStack >
    )
}

export default SimpleLogCard;