import React from 'react'
import { Box, chakra, Text, useColorModeValue as mode, Stack, VStack } from '@chakra-ui/react'
import { terms } from './termData'

function replaceWithBr() {
  return terms.replace(/\n/g, '<br />')
}

function Terms() {
  return (
    <Stack>
      <Box
        p='6'
        my='8'
        bg='bg-surface'
        boxShadow={mode('xl', 'xl-dark')}
        _groupHover={{ boxShadow: mode('xl', 'xl-dark') }}
        transition='all 0.2s'
        maxW={'container.lg'}
        mx='auto'
      >
        <Text fontSize={['1.85rem', '2rem']} fontWeight={'600'} m={'4'} textAlign={'center'}>
          <chakra.span color={'brand.1'}>Terms</chakra.span>
          <chakra.span color={'brand.2'}> of </chakra.span>
          <chakra.span color={'brand.1'}>Service</chakra.span>
        </Text>

        <VStack alignItems={'flex-start'} spacing={2}>
          <p dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
        </VStack>
      </Box>
    </Stack>
  )
}

export default Terms
