import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  Image,
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
  theme,
  HStack,
} from '@chakra-ui/react'
import { HamburgerIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom'
import { auth } from '../firebase/config'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { stripePlanId, userLoggedIn, userState } from '../recoil/atoms/auth'

import Slogo from '../assets/images/sendmea-logo.png'
import { useEffect, useState } from 'react'
import { MdDashboard, MdOutlinePriceChange, MdRateReview } from 'react-icons/md'
import { AiFillFileText } from 'react-icons/ai'
import { IoShareSocialSharp } from 'react-icons/io5'
import { FaBlog, FaQuestionCircle } from 'react-icons/fa'
import { saveLogoutLogs } from '../pages/Login/utils'


export const scrollToOtherPageElement = (id, allowMultiple, navBarHeight) => {

  const sectionId = id.split('#')[1]
  const section = document.querySelector(`#${sectionId}`)
  const notLoopIds = ['sales', 'getting-started']
  navBarHeight = navBarHeight || 64

  for (let i = 0; i <= 2; i++) {
    if (i === 0) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offset: -80 })
    }
    else if (!notLoopIds.includes(sectionId) || allowMultiple) {
      setTimeout(() => {
        window.scrollTo(0, (section.getBoundingClientRect().top + window.scrollY) - (navBarHeight))
      }, i === 1 ? i * 800 : (i * 800) - 400);
    }

  }
}

export default function WithSubnavigation() {

  const { pathname } = useLocation()
  const location = useLocation()
  const navigate = useNavigate()

  const [user, setUser] = useRecoilState(userState)
  const setIsUserLoggedIn = useSetRecoilState(userLoggedIn)
  const setStripePlanId = useSetRecoilState(stripePlanId)

  const logout = async () => {
    await auth.signOut()
    await saveLogoutLogs({
      userEmail: user?.email,
      userId: user.uid,
      loginId: user?.loginId || ''
    })
    navigate('/login')
    setUser(null)
    setIsUserLoggedIn(false)
    setStripePlanId('')
  }

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setIsSticky(true) : setIsSticky(false);
    }
  };

  return (
    <Box id='navbar'
      transition="all 0.6s ease-in-out"
      {...(isSticky && ({
        width: 'full',
        position: 'fixed', zIndex: '10', top: '0%',
      }))}
    >
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        boxShadow="sm"
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        gap={['2', '4']}
      >
        <Stack
          direction='row'
          cursor={'pointer'}
          onClick={() => navigate('/')}
        >
          <Image maxH='50px' width={["150px", "200px"]} src={Slogo} alt='logo' />
        </Stack>
        <Flex display={{ base: 'none', md: 'none', lg: "flex" }} mx={'auto'}>
          <DesktopNav user={user} />
        </Flex>
        {/* </Flex> */}

        <Stack
          flex={{ base: 1, md: 1, lg: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={[2, 2, 3]}
        >
          {user === null && pathname !== '/register' && pathname !== '/blog' && (
            <Button
              display={{ base: 'none', sm: 'flex' }}
              borderRadius="4px"
              fontWeight={600}
              variant={'outline'}
              color={'brand.1'}
              as={RouterLink}
              to={'/register'}
              sx={{
                border: (theme) => `1px solid ${theme.colors.brand['1']}!important`,
              }}
              size={{ base: "sm", sm: "sm", md: "md" }}
            >
              Sign up
            </Button>
          )}
          {user === null && pathname !== '/login' && (
            <Button as={RouterLink} to={'/login'} variant={'solid'} borderRadius="4px"
              size={{ base: "sm", sm: "sm", md: "md" }}
            >
              Log In
            </Button>
          )}
          {user !== null && (
            <Button
              fontSize={'sm'}
              fontWeight={600}
              color={'red'}
              style={{ border: '1px solid red' }}
              onClick={logout}
              size={{ base: "sm", sm: "sm", md: "md" }}
              borderRadius="4px"
              _hover={{
                bg: "red.50"
              }}
            >
              Logout
            </Button>
          )}
        </Stack>

        <Menu>
          <MenuButton
            as={IconButton}
            display={{ base: 'block', md: 'block', lg: "none" }}
            variant='ghost'
            aria-label='open menu'
            fontWeight='bold'
            icon={<HamburgerIcon />}
            height={["32px"]}
          />
          <MenuList
            zIndex={10}
            bg={'white'}
            boxShadow="lg"
            borderColor={'gray.200'}
            sx={{
              minH: '80px',
              overflow: 'auto',
              py: 0,
              mt: 1.5
            }}
          >
            <MenuItem p={2} display={[!user ? "block" : "none", "none"]} border={`1px solid ${theme.colors.gray[100]}`}
              _focus={{
                bg: 'white',
              }}>
              <Button
                fontWeight={600}
                variant={'solid'}
                as={RouterLink}
                to={'/register'}
                size={"sm"}
                w="full"
                rounded="sm"
              >
                Sign up
              </Button>
            </MenuItem>
            {(user
              ? NAV_ITEMS
              : NAV_ITEMS.filter((v) => v.label !== 'Dashboard')
            ).map((navItem, i) => (
              <MenuItem
                key={navItem.label}
                sx={{
                  bg: 'white',
                  maxW: 'xs',
                  flexWrap: 'wrap',
                  py: [3, 3.5],
                }}
                _hover={{
                  bg: 'teal.50',
                }}
                _focus={{
                  bg: 'white',
                }}
                cursor='pointer'
                borderBottom={(NAV_ITEMS.length !== i + 1) ? `1px solid ${theme.colors.gray[100]}` : 'none'}
                onClick={() => {
                  if (navItem.label === 'Blog') {
                    return window.open(navItem.href, '_blank')
                  }
                  else if (navItem.href.includes('#')) {
                    location.pathname !== '/' && navigate('/')
                    return setTimeout(() => {
                      scrollToOtherPageElement(navItem.href)
                    }, 20)
                  }
                  navigate(navItem.href)
                }}
              >
                <HStack alignItems='center'>
                  <Icon as={navItem.icon} fontSize={'22px'} color={theme.colors.teal[500]} />
                  <Text textTransform='capitalize' fontWeight='500' fontSize={['15px']}>
                    {navItem.label}
                  </Text>
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

      </Flex>

    </Box >
  )
}

const DesktopNav = ({ user }) => {

  const popoverContentBgColor = useColorModeValue('white', 'gray.800')
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Stack id='desktopNav' direction={'row'} alignItems='center' spacing={0} gap={2}>
      {(user
        ? NAV_ITEMS
        : NAV_ITEMS.filter((v) => v.label !== 'Dashboard')
      ).map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Button
                p={2}
                fontWeight={500}
                onClick={() => {
                  if (navItem.label === 'Blog') {
                    return window.open(navItem.href, '_blank')
                  }
                  else if (navItem.href.includes('#')) {
                    location.pathname !== '/' && navigate('/')
                    return setTimeout(() => {
                      scrollToOtherPageElement(navItem.href)
                    }, 100)
                  }
                  navigate(navItem.href)
                  setTimeout(() => {
                    window.scrollTo({
                      top: 5,
                      behavior: 'auto',
                    })
                  }, 20)
                }}
                variant="ghost"
                size="sm"
                colorScheme="teal"
              >
                {navItem.label}
              </Button>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Link
      onClick={() => {
        if (href.includes('#')) {
          location.pathname !== '/' && navigate('/')
          return setTimeout(() => {
            scrollToOtherPageElement(href)
          }, 100)
        }
        navigate(href)
      }}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} _groupHover={{ color: 'pink.400' }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  )
}

const NAV_ITEMS = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    icon: MdDashboard
  },
  {
    label: 'Getting Started',
    href: '#getting-started',
    icon: AiFillFileText
  },
  {
    label: 'Social Benefits',
    href: '#benefits',
    icon: IoShareSocialSharp
  },
  {
    label: 'Pricing',
    href: '#pricing',
    icon: MdOutlinePriceChange
  },
  {
    label: 'Reviews',
    href: '#campaigns',
    icon: MdRateReview
  },
  {
    label: 'Blog',
    href: 'https://blog.sendmea.io',
    icon: FaBlog
  },
  {
    label: 'FAQ',
    href: '/faq',
    icon: FaQuestionCircle
  },
]
