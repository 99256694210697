import {
  Box,
  Divider,
  Heading,
  List,
  ListItem,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import {  CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'

const Details = (props) => {
  const { children, iconColor, ...rest } = props
  return (
    <ListItem display="flex" alignItems="flex-start" fontWeight="medium" maxW="260px" {...rest}>
      <CheckIcon marginEnd="3" mt="1" color={iconColor} />
      <Text as="span" display="inline-block">
        {children}
      </Text>
    </ListItem>
  )
}
const Negatives = (props) => {
  const { children, iconColor, ...rest } = props
  return (
    <ListItem display="flex" alignItems="flex-start" fontWeight="medium" maxW="260px" {...rest}>
      <SmallCloseIcon  marginEnd="3" mt="1"color={iconColor} />
      <Text as="span" display="inline-block">
        {children}
      </Text>
    </ListItem>
  )
}

export const SingleCard = (props) => {
  const {
    features,
    negatives,
    name,
    description,
    duration,
    price,
    extras,
    onClick,
    colorScheme: c,
    ...rest
  } = props
  return (
    <Box
      p={{
        base: '10',
        md: '16',
      }}
      {...rest}
    >
      <Heading>{name}</Heading>
      <Divider
        opacity={1}
        borderWidth="2px"
        borderColor={mode(`${c}`, `${c}`)}
        my="6"
        w="56px"
      />

      <Text maxW="280px" fontSize="lg">
        {description}
      </Text>

      <Box mt="2">
        <Text
          fontSize={{
            base: '6xl',
            md: '7xl',
          }}
          fontWeight="extrabold"
        >
          {price}
        </Text>
        <Text casing="uppercase" fontWeight="bold">
          {duration}
        </Text>
        <Text mt="2" color={mode('gray.600', 'gray.400')}>
          {extras}
        </Text>
      </Box>

      <Box>
        <Text fontWeight="bold" mb="4">
          What you get:
        </Text>
        <List spacing="4">
          {features.map((feature, index) => (
            <Details key={index} iconColor={'brand.1'}>
              {feature}
            </Details>
          ))}
            {negatives.map((negative, index) => (
            <Negatives key={index} iconColor={mode(`${c}.500`, `${c}.200`)}>
              {negative}
            </Negatives>
          ))}
        </List>
      </Box>
    </Box>
  )
}
