
import { Avatar, Box, Button, HStack, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Tag, TagLabel, TagLeftIcon, Text, Tooltip, VStack, theme, useDisclosure } from '@chakra-ui/react'
import { format, isValid } from 'date-fns'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { BiSupport } from 'react-icons/bi'
import CheckMarkIcon from '../../../../../../assets/icons/CheckMarkIcon'
import ExclamationIcon from '../../../../../../assets/icons/ExclamationMarkIcon'
import EyesIcon from '../../../../../../assets/icons/EyesIcon'
import LaptopIcon from '../../../../../../assets/icons/LaptopIcon'
import { db } from '../../../../../../firebase/config'
import { useCustomToast } from '../../../../../../hooks/customToast'

const ContactToolTip = ({ val, children }) => {

    const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

    return (
        <Tooltip
            hasArrow
            arrowShadowColor={'#1f7decc7'}
            border='2px solid'
            borderColor='#1f7decc7'
            bg='#2382f4d6'
            borderRadius='md'
            placement={'top'}
            isOpen={isOpen}
            maxW={'250px'}
            textAlign='center'
            rounded='md'
            filter={'brightness(1.2) drop-shadow(3px 3px 6px gray)'}
            label={
                <VStack p={1.2} spacing={1.5} alignItems='flex-start' >
                    <VStack spacing={0} alignItems='flex-start'>
                        <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Name:</Text>
                        <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{(val?.name)?.split("_")?.join(' ')}</Text>
                    </VStack>
                    {val?.phoneNumber &&
                        <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Phone:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm'>{val?.phoneNumber}</Text>
                        </VStack>
                    }
                    <VStack spacing={0} alignItems='flex-start'>
                        <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Message:</Text>
                        <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm'>{val?.message}</Text>
                    </VStack>
                    <VStack spacing={0} alignItems='flex-start'>
                        <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>For:</Text>
                        <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm'>{val?.for}</Text>
                    </VStack>
                    {val?.ip && (
                        <VStack spacing={0} alignItems='flex-start'>
                            <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>ip:</Text>
                            <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm' textTransform={'capitalize'}>{(val?.ip)}</Text>
                        </VStack>
                    )}
                </VStack>
            }
        >
            <span onMouseEnter={onOpen} onClick={onToggle} onMouseLeave={onClose}>
                {children}
            </span>
        </Tooltip>
    )
}


const IconPopover = ({ val, approvedIconByName, children, log, user: loginUser }) => {

    const { isOpen, onToggle, onClose } = useDisclosure();
    const user = val?.markedByUser || {};
    const { addToast } = useCustomToast()


    const [comment, setComment] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSave = async () => {
        try {
            setIsLoading(true)
            try {
                if (val?.isMarked) return false;
                let prevIconsStatus = log?.iconsStatus || {}
                if (Object.keys(prevIconsStatus).length > 0) {
                    Object.keys(prevIconsStatus).map(iconKey => {
                        const { markedByUser, ...rest } = prevIconsStatus?.[iconKey]
                        prevIconsStatus = { ...prevIconsStatus, [iconKey]: { ...rest } }
                    })
                }
                const iconName = val?.name
                const errorLogRef = doc(db, 'logs', log.id)
                await updateDoc(errorLogRef, {
                    iconsStatus: {
                        ...(prevIconsStatus),
                        [iconName]: {
                            markedAt: serverTimestamp(),
                            markedBy: loginUser?.uid,
                            isMarked: !val?.isMarked,
                            comment
                        }
                    }
                })
                setIsLoading(false)
                addToast({ title: 'Status', description: `${val?.title} Icon Marked As ${!val?.isMarked}`, status: 'success' })
            } catch (error) {
                setIsLoading(false)
                console.log(error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={onClose}
            placement='top'
        >
            <PopoverTrigger>
                <span
                    onClick={onToggle}
                >
                    {children}
                </span>
            </PopoverTrigger>
            <PopoverContent _focus={{ boxShadow: 'md' }}
                borderColor='#7ed957b5'
                bg='teal.600'
                maxW='250px'
                filter={'brightness(1.2) drop-shadow(3px 3px 6px gray)'}
            >
                <PopoverArrow
                    bg='teal.600'
                    borderColor={'#7ed957b5'}

                />
                <PopoverCloseButton color={'white'} />
                <PopoverBody py={0}>
                    <VStack p={1.5} px={[0.8]} spacing={1.5} alignItems='flex-start' >
                        {val?.isMarked ?
                            <>
                                <VStack spacing={2} alignItems='flex-start'>
                                    <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Marked By:</Text>
                                    <VStack spacing='3px' alignItems='flex-start' px={0}>
                                        <Avatar
                                            src={user?.photoURL}
                                            boxSize={'30px'}
                                            border={(theme) => '1px solid' + theme.colors.gray['200']}
                                        />
                                        <VStack spacing={'0px'} alignItems='flex-start'>
                                            <Text fontSize={['14px']} fontWeight='bold' color='white' whiteSpace='nowrap'>
                                                {user?.firstName + " " + user?.lastName}
                                            </Text>
                                            <Text fontSize={['12px']} fontWeight='bold' color='gray.400' overflow='anywhere'>
                                                {user?.email}
                                            </Text>
                                        </VStack>
                                    </VStack>
                                </VStack>
                                <VStack spacing={0} alignItems='flex-start'>
                                    <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Marked At:</Text>
                                    <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize={['sm']} textTransform={'capitalize'}>
                                        {isValid(new Date(val?.markedAt?.toDate())) ? format(new Date(val?.markedAt?.toDate()), "do MMM, yyyy 'at' h:mm a") : '...'}
                                    </Text>
                                </VStack>
                                {val?.comment &&
                                    <VStack spacing={0} alignItems='flex-start'>
                                        <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Comment:</Text>
                                        <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize={['sm']} textTransform={'capitalize'}>
                                            {val?.comment}
                                        </Text>
                                    </VStack>
                                }
                            </> :
                            <>
                                <VStack spacing={0} alignItems='flex-start'>
                                    <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Icon Name:</Text>
                                    <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm'>{val?.title}</Text>
                                </VStack>
                                <VStack spacing={0} alignItems='flex-start'>
                                    <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>About:</Text>
                                    <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize={['14px']}>{val?.description}</Text>
                                </VStack>
                                <VStack spacing={0} alignItems='flex-start'>
                                    <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Status:</Text>
                                    <Text color='whiteAlpha.900' fontStyle='italic' textAlign="left" fontSize='sm'>{'Yet to be marked'}</Text>
                                </VStack>
                                <VStack spacing={1} alignItems='flex-start' w='full'>
                                    <Text color='brand.2' fontStyle='italic' fontWeight='600' fontSize='sm'>Add Comment:</Text>
                                    <Input
                                        width={'full'}
                                        placeholder='Type Comment...'
                                        bg={'gray.100'}
                                        border={0}
                                        borderRadius={'4px'}
                                        color={'gray.500'}
                                        _placeholder={{
                                            color: 'gray.500',
                                        }}
                                        fontSize={'14px'}
                                        size={'sm'}
                                        onChange={e => setComment(e.target.value)}
                                    />
                                </VStack>
                                <VStack spacing={0} alignItems='flex-start'>
                                    <Button my={1} size='sm' variant={'solid'} onClick={handleSave} isLoading={isLoading}>Save</Button>
                                </VStack>
                            </>

                        }
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

const ContactCard = ({ log, user }) => {

    const { ...data } = log;
    const { addToast } = useCustomToast();
    const { colors } = theme;


    const iconsStatusData = {
        error: {
            icon: (isMarked) => (
                <ExclamationIcon
                    fill={isMarked ? colors.red['400'] : '#c5c4c4'}
                    width={'20px'}
                    height={'20px'}
                />
            ),
            name: 'error',
            title: 'Error',
            description: 'This means Error by default, team is yet to work on it',
        },
        eyes: {
            icon: (isMarked) => (
                <EyesIcon
                    fill={isMarked ? colors.gray['500'] : '#c5c4c4'}
                    width={'20px'}
                    height={'20px'}
                />
            ),
            name: 'eyes',
            title: 'Eyes',
            description: 'This means user is looking into the error',
        },
        beingFixed: {
            icon: (isMarked) => (
                <LaptopIcon
                    fill={isMarked ? colors.blackAlpha['700'] : '#c5c4c4'}
                    width={'25px'}
                    height={'25px'}
                />
            ),
            name: 'beingFixed',
            title: 'Being Fixed',
            description: 'This means a fix is needed and being done',
        },
        fixed: {
            icon: (isMarked) => (
                <CheckMarkIcon
                    fill={isMarked ? colors.green['400'] : '#c5c4c4'}
                    width={'20px'}
                    height={'20px'}
                />
            ),
            name: 'fixed',
            title: 'Fixed',
            description: 'This means the error is fixed',
        },
    };


    const approvedIconByName = async (iconObj) => {
        try {
            let prevIconsStatus = log?.iconsStatus || {}
            if (Object.keys(prevIconsStatus).length > 0) {
                Object.keys(prevIconsStatus).map(iconKey => {
                    const { markedByUser, ...rest } = prevIconsStatus?.[iconKey]
                    prevIconsStatus = { ...prevIconsStatus, [iconKey]: { ...rest } }
                })
            }
            const iconName = iconObj?.name
            const errorLogRef = doc(db, 'logs', log.id)
            await updateDoc(errorLogRef, {
                iconsStatus: {
                    ...(prevIconsStatus),
                    [iconName]: {
                        markedAt: serverTimestamp(),
                        markedBy: user?.uid,
                        isMarked: !iconObj?.isMarked
                    }
                }
            })
            addToast({ title: 'Status', description: `${iconObj?.title} Icon Marked As ${!iconObj?.isMarked}`, status: 'success' })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <VStack w='full'
            key={data.id}
            p={{ base: 4, md: 4, }}
            alignItems='flex-start'
            bg="white"
            shadow="lg"
            rounded="lg"
            spacing={0}
            gap={'3'} maxW={'380px'}
        >
            <VStack w='full' alignItems='center' spacing={1.5}>
                <VStack spacing={2} alignItems={'center'}>
                    <VStack alignItems={'center'} spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'}>{'Email'}</Text>
                        <Text fontSize={['xs']} color='gary.400' overflowWrap={'anywhere'} textAlign={'center'}>{data?.userEmail}</Text>
                    </VStack>
                    <VStack spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'} textTransform={'capitalize'}>Created At:</Text>
                        <Text fontSize={['xs']} textTransform='capitalize' color='gary.400' textAlign='center'>
                            {isValid(new Date(data?.createdAt?.toDate())) ? format(new Date(data?.createdAt?.toDate()), "do MMM, yyyy 'at' h:mm a") : '...'}
                        </Text>
                    </VStack>
                    <HStack spacing={0.8} alignItems={'center'}>
                        <Text fontSize={['sm']} fontWeight={'500'}>For:</Text>
                        <AnimatePresence>
                            <motion.span
                                animate={{
                                    scale: [0.8, 1, 0.8, 1],
                                }}
                                transition={{
                                    duration: 0.75,
                                    delay: 0.5,
                                    repeat: 2,
                                    repeatType: "reverse",
                                }}
                            >
                                <ContactToolTip val={data}>
                                    <Tag mt={1} size={['md']} variant='subtle' colorScheme={'yellow'}>
                                        <TagLabel textTransform={'capitalize'}>{data?.for}</TagLabel>
                                        <TagLeftIcon boxSize='12px' as={BiSupport} mt='2px !important' ml='5px !important' mr='0 !important' />
                                    </Tag>
                                </ContactToolTip>
                            </motion.span>
                        </AnimatePresence>
                    </HStack>
                    <HStack spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'}>Browser:</Text>
                        <Text fontSize={['sm']} textTransform='capitalize' color='gary.400'>{data?.browserName}</Text>
                    </HStack>
                    {data?.osName && <HStack spacing={0.8}>
                        <Text fontSize={['sm']} fontWeight={'500'}>OS:</Text>
                        <Text fontSize={['sm']} textTransform='capitalize' color='gary.400'>{data?.osName} {data?.osVersion}</Text>
                    </HStack>}
                </VStack>
            </VStack>
            <HStack mt={2} spacing={1.5} justifyContent={'center'} w='full'>
                {Object.keys(iconsStatusData).map(iconKey => {
                    const iconObj = {
                        ...(log?.iconsStatus?.[iconKey] || {}),
                        name: iconKey,
                        title: iconsStatusData[iconKey]?.title,
                        description: iconsStatusData[iconKey]?.description,
                    }
                    return (
                        <IconPopover val={iconObj} key={iconKey} approvedIconByName={approvedIconByName}
                            log={log} user={user}
                        >
                            <Box key={iconKey}
                            >
                                {iconsStatusData[iconKey].icon(Boolean(iconObj?.isMarked))}
                            </Box>
                        </IconPopover>
                    )
                })}
            </HStack>
        </VStack >
    )
}

export default ContactCard