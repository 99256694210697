import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra
} from '@chakra-ui/react'
import confetti from 'canvas-confetti'
import { add, formatDuration, intervalToDuration, isAfter } from 'date-fns'
import { addDoc, collection, doc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'
import { animate } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import sc from 'states-cities-db'
import { db, storage } from '../firebase/config'

// utils

const referralIds = [
  'hassan@codeshock.dev',
  'hussain@codeshock.dev',
  'arham@codeshock.dev',
  'zsendmea@codeshock.dev',
  'asendmea@codeshock.dev',
  'seth@codeshock.dev',
]

export const freeSendmeaPremium = [
  //  ...referralIds,
  //  'zac@codeshock.dev',
  //  'alexandra@codeshock.dev',
  //  'hassan09876khan@gmail.com',
  //  'muhammadhussain99100@gmail.com',
  //  'pace@flyingovertime.org',
]



export const teamEmailIds = [
  ...referralIds,
  'zac@codeshock.dev',
  'alexandra@codeshock.dev',
  'hassan09876khan@gmail.com',
  // 'muhammadhussain99100@gmail.com',
]

export const promoCode = 'promo_1OwTqiCV2kX2LuVw1Dc0Iwr9'

//'promo_1MU6vICV2kX2LuVwlQvziYZq' //! 35% off promo_code

export const countries = [
  {
    "label": "Afghanistan",
    "value": "AF",
    "code": "+93"
  },
  {
    "label": "Albania",
    "value": "AL",
    "code": "+355"
  },
  {
    "label": "Algeria",
    "value": "DZ",
    "code": "+213"
  },
  {
    "label": "American Samoa",
    "value": "AS",
    "code": "+1"
  },
  {
    "label": "Andorra",
    "value": "AD",
    "code": "+376"
  },
  {
    "label": "Angola",
    "value": "AO",
    "code": "+244"
  },
  {
    "label": "Anguilla",
    "value": "AI",
    "code": "+43"
  },
  {
    "label": "Antigua and Barbuda",
    "value": "AG",
    "code": "+1"
  },
  {
    "label": "Argentina",
    "value": "AR",
    "code": "+54"
  },
  {
    "label": "Armenia",
    "value": "AM",
    "code": "+374"
  },
  {
    "label": "Aruba",
    "value": "AW",
    "code": "+297"
  },
  {
    "label": "Australia",
    "value": "AU",
    "code": "+61"
  },
  {
    "label": "Azerbaijan",
    "value": "AZ",
    "code": "+994"
  },
  {
    "label": "Bahamas",
    "value": "BS",
    "code": "+1"
  },
  {
    "label": "Bahrain",
    "value": "BH",
    "code": "+973"
  },
  {
    "label": "Bangladesh",
    "value": "BD",
    "code": "+880"
  },
  {
    "label": "Barbados",
    "value": "BB",
    "code": "+1"
  },
  {
    "label": "Belarus",
    "value": "BY",
    "code": "+375"
  },
  {
    "label": "Belgium",
    "value": "BE",
    "code": "+32"
  },
  {
    "label": "Belize",
    "value": "BZ",
    "code": "+501"
  },
  {
    "label": "Benin",
    "value": "BJ",
    "code": "+229"
  },
  {
    "label": "Bermuda",
    "value": "BM",
    "code": "+1"
  },
  {
    "label": "Bhutan",
    "value": "BT",
    "code": "+975"
  },
  {
    "label": "Bolivia (Plurinational State of)",
    "value": "BO",
    "code": "+591"
  },
  {
    "label": "Bosnia and Herzegovina",
    "value": "BA",
    "code": "+387"
  },
  {
    "label": "Botswana",
    "value": "BW",
    "code": "+267"
  },
  {
    "label": "Brazil",
    "value": "BR",
    "code": "+55"
  },
  {
    "label": "British Indian Ocean Territory",
    "value": "IO",
    "code": "+246"
  },
  {
    "label": "Virgin Islands (British)",
    "value": "VG",
    "code": "+1"
  },
  {
    "label": "Virgin Islands (U.S.)",
    "value": "VI",
    "code": "+1"
  },
  {
    "label": "Brunei Darussalam",
    "value": "BN",
    "code": "+673"
  },
  {
    "label": "Bulgaria",
    "value": "BG",
    "code": "+359"
  },
  {
    "label": "Burkina Faso",
    "value": "BF",
    "code": "+226"
  },
  {
    "label": "Burundi",
    "value": "BI",
    "code": "+257"
  },
  {
    "label": "Cambodia",
    "value": "KH",
    "code": "+855"
  },
  {
    "label": "Cameroon",
    "value": "CM",
    "code": "+237"
  },
  {
    "label": "Canada",
    "value": "CA",
    "code": "+1"
  },
  {
    "label": "Cabo Verde",
    "value": "CV",
    "code": "+238"
  },
  {
    "label": "Cayman Islands",
    "value": "KY",
    "code": "+1"
  },
  {
    "label": "Central African Republic",
    "value": "CF",
    "code": "+236"
  },
  {
    "label": "Central African Republic",
    "value": "CF",
    "code": "+236"
  },
  {
    "label": "Chile",
    "value": "CL",
    "code": "+56"
  },
  {
    "label": "China",
    "value": "CN",
    "code": "+86"
  },
  {
    "label": "Colombia",
    "value": "CO",
    "code": "+57"
  },
  {
    "label": "Comoros",
    "value": "KM",
    "code": "+269"
  },
  {
    "label": "Congo",
    "value": "CG",
    "code": "+242"
  },
  {
    "label": "Congo (Democratic Republic of the)",
    "value": "CD",
    "code": "+243"
  },
  {
    "label": "Cook Islands",
    "value": "CK",
    "code": "+682"
  },
  {
    "label": "Costa Rica",
    "value": "CR",
    "code": "+506"
  },
  {
    "label": "Croatia",
    "value": "HR",
    "code": "+385"
  },
  {
    "label": "Cuba",
    "value": "CU",
    "code": "+53"
  },
  {
    "label": "Cuba",
    "value": "CU",
    "code": "+53"
  },
  {
    "label": "Cyprus",
    "value": "CY",
    "code": "+357"
  },
  {
    "label": "Czech Republic",
    "value": "CZ",
    "code": "+420"
  },
  {
    "label": "Denmark",
    "value": "DK",
    "code": "+45"
  },
  {
    "label": "Djibouti",
    "value": "DJ",
    "code": "+253"
  },
  {
    "label": "Dominica",
    "value": "DM",
    "code": "+1"
  },
  {
    "label": "Dominican Republic",
    "value": "DO",
    "code": "+1"
  },
  {
    "label": "Ecuador",
    "value": "EC",
    "code": "+593"
  },
  {
    "label": "Egypt",
    "value": "EG",
    "code": "+20"
  },
  {
    "label": "El Salvador",
    "value": "SV",
    "code": "+503"
  },
  {
    "label": "Equatorial Guinea",
    "value": "GQ",
    "code": "+240"
  },
  {
    "label": "Eritrea",
    "value": "ER",
    "code": "+291"
  },
  {
    "label": "Estonia",
    "value": "EE",
    "code": "+372"
  },
  {
    "label": "Ethiopia",
    "value": "ET",
    "code": "+251"
  },
  {
    "label": "Falkland Islands (Malvinas)",
    "value": "FK",
    "code": "+500"
  },
  {
    "label": "Faroe Islands",
    "value": "FO",
    "code": "+298"
  },
  {
    "label": "Fiji",
    "value": "FJ",
    "code": "+679"
  },
  {
    "label": "Finland",
    "value": "FI",
    "code": "+358"
  },
  {
    "label": "France",
    "value": "FR",
    "code": "+33"
  },
  {
    "label": "French Guiana",
    "value": "GF",
    "code": "+594"
  },
  {
    "label": "French Polynesia",
    "value": "PF",
    "code": "+689"
  },
  {
    "label": "Gabon",
    "value": "GA",
    "code": "+241"
  },
  {
    "label": "Gambia",
    "value": "GM",
    "code": "+220"
  },
  {
    "label": "Georgia",
    "value": "GE",
    "code": "+995"
  },
  {
    "label": "Germany",
    "value": "DE",
    "code": "+49"
  },
  {
    "label": "Ghana",
    "value": "GH",
    "code": "+233"
  },
  {
    "label": "Gibraltar",
    "value": "GI",
    "code": "+350"
  },
  {
    "label": "Greece",
    "value": "GR",
    "code": "+30"
  },
  {
    "label": "Greenland",
    "value": "GL",
    "code": "+299"
  },
  {
    "label": "Grenada",
    "value": "GD",
    "code": "+1"
  },
  {
    "label": "Guadeloupe",
    "value": "GP",
    "code": "+590"
  },
  {
    "label": "Guam",
    "value": "GU",
    "code": "+1"
  },
  {
    "label": "Guatemala",
    "value": "GT",
    "code": "+502"
  },
  {
    "label": "Guinea",
    "value": "GN",
    "code": "+224"
  },
  {
    "label": "Guinea-Bissau",
    "value": "GW",
    "code": "+245"
  },
  {
    "label": "Guyana",
    "value": "GY",
    "code": "+592"
  },
  {
    "label": "Haiti",
    "value": "HT",
    "code": "+509"
  },
  {
    "label": "Holy See",
    "value": "VA",
    "code": "+39"
  },
  {
    "label": "Honduras",
    "value": "HN",
    "code": "+504"
  },
  {
    "label": "Hong Kong",
    "value": "HK",
    "code": "+852"
  },
  {
    "label": "Hungary",
    "value": "HU",
    "code": "+36"
  },
  {
    "label": "Iceland",
    "value": "IS",
    "code": "+354"
  },
  {
    "label": "India",
    "value": "IN",
    "code": "+91"
  },
  {
    "label": "Indonesia",
    "value": "ID",
    "code": "+62"
  },
  {
    "label": "Côte d'Ivoire",
    "value": "CI",
    "code": "+225"
  },
  {
    "label": "Iran (Islamic Republic of)",
    "value": "IR",
    "code": "+98"
  },
  {
    "label": "Iraq",
    "value": "IQ",
    "code": "+964"
  },
  {
    "label": "Ireland",
    "value": "IE",
    "code": "+353"
  },
  {
    "label": "Israel",
    "value": "IL",
    "code": "+972"
  },
  {
    "label": "Italy",
    "value": "IT",
    "code": "+39"
  },
  {
    "label": "Jamaica",
    "value": "JM",
    "code": "+1"
  },
  {
    "label": "Japan",
    "value": "JP",
    "code": "+81"
  },
  {
    "label": "Jordan",
    "value": "JO",
    "code": "+962"
  },
  {
    "label": "Kazakhstan",
    "value": "KZ",
    "code": "+7"
  },
  {
    "label": "Kenya",
    "value": "KE",
    "code": "+254"
  },
  {
    "label": "Kiribati",
    "value": "KI",
    "code": "+686"
  },
  {
    "label": "Kuwait",
    "value": "KW",
    "code": "+965"
  },
  {
    "label": "Kyrgyzstan",
    "value": "KG",
    "code": "+996"
  },
  {
    "label": "Lao People's Democratic Republic",
    "value": "LA",
    "code": "+856"
  },
  {
    "label": "Latvia",
    "value": "LV",
    "code": "+371"
  },
  {
    "label": "Lebanon",
    "value": "LB",
    "code": "+961"
  },
  {
    "label": "Lesotho",
    "value": "LS",
    "code": "+266"
  },
  {
    "label": "Liberia",
    "value": "LR",
    "code": "+231"
  },
  {
    "label": "Libya",
    "value": "LY",
    "code": "+218"
  },
  {
    "label": "Liechtenstein",
    "value": "LI",
    "code": "+423"
  },
  {
    "label": "Lithuania",
    "value": "LT",
    "code": "+370"
  },
  {
    "label": "Luxembourg",
    "value": "LU",
    "code": "+352"
  },
  {
    "label": "Macao",
    "value": "MO",
    "code": "+853"
  },
  {
    "label": "Macedonia (the former Yugoslav Republic of)",
    "value": "MK",
    "code": "+389"
  },
  {
    "label": "Madagascar",
    "value": "MG",
    "code": "+261"
  },
  {
    "label": "Malawi",
    "value": "MW",
    "code": "+265"
  },
  {
    "label": "Malaysia",
    "value": "MY",
    "code": "+60"
  },
  {
    "label": "Maldives",
    "value": "MV",
    "code": "+960"
  },
  {
    "label": "Mali",
    "value": "ML",
    "code": "+223"
  },
  {
    "label": "Malta",
    "value": "MT",
    "code": "+356"
  },
  {
    "label": "Marshall Islands",
    "value": "MH",
    "code": "+692"
  },
  {
    "label": "Martinique",
    "value": "MQ",
    "code": "+596"
  },
  {
    "label": "Mauritania",
    "value": "MR",
    "code": "+222"
  },
  {
    "label": "Mauritius",
    "value": "MU",
    "code": "+230"
  },
  {
    "label": "Mayotte",
    "value": "YT",
    "code": "+262"
  },
  {
    "label": "Mexico",
    "value": "MX",
    "code": "+52"
  },
  {
    "label": "Micronesia (Federated States of)",
    "value": "FM",
    "code": "+691"
  },
  {
    "label": "Moldova (Republic of)",
    "value": "MD",
    "code": "+373"
  },
  {
    "label": "Monaco",
    "value": "MC",
    "code": "+377"
  },
  {
    "label": "Mongolia",
    "value": "MN",
    "code": "+976"
  },
  {
    "label": "Montenegro",
    "value": "ME",
    "code": "+382"
  },
  {
    "label": "Montserrat",
    "value": "MS",
    "code": "+1"
  },
  {
    "label": "Morocco",
    "value": "MA",
    "code": "+212"
  },
  {
    "label": "Mozambique",
    "value": "MZ",
    "code": "+258"
  },
  {
    "label": "Myanmar",
    "value": "MM",
    "code": "+95"
  },
  {
    "label": "Namibia",
    "value": "NA",
    "code": "+264"
  },
  {
    "label": "Nauru",
    "value": "NR",
    "code": "+674"
  },
  {
    "label": "Nepal",
    "value": "NP",
    "code": "+977"
  },
  {
    "label": "Netherlands",
    "value": "NL",
    "code": "+31"
  },
  {
    "label": "New Caledonia",
    "value": "NC",
    "code": "+687"
  },
  {
    "label": "New Zealand",
    "value": "NZ",
    "code": "+64"
  },
  {
    "label": "Nicaragua",
    "value": "NI",
    "code": "+505"
  },
  {
    "label": "Niger",
    "value": "NE",
    "code": "+227"
  },
  {
    "label": "Nigeria",
    "value": "NG",
    "code": "+234"
  },
  {
    "label": "Niue",
    "value": "NU",
    "code": "+683"
  },
  {
    "label": "Norfolk Island",
    "value": "NF",
    "code": "+672"
  },
  {
    "label": "Korea (Democratic People's Republic of)",
    "value": "KP",
    "code": "+850"
  },
  {
    "label": "Northern Mariana Islands",
    "value": "MP",
    "code": "+1"
  },
  {
    "label": "Norway",
    "value": "NO",
    "code": "+47"
  },
  {
    "label": "Oman",
    "value": "OM",
    "code": "+968"
  },
  {
    "label": "Pakistan",
    "value": "PK",
    "code": "+92"
  },
  {
    "label": "Palau",
    "value": "PW",
    "code": "+680"
  },
  {
    "label": "Palestine, State of",
    "value": "PS",
    "code": "+970"
  },
  {
    "label": "Panama",
    "value": "PA",
    "code": "+507"
  },
  {
    "label": "Papua New Guinea",
    "value": "PG",
    "code": "+675"
  },
  {
    "label": "Paraguay",
    "value": "PY",
    "code": "+595"
  },
  {
    "label": "Peru",
    "value": "PE",
    "code": "+51"
  },
  {
    "label": "Philippines",
    "value": "PH",
    "code": "+63"
  },
  {
    "label": "Poland",
    "value": "PL",
    "code": "+48"
  },
  {
    "label": "Portugal",
    "value": "PT",
    "code": "+351"
  },
  {
    "label": "Puerto Rico",
    "value": "PR",
    "code": "+1"
  },
  {
    "label": "Qatar",
    "value": "QA",
    "code": "+974"
  },
  {
    "label": "Republic of Kosovo",
    "value": "XK",
    "code": "+381"
  },
  {
    "label": "Réunion",
    "value": "RE",
    "code": "+262"
  },
  {
    "label": "Romania",
    "value": "RO",
    "code": "+40"
  },
  {
    "label": "Russian Federation",
    "value": "RU",
    "code": "+7"
  },
  {
    "label": "Rwanda",
    "value": "RW",
    "code": "+250"
  },
  {
    "label": "Saint Barthélemy",
    "value": "BL",
    "code": "+590"
  },
  {
    "label": "Saint Helena, Ascension and Tristan da Cunha",
    "value": "SH",
    "code": "+290"
  },
  {
    "label": "Saint Kitts and Nevis",
    "value": "KN",
    "code": "+1"
  },
  {
    "label": "Saint Lucia",
    "value": "LC",
    "code": "+1"
  },
  {
    "label": "Saint Martin (French part)",
    "value": "MF",
    "code": "+590"
  },
  {
    "label": "Saint Pierre and Miquelon",
    "value": "PM",
    "code": "+508"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "VC",
    "code": "+1"
  },
  {
    "label": "Samoa",
    "value": "WS",
    "code": "+685"
  },
  {
    "label": "San Marino",
    "value": "SM",
    "code": "+378"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "ST",
    "code": "+239"
  },
  {
    "label": "Saudi Arabia",
    "value": "SA",
    "code": "+966"
  },
  {
    "label": "Senegal",
    "value": "SN",
    "code": "+221"
  },
  {
    "label": "Serbia",
    "value": "RS",
    "code": "+381"
  },
  {
    "label": "Seychelles",
    "value": "SC",
    "code": "+248"
  },
  {
    "label": "Sierra Leone",
    "value": "SL",
    "code": "+232"
  },
  {
    "label": "Singapore",
    "value": "SG",
    "code": "+65"
  },
  {
    "label": "Singapore",
    "value": "SG",
    "code": "+65"
  },
  {
    "label": "Slovakia",
    "value": "SK",
    "code": "+421"
  },
  {
    "label": "Slovenia",
    "value": "SI",
    "code": "+386"
  },
  {
    "label": "Solomon Islands",
    "value": "SB",
    "code": "+677"
  },
  {
    "label": "Somalia",
    "value": "SO",
    "code": "+252"
  },
  {
    "label": "South Africa",
    "value": "ZA",
    "code": "+27"
  },
  {
    "label": "Korea (Republic of)",
    "value": "KR",
    "code": "+82"
  },
  {
    "label": "Spain",
    "value": "ES",
    "code": "+34"
  },
  {
    "label": "Sri Lanka",
    "value": "LK",
    "code": "+94"
  },
  {
    "label": "Sudan",
    "value": "SD",
    "code": "+249"
  },
  {
    "label": "Suriname",
    "value": "SR",
    "code": "+597"
  },
  {
    "label": "Swaziland",
    "value": "SZ",
    "code": "+268"
  },
  {
    "label": "Sweden",
    "value": "SE",
    "code": "+46"
  },
  {
    "label": "Switzerland",
    "value": "CH",
    "code": "+41"
  },
  {
    "label": "Syrian Arab Republic",
    "value": "SY",
    "code": "+963"
  },
  {
    "label": "Taiwan",
    "value": "TW",
    "code": "+886"
  },
  {
    "label": "Tajikistan",
    "value": "TJ",
    "code": "+992"
  },
  {
    "label": "Tanzania, United Republic of",
    "value": "TZ",
    "code": "+255"
  },
  {
    "label": "Thailand",
    "value": "TH",
    "code": "+66"
  },
  {
    "label": "Timor-Leste",
    "value": "TL",
    "code": "+670"
  },
  {
    "label": "Togo",
    "value": "TG",
    "code": "+228"
  },
  {
    "label": "Tokelau",
    "value": "TK",
    "code": "+690"
  },
  {
    "label": "Tonga",
    "value": "TO",
    "code": "+676"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "TT",
    "code": "+1"
  },
  {
    "label": "Tunisia",
    "value": "TN",
    "code": "+216"
  },
  {
    "label": "Turkey",
    "value": "TR",
    "code": "+90"
  },
  {
    "label": "Turkmenistan",
    "value": "TM",
    "code": "+993"
  },
  {
    "label": "Turks and Caicos Islands",
    "value": "TC",
    "code": "+1"
  },
  {
    "label": "Tuvalu",
    "value": "TV",
    "code": "+688"
  },
  {
    "label": "Uganda",
    "value": "UG",
    "code": "+256"
  },
  {
    "label": "Ukraine",
    "value": "UA",
    "code": "+380"
  },
  {
    "label": "United Arab Emirates",
    "value": "AE",
    "code": "+971"
  },
  {
    "label": "United Kingdom of Great Britain and Northern Ireland",
    "value": "GB",
    "code": "+44"
  },
  {
    "label": "United States",
    "value": "US",
    "code": "+1"
  },
  {
    "label": "Uruguay",
    "value": "UY",
    "code": "+598"
  },
  {
    "label": "Uzbekistan",
    "value": "UZ",
    "code": "+998"
  },
  {
    "label": "Vanuatu",
    "value": "VU",
    "code": "+678"
  },
  {
    "label": "Venezuela (Bolivarian Republic of)",
    "value": "VE",
    "code": "+58"
  },
  {
    "label": "Viet Nam",
    "value": "VN",
    "code": "+84"
  },
  {
    "label": "Wallis and Futuna",
    "value": "WF",
    "code": "+681"
  },
  {
    "label": "Wallis and Futuna",
    "value": "WF",
    "code": "+681"
  },
  {
    "label": "Yemen",
    "value": "YE",
    "code": "+967"
  },
  {
    "label": "Zambia",
    "value": "ZM",
    "code": "+260"
  },
  {
    "label": "Zimbabwe",
    "value": "ZW",
    "code": "+263"
  }
]

export const secToMin = (duration) => {
  let durationInt = parseInt(duration)
  return (durationInt - (durationInt %= 60)) / 60 + (9 < durationInt ? ':' : ':0') + durationInt
}
export const convertToMb = (fileObj) => (fileObj.size / (1024 * 1024)).toFixed(2)

export const add3Dots = (string, limit) => {
  var dots = '...'
  if (string.length > limit) {
    string = string.substring(0, limit) + dots
  }

  return string
}

export const makeTwoDigit = (num) => {
  return num.toString().padStart(2, '0');
}

export const convertToDashed = (string) => String(string).toLowerCase().trim().replace(/ /g, "-");
export const convertDashedToString = (string) => String(string || "").split("-").join(" ").toLowerCase();

export const capitalizeSentence = (sentence) => {
  const conjunctions = ['and', 'or', 'but', 'nor', 'so', 'yet'];
  const words = sentence.split(' ');

  return words.map((word) => {
    return conjunctions.includes(word) ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  }).join(' ');
};

export const getRandomNumbers = (totalNo, rangeStart, rangeEnd) => {
  let getRandom_ = (rangeStart, rangeEnd) => {
    return Math.floor(Math.random() * (rangeEnd - rangeStart + 1)) + rangeStart
  }
  let getRealValue_ = (map, rnd) => {
    if (map.has(rnd)) {
      return getRealValue_(map, map.get(rnd))
    } else {
      return rnd
    }
  }

  const out = new Map()
  while (totalNo--) {
    const rnd = getRandom_(rangeStart, rangeEnd--)
    out.set(getRealValue_(out, rnd), rangeEnd + 1)
  }
  return [...out.keys()]
}

export const getNameInitials = (str = '') => {
  const [firstname, lastname] = str.toUpperCase().split(' ')
  const initials = firstname.substring(0, 1)
  return lastname
    ? initials.concat(lastname.substring(0, 1))
    : initials.concat(firstname.substring(1, 2))
}

export function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v))
}

export const handleOpenLink = (url) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "http://" + url;
  }
  window.open(url, "_blank");
}

export const getTotalCampaigns = new Promise(async (resolve, reject) => {
  try {
    const collRef = collection(db, 'campaigns')
    const size = (await getDocs(collRef)).size
    resolve(size)
  } catch (error) {
    reject(error.message)
  }
})

export const getReferralByEmails = async (emailIds = []) => {
  if (emailIds.length === 0) return false

  let myHeaders = new Headers()
  myHeaders.append('x-api-key', process.env.REACT_APP_FIRSTPROMOTORER_API_KEY)
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }
  try {
    const getReferrals = await Promise.all(
      emailIds.map(async (email) => {
        const showRes = await fetch(
          `https://firstpromoter.com/api/v1/promoters/show?promoter_email=${email}`,
          requestOptions
        )
        const showResult = await showRes.text()
        const res = JSON.parse(showResult)
        if (res?.error) return null
        return { email, ref_id: res?.promotions[0]?.ref_id }
      })
    )
    return getReferrals
  } catch (error) {
    console.log('error', error)
  }
}

export const fetchReferral = ({ ref_id, email, cust_id }) => {
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append('x-api-key', process.env.REACT_APP_FIRSTPROMOTORER_API_KEY);
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    let urlencoded = new URLSearchParams();
    if (ref_id) {
      urlencoded.append('ref_id', ref_id.toLowerCase());
    }
    if (cust_id) {
      urlencoded.append('cust_id', cust_id);
    }
    if (email) {
      urlencoded.append('promoter_email', email.toLowerCase());
    }

    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch('https://firstpromoter.com/api/v1/promoters/show?' + urlencoded, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        if (!res?.error) {
          resolve(res);
        } else {
          if (res?.error === 'Promoter not found.') {
            resolve({ isEnrolled: false, error: res?.error });
          } else {
            reject(res?.error)
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.onload = () => {
        const media = new Audio(reader.result)
        media.onloadedmetadata = () => resolve(media.duration)
      }
      reader.readAsDataURL(file)
      reader.onerror = (error) => reject(error)
    } catch (err) {
      reject(err)
    }
  })

export const errorToast = (error, toast, options) => {
  toast({
    title: 'Error!',
    description: error.message,
    status: 'error',
    variant: 'subtle',
    duration: 3000,
    position: 'top-right',
    isClosable: true,
    ...options,
  })
}

export const updateStatus = async (
  collectName,
  id,
  payload,
  toast,
  toastMsg = {
    title: 'Status!',
    description: `Successful`,
  }
) => {
  try {
    const docRef = doc(db, collectName, id)
    await updateDoc(docRef, { ...payload })
    toast({
      ...toastMsg,
      status: 'success',
    })
  } catch (err) {
    errorToast(err, toast)
  }
}

export const fileExistsInStorage = (filePath) =>
  new Promise((resolve, reject) => {
    const storageRef = ref(storage, filePath)
    getDownloadURL(storageRef)
      .then(() => {
        return resolve(true)
      })
      .catch((error) => {
        if (error.code === 'storage/object-not-found') {
          return resolve(false)
        } else {
          return reject(error)
        }
      })
  })

export const DeletePrompt = ({ isOpen, onClose, accept, loading = false, title, text }) => {

  let titleText = title || 'Caution'
  let bodyText = text || 'Can not be recovered. Will be deleted permanently.'

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{titleText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{bodyText}</ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Decline
          </Button>
          <Button
            variant='solid'
            bg='red.500'
            _hover={{ bg: 'red.300' }}
            onClick={accept}
            isLoading={loading}
          >
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export const ConfirmationPrompt = ({ isOpen, onClose, accept, loading = false, message, ...rest }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='inside'
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Caution!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='solid'
            bg='red.500'
            _hover={{ bg: 'red.300' }}
            onClick={accept}
            isLoading={loading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const sendNotification = async (payload) => {
  try {
    const payloadNoti = {
      ...payload,
      seen: false,
      createdAt: serverTimestamp(),
    }
    const notiRef = collection(db, 'notifications')
    const id = await addDoc(notiRef, payloadNoti)
    return id
  } catch (error) {
    console.log(error)
  }
}

export const firstPromoteApiHeader = {
  'Content-Type': 'application/json',
  'x-api-key': process.env.REACT_APP_FIRSTPROMOTORER_API_KEY,
}

export const firstPromoterApi = async (api, payload) => {
  const json = await fetch(api, {
    method: 'POST',
    headers: firstPromoteApiHeader,
    body: JSON.stringify(payload),
  })
  const firstpromoterSignup = json.json()
  return firstpromoterSignup
}

const COUNTRIES = sc.getCountries()

const getCountryTelCode = (country) =>
  country && COUNTRIES.find(({ iso }) => iso === country).prefix

export { COUNTRIES, getCountryTelCode }

export const getCountry = (countryCallingCode) => {
  const countries = COUNTRIES.filter((country) => country.prefix === countryCallingCode)
  const countryIsoCode = countries?.length > 1 ? countries?.[1]?.iso : countries?.[0]?.iso
  return countryIsoCode
}

export const readCookie = (cookieName) => {
  let theCookie = ' ' + document.cookie
  let ind = theCookie.indexOf(' ' + cookieName + '=')
  if (ind === -1) ind = theCookie.indexOf(';' + cookieName + '=')
  if (ind === -1 || cookieName === '') return ''
  let ind1 = theCookie.indexOf(';', ind + 1)
  if (ind1 === -1) ind1 = theCookie.length
  return unescape(theCookie.substring(ind + cookieName.length + 2, ind1))
}

export const setCookie = (cookieName, cookieValue) => {
  let today = new Date()
  let expire = new Date()
  expire.setTime(today.getTime() + 3600000 * 24 * 14)
  document.cookie = cookieName + '=' + encodeURI(cookieValue) + ';expires=' + expire.toGMTString()
}

export const deleteCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}


export const discoutConstant = 50

export const getRandomReferral = async (setValue = () => { }) => {
  const referrals = await getReferralByEmails(referralIds)
  const randomNo = getRandomNumbers(1, 1, referrals.filter((v) => !!v)?.length)
  setValue('referral', referrals.filter((v) => !!v)[randomNo[0] - 1]?.ref_id)
  setCookie('referral', referrals.filter((v) => !!v)[randomNo[0] - 1]?.ref_id)
  return referrals.filter((v) => !!v)[randomNo[0] - 1]?.ref_id
}

export const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = function () {
      const dataUrl = reader.result
      resolve(dataUrl)
    }
  })

export const isChrome = () => {
  let userAgent = navigator.userAgent
  if (userAgent.match(/chrome|chromium|crios/i) && !userAgent.match(/(iPod|iPhone|iPad)/)) {
    return true
  }
  return false
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export const cloudfunctionsBaseURL = 'https://us-central1-sendmea-c7d45.cloudfunctions.net/'

export const fireCanvaParticles = () => {
  var count = 300
  var defaults = {
    origin: { y: 0.7 },
  }

  function fire(particleRatio, opts) {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    )
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  })
  fire(0.2, {
    spread: 60,
  })
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  })
}

export const Counter = ({ from, to, toFixed, duration }) => {
  const nodeRef = useRef()

  useEffect(() => {
    const node = nodeRef.current

    const controls = animate(from, to, {
      duration,
      onUpdate(value) {
        node.textContent = makeTwoDigit(value.toFixed(toFixed))
      },
    })

    return () => controls.stop()
  }, [from, to])

  return <chakra.span fontSize={['24px', '32px']} fontWeight='500' ref={nodeRef} />
}
export const uniqueArrayOfObjects = (array, duplicateKey) => {
  return array.reduce((unique, o) => {
    if (!unique.some((obj) => obj[duplicateKey] === o[duplicateKey])) {
      unique.push(o)
    }
    return unique
  }, [])
}

export const zacSmith = 'zsendmea@codeshock.dev' //'TARmgF2iocYjKHEpFi77hJVD7JL2'

export const handleVideoConvert = async (url, path) => {
  const convertVideoUrl = `${cloudfunctionsBaseURL}handleVideoConvert`
  await fetch(convertVideoUrl, {
    method: "POST",
    body: JSON.stringify({ video: url, storagePath: path })
  })
}

export const handleDeleteCloudinaryMedia = async ({ filename, folder, resource_type }) => {
  new Promise(async (resolve, reject) => {
    const deleteMediaUrl = `${cloudfunctionsBaseURL}deleteMediaCloudinary`

    await fetch(deleteMediaUrl, {
      method: "POST",
      body: JSON.stringify({ filename, folder, resource_type })
    }).then(() => {
      resolve(true)
    }).catch(err => {
      reject(err)
    })

  })
}

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const getWeekNumber = (date) => {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}

export const isDatePassedDuration = (firebaseDate, { days = 1, months = 0, years = 0 }) => {
  let updatedDate = new Date(firebaseDate.seconds * 1000 + firebaseDate.nanoseconds / 100000)
  const currentDate = new Date()
  const dateAfter3Months = add(updatedDate, { days, months, years })
  const isDateCrossed = isAfter(currentDate, dateAfter3Months)
  const durationPassed = intervalToDuration({
    start: currentDate,
    end: dateAfter3Months,
  })
  const durationLeft = formatDuration(
    {
      ...durationPassed,
    },
    { format: ['months', 'days'] }
  )
  return { isDateCrossed, durationPassed, durationLeft }
}

export const mergeByProperty = (target, source, prop) => {
  source.forEach(sourceElement => {
    let targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop];
    })
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
  })
}

export function fnBrowserDetect() {

  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No Browser";
  }

  return browserName;
}

export function extractNameParts(fullname) {
  const names = String(fullname).trim()?.split(" ");
  const firstName = names?.[0];

  const lastNameFirstLetter = names?.[names?.length - 1][0];

  let middleName = "";
  if (names?.length > 2) {
    middleName = names?.slice(1, -1)?.join(" ");
  }
  return `${firstName} ${middleName} ${lastNameFirstLetter}`;
}

export const standardBrowserList = ['Chrome', 'Safari', 'Firefox', 'Opera', 'Brave']
export const suggestedBrowserList = ['Chrome', 'Safari', 'Brave']

export const registerCodeshockContact = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      // const url = 'http://127.0.0.1:5001/sendmea-c7d45/us-central1/codeshockCreateContact'
      const url = cloudfunctionsBaseURL + 'codeshockCreateContact'
      const options = {
        method: 'POST',
        body: JSON.stringify(payload),
      }

      const resp = await fetch(url, options)
      resolve(resp)
    } catch (err) {
      reject(err)
    }
  })