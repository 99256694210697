import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Text, chakra, SimpleGrid, Button, Stack, VStack, Spinner } from '@chakra-ui/react'
import React, { startTransition, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TestimonialCard from '../../../components/TestimonialCard'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { getRandomNumbers } from '../../../utils'

function Campaigns() {
  const [randomCampaigns, setRandomCampaigns] = useState(null)
  const [dataLoading, setDataLoading] = useState(true)

  const getRandomCampaigns = async () => {
    setDataLoading(true)
    try {
      const collRef = collection(db, 'campaigns')
      const q = query(collRef, where('active', '==', true))
      const totalCount = (await getDocs(q)).size
      const random3No = getRandomNumbers(3, 1, totalCount)
      const queryRef = query(collRef, where('docNo', 'in', random3No))
      let randomData = await getDocs(queryRef)
      randomData = await Promise.all(
        randomData.docs.map(async (campaignSnap) => {
          const queryTestimonial = query(
            collection(db, 'reviews'),
            where('campaignId', '==', campaignSnap.id),
            where('approve', '==', true)
          )
          const getReviews = await getDocs(queryTestimonial)
          let avgRating = getReviews.docs
            .map((review) => review.data()?.rating)
            .filter((rating) => !!rating)
          avgRating =
            avgRating.reduce((sum, rating) => sum + Number(rating), 0) / avgRating.length || 0

          let campaignData = {
            ...campaignSnap.data(),
            id: campaignSnap.id,
            avgRating: avgRating || 0,
            totalReviews: getReviews.size || 0,
          }

          const customUrlRef = collection(db, 'campaigns', campaignSnap.id, 'vanity')
          let customUrlData = await getDocs(customUrlRef)
          if (!customUrlData.empty) {
            let customUrlName = String(customUrlData.docs[0]?.data()?.vanityName || '')
              .toLowerCase()
              .trim()
            campaignData = { ...campaignData, customUrlName }
          }

          return campaignData
        })
      )
      startTransition(() => {
        setRandomCampaigns(randomData || [])
      })
      setDataLoading(false)
    } catch (error) {
      console.log(error.message)
      setDataLoading(false)
    }
  }

  useEffect(() => {
    getRandomCampaigns()

  }, [])

  return (
    <chakra.div
      w='full'
      pt={[4, 8]}
      pb={[2, 4]}
    >
      <Text
        fontSize={['1.8rem', '2rem']}
        fontWeight={'600'}
        mb={['5', '8']}
        textAlign={'center'}
      >
        <chakra.span color={'brand.1'}>User's Review </chakra.span>
        <chakra.span color={'brand.2'} ml={0.5}>
          Walls
        </chakra.span>
      </Text>
      <Stack direction={'row'} justifyContent={'right'} px={['2.5', '16']}>
        <Button
          size='xs'
          variant={'outline'}
          fontSize={'10px'}
          fontWeight='600'
          _hover={{ cursor: 'pointer', backgroundColor: 'gray.100' }}
          onClick={getRandomCampaigns}
          textAlign='right'
        >
          Random Review Walls
        </Button>
      </Stack>
      {dataLoading ? (
        <VStack justifyContent='center' alignItems='center' width='full' minH='300px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='teal.500'
            size={['md', 'xl']}
          />
        </VStack>
      ) : (
        randomCampaigns && (
          <SimpleGrid
            spacing={['10px', '20px', '30px']}
            minChildWidth={['220px', '270px']}
            mt={5}
            mx={'auto'}
            px={['2.5', '16']}
            className='campaigns'
          >
            {randomCampaigns.map((cardInfo) => (
              <TestimonialCard key={cardInfo.id} cardInfo={cardInfo} />
            ))}
          </SimpleGrid>
        )
      )}
      <chakra.div mt={'10'} mb={'16'} textAlign={'center'}>
        <Button w={'36'} as={Link} to={'/register'} variant={'solid'}>
          Sign up <ArrowForwardIcon w={'10'} />
        </Button>
      </chakra.div>
    </chakra.div>
  )
}

export default Campaigns
