import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormLabel,
    GridItem,
    Heading,
    HStack,
    Input,
    InputGroup,
    Stack,
    Text
} from '@chakra-ui/react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { db } from '../../../../firebase/config'
import { useCustomToast } from '../../../../hooks/customToast'

const SEOForm = ({ selectedCampaign, tabIndex }) => {

    const { addToast } = useCustomToast()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [campaign, setCampaign] = useState(selectedCampaign)
    const [isEditable, setIsEditable] = useState(false)


    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            seo: [{ keyword: '' }],
        },
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'seo',
    })

    const onSubmit = async (state) => {
        let { ...values } = state
        try {
            setIsSubmitting(true)
            if (campaign) {
                const docRef = doc(db, 'campaigns', campaign.id)
                await updateDoc(docRef, {
                    seo: values,
                })
                addToast({
                    id: 'seo-success',
                    title: 'seo !',
                    description: 'Updated successfully',
                    status: 'success',
                    variant: 'left-accent',
                })
                setIsEditable(false)
            } else {
                addToast({
                    id: 'seo-error',
                    title: 'Error',
                    description: 'seo',
                    status: 'warning',
                    variant: 'left-accent',
                })
            }
            setIsSubmitting(false)
        } catch (error) {
            addToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                variant: 'left-accent',
            })
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                if (selectedCampaign) {
                    const docRef = doc(db, 'campaigns', selectedCampaign?.id)
                    const getCampaign = await getDoc(docRef)
                    setCampaign({ ...getCampaign.data(), id: getCampaign.id })
                    if (getCampaign?.data()) {
                        reset({ ...campaign?.seo })
                    }
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
        })()
    }, [selectedCampaign])

    useEffect(() => {

        reset({ ...campaign?.seo })

    }, [campaign])

    return (
        <Stack
            mt={4}
            py={{ base: 4, sm: 6, md: 8 }}
            px={{ base: 2, sm: 4, md: 8 }}
            width='full'
            spacing={8}
            bg="white"
            shadow="md"
            rounded="lg"
        >
            <HStack justifyContent='space-between' w='full'>
                <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'md', sm: 'lg' }}>
                    {campaign?.campaignDetails.publicCampaignName} SEO
                </Heading>
            </HStack>

            {loading ?
                <HStack alignItems={'center'} mt={2}>
                    <Text>Loading...</Text>
                </HStack> :
                <Box
                    as={'form'}
                    mt={10}
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        'input, select, textarea': {
                            color: 'gray.700',
                        },
                    }}
                >
                    {fields.map((field, index) => (
                        <FormControl isInvalid={!!errors?.seo?.[index]?.keyword} key={field.id} p='6'>
                            <HStack alignItems='end'>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                    {index + 1}.
                                </FormLabel>
                                <InputGroup>
                                    <Input
                                        name='keyword'
                                        placeholder={'Type your SEO keyword here, max 48 characters'}
                                        bg={'gray.100'}
                                        border={0}
                                        color={'gray.500'}
                                        _placeholder={{
                                            color: 'gray.500',
                                        }}
                                        disabled={!isEditable}
                                        {...register(`seo.${index}.keyword`, {
                                            required: 'This field is required',
                                            maxLength: {
                                                value: 60,
                                                message: 'too many characters',
                                            },
                                            minLength: {
                                                value: 2,
                                                message: 'cannot be blank',
                                            },
                                        })}
                                    />
                                </InputGroup>

                                {fields.length !== 1 && (
                                    <Button disabled={!isEditable} onClick={() => remove(index)}>
                                        Remove
                                    </Button>
                                )}
                                {fields.length - 1 === index && (
                                    <Button disabled={!isEditable} onClick={() => append({ keyword: '' })}>
                                        New
                                    </Button>
                                )}
                            </HStack>
                            {errors.seo?.[index]?.keyword.message && (
                                <FormErrorMessage>{errors.seo?.[index]?.keyword?.message}</FormErrorMessage>
                            )}
                        </FormControl>
                    ))}

                    <GridItem colSpan={[12]} order={14} alignItems={'center'} display={'flex'} justifyContent={'right'} gap={2}>
                        {!isEditable && (
                            <Box
                                type='button'
                                as='button'
                                name='edit'
                                mt={3}
                                w={'20%'}
                                borderRadius='8'
                                py='2'
                                bg='brand.1'
                                variant='solid'
                                color='white'
                                fontWeight={'bold'}
                                onClick={() => setIsEditable(true)}
                            >
                                Edit
                            </Box>
                        )}
                        {!campaign?.seo ? (
                            <Button
                                type='submit'
                                disabled={isSubmitting}
                                mt={3}
                                leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
                                variant='solid'
                            >
                                Save
                            </Button>
                        ) : (
                            <Button
                                type='submit'
                                disabled={isSubmitting}
                                mt={3}
                                leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
                                variant='solid'
                            >
                                Update
                            </Button>
                        )}
                    </GridItem>
                </Box>
            }
        </Stack>
    )
}

export default SEOForm
