import React from 'react'
import {
    Flex,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    chakra,
    Box,
    useBreakpointValue,
    Hide,
    Skeleton,
    SkeletonText,
    Button
} from '@chakra-ui/react'
import { ProfileModal } from '../ProfileModal/ProfileModal'
import Rating from '../../../../components/Rating'
import { ContactModal } from '../ContactModal/ContactModal'
import { handleOpenLink } from '../../../../utils'


const LivelyTheme = ({ avgRating, loading, campaignData, disableIcon = false }) => {

    const { campaignDetails } = campaignData

    return (
        <Stack minH={'20vh'} px={['3', '6']} direction={{ base: 'column', md: 'row' }} justify='space-between'>
            <Flex align={'center'} justify={'center'}>
                <Stack spacing={4} w={'full'} >

                    <Stack py='2' align={'space-between'} direction={{ base: 'column', md: 'row' }}>
                        {campaignDetails?.campaignLogo ? (
                            <Box pr='4'>
                                <Image
                                    src={campaignDetails?.campaignLogo}
                                    alt=''
                                    maxW={'180px'}
                                    maxH='100px'
                                    objectFit='contain'
                                    rounded="md"
                                />
                            </Box>
                        ) : (
                            null
                        )}

                        <Skeleton startColor='brand.1' endColor='gray.300' fadeDuration={1} isLoaded={!loading}>
                            <Heading fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}>
                                <Text
                                    fontSize='4xl'
                                    as={'span'}
                                    position={'relative'}
                                    _after={{
                                        content: "''",
                                        width: 'full',
                                        height: useBreakpointValue({ base: '15%', md: '20%' }),
                                        position: 'absolute',
                                        bottom: 1,
                                        left: 0,
                                        bg: 'brand.2',
                                        zIndex: -1,
                                    }}>
                                    {campaignDetails?.publicCampaignName ||
                                        campaignDetails?.testimonialCampaignName ||
                                        '-'}
                                </Text>
                                <br />{' '}
                                <HStack pt='4' direction='row' align='center' spacing={'10px'}>
                                    <Rating
                                        total={5}
                                        value={Math.round(avgRating)}
                                        size={['19px']}
                                        isStatic={true}
                                        showNumber={false}
                                    />
                                    <Text fontSize='16px' color='brand.1' pb={1}>
                                        {!!campaignData?.reviews?.length ? campaignData?.totalTestimonials : 0} Review{(campaignData?.totalTestimonials === 0 || campaignData?.totalTestimonials > 1) && 's'}
                                    </Text>
                                </HStack>
                                <Text fontSize='16px' color='brand.2'>
                                    <chakra.span color='brand.2' fontWeight='medium'>
                                        Average:
                                    </chakra.span>{' '}
                                    {Number(avgRating).toFixed(
                                        avgRating ? 2 : 0
                                    )}{' '}
                                    out of 5 Stars
                                </Text>
                            </Heading>
                        </Skeleton>
                    </Stack>
                    <SkeletonText startColor='brand.1' endColor='gray.300' fadeDuration={1} isLoaded={!loading}>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>

                            {campaignDetails?.campaignDescription || '-'}

                        </Text>
                    </SkeletonText>
                    <Skeleton startColor='brand.1' endColor='gray.300' fadeDuration={1} isLoaded={!loading}>
                        <HStack flexWrap="wrap" spacing={0} gap={[2]}>
                            {!campaignData?.isExpressSetup &&
                                <ProfileModal campaignData={campaignData} disableIcon={disableIcon} />
                            }
                            <ContactModal campaignEmail={campaignData?.campaignDetails?.email} disableIcon={disableIcon} />
                            {!campaignData?.isExpressSetup &&
                                <Text
                                    pl='2'
                                    fontSize='16px'
                                    color='teal.400'
                                    fontWeight='semibold'
                                    cursor={'pointer'}
                                    onClick={() => !disableIcon && handleOpenLink(campaignDetails?.reviewWallUrl || '#')}
                                >
                                    {campaignDetails?.reviewWallUrl}
                                </Text>
                            }
                        </HStack>
                        <Button
                            colorScheme='teal' color='#78bdc0' mt='10' size='md'
                            onClick={() => !disableIcon && handleOpenLink(`${window.location.origin}/review/${campaignData?.campaignId}` || '#')}
                        >
                            Leave  {campaignDetails?.publicCampaignName || campaignDetails?.testimonialCampaignName || '-'} a Review!
                        </Button>
                    </Skeleton>

                </Stack>

            </Flex>

            <Flex justify={'flex-end'} >

                <Hide below='lg'>
                    <Box w='50%' >
                        <Image
                            zIndex={2}
                            alt={'Login Image'}
                            objectFit={'cover'}
                            src={
                                'https://res.cloudinary.com/codeshock/image/upload/v1675817432/Untitled_design_2_ielr3m.png'
                            }

                        />
                    </Box>
                </Hide>

            </Flex>
        </Stack>
    )
}

export default LivelyTheme