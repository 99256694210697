import { Suspense } from 'react'
import NewHero from './components/Hero'
import Benefits from './components/Benefits'
import GetStarted from './components/GetStarted'
import StartWith from './components/StartWith'
import Sales from './components/Sales'
import Campaigns from './components/Campaigns'
import Pricing from './components/Pricing'
import Awareness from './components/Awareness'
import { Box, useBreakpointValue } from '@chakra-ui/react'

import LazyLoad from 'react-lazyload'

const Landing = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
  })

  return (
    <Suspense>
      <Box id='landingPage' maxW={'7xl'} m={'auto'}>
        <Box id='getting-started' minH='100px' py={2}>
          <LazyLoad height={200}>
            <NewHero />
          </LazyLoad>
        </Box>

        <Box id='benefits' minH='100px' py={2}>
          <LazyLoad height={200}>
            <Benefits />
          </LazyLoad>
        </Box>

        <LazyLoad height={200}>
          <Sales />
        </LazyLoad>

        <Box id='pricing' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <Pricing />
          </LazyLoad>
        </Box>

        <Box id='startWith' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <StartWith />
          </LazyLoad>
        </Box>

        <Box id='awareness' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <Awareness />
          </LazyLoad>
        </Box>

        {!isMobile && <Box id='getStarted' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <GetStarted />
          </LazyLoad>
        </Box>}

        {!isMobile && <Box id='campaigns' minH='100px' pt={0}>
          <LazyLoad height={200}>
            <Campaigns />
          </LazyLoad>
        </Box>}
      </Box>
    </Suspense>
  )
}

export default Landing
