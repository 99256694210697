import React from 'react'
import { Box, chakra, Text, useColorModeValue as mode } from '@chakra-ui/react'
import { cookieData } from './cookieData'

function replaceWithBr() {
  return cookieData.replace(/\n/g, '<br />')
}

function Manage() {
  return (
    <>
      <Box
        p='6'
        my='8'
        bg='bg-surface'
        boxShadow={mode('lg', 'lg-dark')}
        _groupHover={{ boxShadow: mode('xl', 'xl-dark') }}
        transition='all 0.2s'
        w='90%'
      >
        <Text fontSize={['1.85rem', '2rem']} fontWeight={'600'} m={'4'} textAlign={'center'}>
          <chakra.span color={'brand.1'}>Cookie</chakra.span>{' '}
          <chakra.span color={'brand.2'}>Policy</chakra.span>
        </Text>

        <Box>
          <p dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
        </Box>
      </Box>
    </>
  )
}

export default Manage
