import React, { useEffect, useMemo, useState } from 'react';
import { CampaignBanner } from './components/CampaignBanner';
import {
  Flex,
  SimpleGrid,
  Text,
  chakra,
  useDisclosure,
  Stack,
  Button,
  VStack,
  GridItem,
  Box,
  HStack
} from '@chakra-ui/react';
import Pagination from '../../components/Pagination';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useCustomToast } from '../../hooks/customToast';
import VideoPopup from '../../components/VideoPopup';
import TestimonialVideoCard from '../../components/TestimonialVideoCard/TestimonialVideoCard';
import { CampNav } from './components/CampaignNav';
import Footer from '../../components/Footer';
import { readCookie } from '../../utils';
import LazyLoad from 'react-lazyload';

export const themeObj = {
  forest_dark: {
    background: '#0c191bfa',
    title: '#eff4f7',
    desc: '#cbd5e0',
    noOfReviews: '#02BFC8',
    averageRatingBg: '#FAB917',
    reviewsBg: 'transparent',
    footerLinksColor: 'teal.300',
    footerBg: '#0c191bfa',
    navBg: '#0c191bfa',
    navOutlineColor: '#0c191bfa'
  },
  default: {
    background: 'white',
    title: 'black',
    desc: 'gray.500',
    noOfReviews: '#02BFC8',
    averageRatingBg: 'brand.2',
    reviewsBg: '#b4e0e21a',
    footerLinksColor: 'black',
    footerBg: 'white',
    navBg: 'white'
  }
};

const TestimonialDetails = () => {
  const { addToast } = useCustomToast();
  let { campaignId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { search } = useLocation();
  const [referralCode, setReferralCode] = useState('');

  const [paginationData, setPaginationData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [error, setError] = useState('');
  const [cardInfoState, setCardInfo] = useState(null);

  const [campaignData, setCampaignData] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleEmbeded = () => {
    return searchParams.get('embeded') === 'true';
  };

  const onPaginationChange = (data, scrollToCards) => {
    const { currentPage, cardsPerPage } = data;

    const offset = (currentPage - 1) * cardsPerPage;
    const currentPageData = (campaignData?.reviews || []).slice(
      offset,
      offset + cardsPerPage
    );

    setCurrentPage(currentPage);
    setPaginationData(currentPageData);
    if (!scrollToCards && !dataLoading) {
      if (campaignData?.reviews?.length < 6) return;
      else {
        const section = document.querySelector('#testimonials');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  let detachSnap = () => null;

  const getApprovedTestimonials = async () => {
    try {
      const vanityCollRefQuery = query(
        collectionGroup(db, 'vanity'),
        where('vanityName', '==', String(campaignId).toLowerCase().trim())
      );
      const vanityData = await getDocs(vanityCollRefQuery);
      let getCampaignId = !vanityData.empty
        ? vanityData?.docs[0]?.ref?.parent?.parent?.id
        : campaignId;

      const campaignRef = doc(db, 'campaigns', getCampaignId);
      detachSnap = onSnapshot(campaignRef, async getCampaign => {
        if (!getCampaign.exists()) {
          setError('Campaign not found!');
        } else if (!getCampaign.data()?.active) {
          setError('Campaign not active anymore 😕');
        } else {
          if (!readCookie('referral') && getCampaign.data().userId) {
            handleReferral(getCampaign.data().userId);
          }

          const queryTestimonial = query(
            collection(db, 'reviews'),
            where('campaignId', '==', getCampaignId),
            where('approve', '==', true),
            orderBy('date', 'desc')
          );
          const getTestimonials = await getDocs(queryTestimonial);

          let avgRating = 0;
          if ('avgRating' in getCampaign.data()) {
            avgRating = getCampaign.data()?.avgRating;
          } else {
            avgRating = getTestimonials.docs
              .map(testimonial => testimonial.data()?.rating)
              .filter(rating => !!rating);
            avgRating =
              avgRating?.length > 0
                ? avgRating.reduce((sum, rating) => sum + Number(rating), 0) /
                  avgRating.length
                : 0;
          }

          let approvedReviews = await getDocs(queryTestimonial);
          approvedReviews = approvedReviews.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
          });

          let obj = {
            reviews: approvedReviews,
            ...getCampaign.data(),
            campaignId: getCampaignId,
            avgRating,
            totalTestimonials: approvedReviews?.length || 0
          };
          setCampaignData(obj);
          setDataLoading(false);
          setError('');
        }
      });
    } catch (error) {
      setDataLoading(false);
      console.log(error.message);
      addToast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        variant: 'left-accent'
      });
    }
  };

  function extractKeywords(arr, keyword) {
    let extractedKeywords = arr.map(item => item[keyword]);
    return extractedKeywords;
  }

  useEffect(() => {
    getApprovedTestimonials();
    return () => {
      detachSnap();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      campaignData?.reviews?.length &&
      search?.split('=')?.[0] === '?review'
    ) {
      const review = campaignData?.reviews.find(
        ({ id }) => id === search?.split('=')?.[1]
      );
      if (review) {
        onOpen();
        setCardInfo({
          ...review,
          campaignName: campaignData?.campaignDetails?.publicCampaignName
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData, search]);

  useEffect(() => {
    if (campaignData?.seo?.seo) {
      const result = extractKeywords(campaignData?.seo?.seo, 'keyword');
      setKeywords(result);
    }
  }, [campaignData]);

  const handleReferral = async id => {
    const docRef = doc(db, 'users', id);
    let data = (await getDoc(docRef)).data();
    if (data?.email) {
      let myHeaders = new Headers();
      myHeaders.append(
        'x-api-key',
        process.env.REACT_APP_FIRSTPROMOTORER_API_KEY
      );
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

      let urlencoded = new URLSearchParams();
      urlencoded.append('promoter_email', data?.email);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(
        'https://firstpromoter.com/api/v1/promoters/show?' + urlencoded,
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          const res = JSON.parse(result);
          !res?.error && setReferralCode(res?.default_ref_id);
        })
        .catch(error => console.log('error', error));
    }
  };

  const [visibleCount, setVisibleCount] = useState(4);

  const handleSeeMoreClick = () => {
    setVisibleCount(prevVisibleCount => prevVisibleCount + 4);
  };

  const wallTheme = useMemo(
    () =>
      campaignData?.campaignDetails?.wallTheme
        ? themeObj[campaignData?.campaignDetails?.wallTheme]
        : themeObj['default'],
    [campaignData?.campaignDetails]
  );

  return (
    <Box
      sx={{
        ...(handleEmbeded() && {
          padding: 0,
          margin: 0,
          height: '680px',
          overflow: 'auto'
        })
      }}>

      {!handleEmbeded() && (
        <CampNav referral={referralCode} wallTheme={wallTheme} />
      )}
      <Flex
        px={!handleEmbeded() ? { base: 1, sm: 2, md: 3 } : null}
        py={!handleEmbeded() ? 8 : 0}
        direction={'column'}
        width="full"
        gap={!handleEmbeded() ? 5 : 0}
        mb={
          !handleEmbeded()
            ? !campaignData?.campaignDetails?.wallTheme
              ? '50px'
              : 0
            : 0
        }
        background={wallTheme['background']}>
        {!handleEmbeded() && (
          <>
            {error ? (
              <Stack
                boxShadow="md"
                rounded="md"
                justifyContent="center"
                alignItems="center"
                width="full"
                minHeight="200px">
                <Text
                  px={4}
                  fontSize={['xl', '2xl', '3xl']}
                  color="red.400"
                  fontWeight="bold"
                  textAlign="center">
                  {error}
                </Text>
              </Stack>
            ) : (
              <CampaignBanner
                error={error}
                campaignData={campaignData || {}}
                dataLoading={dataLoading}
                wallTheme={wallTheme}
              />
            )}
          </>
        )}
        <LazyLoad>
          <Flex
            bg={wallTheme['reviewsBg']}
            px={['4']}
            pb="10"
            justifyContent="center"
            alignItems={'center'}
            position={'relative'}
            direction={'column'}>
            {handleEmbeded() && (
              <Button
                size="xs"
                variant={'outline'}
                fontSize={'10px'}
                fontWeight="600"
                cursor={'pointer'}
                as={'a'}
                href={`${window.location.origin}/wall/${campaignId}`}
                target="_blank"
                sx={{ position: 'absolute', top: 2, right: 3 }}>
                Visit Reviews Wall
              </Button>
            )}
            <SimpleGrid
              width="100%"
              spacing={'20px'}
              id="testimonials"
              pt={10}
              columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
              justifyItems={['stretch', 'stretch', 'stretch']}
              maxWidth={['fit-content', 'fit-content', 'full']}
              mx={['auto', 'auto', 0]}>
              {dataLoading || error ? null : paginationData?.length === 0 ? (
                <GridItem colSpan={12}>
                  <chakra.div
                    minH={'40'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    textAlign={'center'}
                    width="full">
                    <VStack
                      bg={'teal.50'}
                      rounded={'xl'}
                      p={{ base: 6, sm: 6, md: 8 }}
                      py={[8, 10, 12]}
                      width="full"
                      maxWidth="750px"
                      alignItems={'center'}
                      textAlign={'center'}
                      spacing={'4'}>
                      <Text
                        fontWeight={'bold'}
                        fontSize={['xl', '2xl', '3xl']}
                        color={'brand.1'}>
                        No video reviews received or approved yet
                      </Text>
                      <Text
                        fontWeight={'bold'}
                        fontSize={['sm', 'lg']}
                        color={'teal.400'}>
                        Be the first to leave your video review by clicking
                        below!
                      </Text>
                      <Button
                        mt={'3'}
                        variant={'solid'}
                        as={'a'}
                        href={`${window.location.origin}/review/${campaignData?.campaignId}`}
                        target={'_blank'}>
                        Submit Your Review
                      </Button>
                    </VStack>
                  </chakra.div>
                </GridItem>
              ) : (
                (handleEmbeded() ? campaignData?.reviews : paginationData)
                  ?.filter((_, i) =>
                    handleEmbeded() ? i < visibleCount : !handleEmbeded()
                  )
                  .map(cardInfo => (
                    <TestimonialVideoCard
                      key={cardInfo.id}
                      aspectRatio={5 / 4.5}
                      cardInfo={{
                        ...cardInfo,
                        campaignName:
                          cardInfo?.campaignDetails?.publicCampaignName
                      }}
                      onOpen={onOpen}
                      setCardInfo={setCardInfo}
                      showReviewInfo={true}
                      hideThumbnailInfo={true}
                      showShadow={!campaignData?.campaignDetails?.wallTheme}
                      sx={{
                        p: [4, 5],
                        maxW: ['400px', '435px'],
                        minW: { xs: 'full' },
                        backgroundColor: wallTheme['background']
                      }}
                    />
                  ))
              )}
            </SimpleGrid>
            {handleEmbeded() && (
              <HStack mt={8}>
                {!dataLoading && !error ? (
                  visibleCount < campaignData?.reviews.length ? (
                    <Button onClick={handleSeeMoreClick}>See More</Button>
                  ) : (
                    <Button
                      as="a"
                      href={`${window.location.origin}/wall/${campaignId}`}
                      target="_blank">
                      Visit Wall
                    </Button>
                  )
                ) : null}
              </HStack>
            )}
          </Flex>
        </LazyLoad>
        <VideoPopup
          isTestimonialWall
          isOpen={isOpen}
          onClose={e => {
            onClose(e);
            setCardInfo(null);
          }}
          cardInfo={cardInfoState}
          sx={{ backgroundColor: wallTheme['background'] }}
        />

        <Pagination
          currentPage={currentPage}
          cardsPerPage={8}
          totalCards={campaignData?.reviews?.length || 0}
          onPaginationChange={onPaginationChange}
          sx={{
            justifyContent: 'center',
            width: 'full',
            mt: '4',
            ...(handleEmbeded() && { display: 'none' })
          }}
        />
      </Flex>

      {!handleEmbeded() && <Footer wallTheme={wallTheme} />}
    </Box>
  );
};

export default TestimonialDetails;
