import React from 'react'

import { NewBlog } from './NewBlog'

const Blog = () => {
  return (
    <>
      <NewBlog />
    </>
  )
}

export default Blog
