import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Image,
  Modal,
  Box,
  chakra,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  AspectRatio,
  HStack,
  Text,
  IconButton,
  Tooltip,
  useDisclosure,
  VStack,
  Avatar,
  Stack,
  ModalHeader,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Checkbox,
} from '@chakra-ui/react'
import {
  MdDelete,
  MdDownload,
  MdShare,
  MdOutlineFileCopy,
  MdOutlineStayCurrentLandscape,
  MdOutlineStayCurrentPortrait,
} from 'react-icons/md'
import playIcon from '../../../assets/icons/video_play.png'
import {
  DeletePrompt,
  cloudfunctionsBaseURL,
  errorToast,
  extractNameParts,
  handleDeleteCloudinaryMedia,
  handleOpenLink,
  updateStatus,
} from '../../../utils'
import { deleteDoc, doc, serverTimestamp } from 'firebase/firestore'
import { db, storage } from '../../../firebase/config'
import { useCustomToast } from '../../../hooks/customToast'
import { useLocation, useParams } from 'react-router-dom'
import Rating from '../../../components/Rating'
import { deleteObject, ref } from 'firebase/storage'
import { MdPendingActions } from 'react-icons/md'
import EditVideo from '../../../assets/icons/edit-video.png'
import EditVideoModal from '../../../components/EditVideoModal'
import { format, parseISO } from 'date-fns'
import { BiWorld } from 'react-icons/bi'
import { FaAddressCard } from 'react-icons/fa'
import { AiFillCopy } from 'react-icons/ai'
import { userState } from '../../../recoil/atoms/auth'
import { useRecoilValue } from 'recoil'
import { IoIosPaper } from "react-icons/io";

function VideoPopup({ isTestimonialWall, isOpen, onClose, cardInfo }) {
  const { id } = useParams()

  const [loading, setLoading] = useState(false)

  const { addToast } = useCustomToast()
  const user = useRecoilValue(userState)
  const [playing] = useState(1)
  const { isOpen: isDeleteOpen, onOpen, onClose: closeDlt } = useDisclosure()
  const {
    isOpen: isEditVideoOpen,
    onOpen: onOpenEditVideo,
    onClose: onCloseEditVideo,
  } = useDisclosure()
  const [videoUrlCopied, setVideoUrlCopied] = useState(false)
  const videoRef = useRef(null)
  const [shareLink, setShareLink] = useState('')
  const { search } = useLocation()
  const [downloadPopover, setDownloadPopover] = useState(false)
  const [isLoading, setIsLoading] = useState({
    currentDownload: false,
    verticalDownload: false,
  })

  const autoPlay = useMemo(() => search?.split('=')?.[0] === '?review' || cardInfo?.autoPlay, [
    cardInfo,
    search,
  ])

  const handleApprove = () => {
    updateStatus(
      'reviews',
      cardInfo.id,
      { approve: !cardInfo.approve, reviewed: true, updatedAt: serverTimestamp() },
      addToast,
      {
        title: 'Review Status!',
        description: `Video Review ${cardInfo?.approve ? 'Unapproved' : 'Approved'}`,
        variant: cardInfo?.approve ? 'left-accent' : 'solid',
      }
    )
    onClose()
  }

  const handleDownload = async () => {
    setIsLoading((prev) => ({ ...prev, currentDownload: true }))
    try {
      const resp = await fetch(cardInfo?.review)
      const blob = await resp.blob()
      let anchor = document.createElement('a')
      anchor.href = URL.createObjectURL(blob)
      anchor.download = `review ${cardInfo?.name || cardInfo?.email}.mp4`
      anchor.click()
      anchor.remove()
      setIsLoading((prev) => ({ ...prev, currentDownload: false }))
      setDownloadPopover(false)
    } catch (err) {
      setIsLoading((prev) => ({ ...prev, currentDownload: false }))
      console.log(err)
    }
  }

  const handleTransformedVideo = async () => {
    setIsLoading((prev) => ({ ...prev, verticalDownload: true }))
    try {
      const res = await fetch(`${cloudfunctionsBaseURL}handleTransformedVideo`, {
        method: 'POST',
        body: JSON.stringify({
          url: 'sendmea/reviews/' + cardInfo?.id,
        }),
      })
      const response = await res.json()
      const resp = await fetch(response.data)
      const blob = await resp.blob()
      let anchor = document.createElement('a')
      anchor.href = URL.createObjectURL(blob)
      anchor.download = `review ${cardInfo?.name || cardInfo?.email}.mp4`
      anchor.click()
      anchor.remove()
      setIsLoading((prev) => ({ ...prev, verticalDownload: false }))
      setDownloadPopover(false)
    } catch (err) {
      setIsLoading((prev) => ({ ...prev, verticalDownload: false }))
      console.log(err)
      errorToast(err, addToast)
    }
  }

  const handleDlt = async () => {
    try {
      setLoading(true)
      if (cardInfo?.review.includes('cloudinary')) {
        await handleDeleteCloudinaryMedia({
          filename: cardInfo.id,
          folder: 'sendmea/reviews',
          resource_type: 'video',
        })
      } else {
        const storageRef = ref(storage, cardInfo.review)
        await deleteObject(storageRef)
      }
      const docRef = doc(db, 'reviews', cardInfo.id)
      await deleteDoc(docRef)
      onClose()
      addToast({
        title: 'Review Status!',
        description: `Video Review Deleted`,
        status: 'warning',
        variant: 'left-accent',
      })
      closeDlt()
      onClose()
      setLoading(false)
    } catch (err) {
      errorToast(err, addToast)
      setLoading(false)
    }
  }

  const handleShare = () => {
    navigator.clipboard.writeText(
      window.location.origin + '/wall/' + cardInfo?.campaignId + '?review=' + cardInfo?.id
    )
    setShareLink(true)
    setTimeout(() => {
      setShareLink(false)
    }, 5000)
  }

  const handleEditVideoOpen = () => {
    videoRef?.current?.pause()
    onOpenEditVideo(true)
  }

  const getIconByLabel = (label, property) => {
    const reviewOptions = [
      {
        label: 'SoTellUs',
        icon: 'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://sotellus.com&size=32',
      },
      {
        label: 'VideoTouch',
        icon: 'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://videotouch.io&size=32',
      },
      {
        label: 'YouTube',
        icon: 'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://youtube.com&size=32',
      },
      {
        label: 'Instagram',
        icon: 'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://instagram.com&size=32',
      },
    ];

    const option = reviewOptions.find(option => option.label === label);

    return option ? option[property] : null;
  };

  const [isLastName, setIsLastName] = useState(true)

  useEffect(() => {
    setIsLastName(!cardInfo?.showLastNameInitial)
  }, [cardInfo])

  const handleLastName = async (e) => {
    setIsLastName(e.target.checked)

    updateStatus(
      'reviews',
      cardInfo.id,
      { showLastNameInitial: !e.target.checked },
      addToast,
      {
        title: 'Updated!',
        description: '',
        variant: 'left-accent',
      }
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {!id && <ModalOverlay />}
      <ModalContent
        maxW={['full', '500px', '600px']}
        my={'auto'}
        mx={['2']}
      >
        <ModalHeader pl={4} fontSize={['20px']} color='brand.2'>
          <chakra.span color='teal.500' fontWeight='bold' fontSize='20px'>
            Watch
          </chakra.span>{' '}
          Video Review
        </ModalHeader>
        <ModalCloseButton
          bg={{ base: 'blackAlpha.300', sm: 'whiteAlpha.500' }}
          color={{ base: 'white', sm: 'black' }}
          zIndex={'999999'}
          stroke={'currentColor'}
          mt={'1'}
        />
        <ModalBody p={0} borderRadius='13.0523px'>
          <Box
            bg='white'
            p={3}
            pt={0}
            borderRadius='13.0523px'
          >
            <Box width={'100%'} height='100%' position={'relative'}>
              {!playing && (
                <Image
                  src={playIcon}
                  width={'55px'}
                  height={'55px'}
                  alt='play'
                  cursor='pointer'
                  position='absolute'
                  left={'50%'}
                  top={'50%'}
                  transform='translate(-50%, -50%)'
                  zIndex={'1'}
                />
              )}
              <AspectRatio ratio={5 / 3.5}>
                <Image
                  as={'video'}
                  controls={playing}
                  ref={videoRef}
                  autoPlay={autoPlay}
                  preload={'auto'}
                  width={'100%'}
                  height='100%'
                  borderRadius='13.0523px'
                  objectFit='contain !important'
                >
                  <source src={cardInfo?.review + '#t=0.2'} type='video/mp4' />
                  Video can take up to 5 minutes to load after uploading.
                </Image>
              </AspectRatio>
              {cardInfo && !cardInfo?.reviewed && 'reviewed' in cardInfo && (
                <Box
                  position='absolute'
                  width='80%'
                  top='3'
                  zIndex={4}
                  left={0}
                  right={0}
                  mx={'auto'}
                >
                  <HStack justify={'center'} zIndex={4}>
                    <Icon
                      as={MdPendingActions}
                      fontSize={['2rem', '3rem']}
                      color={'teal.200'}
                      transition='all .1s ease-in-out'
                      cursor={'pointer'}
                      _hover={{
                        transform: 'scale(1.2)',
                      }}
                    />
                    <Text color='white' fontWeight='bold' fontSize={'3xl'}>
                      Pending
                    </Text>
                  </HStack>
                </Box>
              )}
            </Box>
            <VStack
              p={2}
              spacing={2}
              mt={2}
              flex={1}
              alignItems='flex-start'
              minH={['max-content', 'max-content']}
            >
              <Stack
                direction={['column-reverse', 'column-reverse', 'row']}
                justifyContent='space-between'
                alignItems={['flex-start', 'flex-start', 'center']}
                gap={[1]}
                w='full'
              >
                <HStack spacing='10px' align='center'>
                  <Avatar
                    name={cardInfo?.name}
                    src={cardInfo?.photo}
                    size={'md'}
                  />
                  <VStack spacing={'0px'} alignItems='flex-start'>
                    <HStack>
                      <Text fontSize={['14px', '16px', '18.66px']} lineHeight='30px' fontWeight='500'>
                        {(isTestimonialWall && !isLastName) ? extractNameParts(cardInfo?.name) : cardInfo?.name}
                      </Text>
                      {!isTestimonialWall && <Checkbox type='checkbox' colorScheme='teal' size='sm' isChecked={isLastName} onChange={handleLastName}>
                        Last name
                      </Checkbox>}
                    </HStack>
                    <HStack direction='row' alignItems='center' spacing={'10px'}>
                      <Rating
                        total={5}
                        value={cardInfo?.rating || 0}
                        size={['16px']}
                        isStatic={true}
                        showNumber={false}
                      />
                      <Text fontSize='16px' lineHeight={0} color='gray.500' pb={2}>
                        ({parseFloat(cardInfo?.rating || 0)})
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
                {!isTestimonialWall && (
                  <Stack direction={'column'}>
                    <HStack>
                      <Tooltip
                        label={!cardInfo?.approve ? 'Approve' : 'Approved'}
                        hasArrow
                        bg='teal.400'
                      >
                        <Button
                          disabled={!cardInfo?.approve ? false : true}
                          onClick={handleApprove}
                          size={'xs'}
                          color={cardInfo?.approve ? 'teal.500' : 'teal.500'}
                          borderColor={cardInfo?.approve ? 'teal.500' : 'teal.500'}
                          bg={cardInfo?.approve ? 'teal.50' : 'teal.50'}
                        >
                          {!cardInfo?.approve ? ' Click to Approve' : 'Approved'}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        label={!cardInfo?.archive ? 'Reject' : 'Rejected'}
                        hasArrow
                        bg='teal.400'
                      >
                        <Button
                          disabled={cardInfo?.approve ? false : true}
                          onClick={handleApprove}
                          size={'xs'}
                          color={cardInfo?.archive ? 'red.500' : 'red.500'}
                          borderColor={cardInfo?.archive ? 'red.500' : 'red.500'}
                          bg={cardInfo?.archive ? 'red.50' : 'red.50'}
                        >
                          {cardInfo?.approve ? 'Click to Reject' : ' Rejected'}
                        </Button>
                      </Tooltip>
                    </HStack>

                    <HStack>
                      {/* Download */}
                      <Popover isOpen={downloadPopover}>
                        <Tooltip label='Download' hasArrow bg='teal.400'>
                          <Box display='inline-block'>
                            <PopoverTrigger>
                              <IconButton
                                mt={1}
                                icon={<MdDownload />}
                                variant='ghost'
                                color={'brand.1'}
                                fontSize={'1.7rem'}
                                _hover={{
                                  transform: 'scale(1.2)',
                                }}
                                onClick={() => setDownloadPopover(true)}
                              />
                            </PopoverTrigger>
                          </Box>
                        </Tooltip>
                        <PopoverContent minWidth={'sm'}>
                          <PopoverArrow />
                          <PopoverCloseButton onClick={() => setDownloadPopover(false)} />
                          <PopoverBody as={HStack} p={6}>
                            <Button
                              variant={'solid'}
                              onClick={handleDownload}
                              leftIcon={<Icon as={MdOutlineStayCurrentLandscape} />}
                              disabled={isLoading.currentDownload}
                              isLoading={isLoading.currentDownload}
                            >
                              standard (current)
                            </Button>
                            <Button
                              variant={'solid'}
                              onClick={handleTransformedVideo}
                              leftIcon={<Icon as={MdOutlineStayCurrentPortrait} />}
                              isLoading={isLoading.verticalDownload}
                              disabled={isLoading.verticalDownload}
                            >
                              Vertical
                            </Button>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>

                      {/* Edit */}
                      <Tooltip label='Edit' hasArrow background='teal.400'>
                        <IconButton
                          icon={<chakra.img src={EditVideo} width='24px' height='24px' mt={0} />}
                          variant='ghost'
                          color={'brand.1'}
                          fontSize={'1.7rem'}
                          onClick={handleEditVideoOpen}
                          _hover={{
                            transform: 'scale(1.2)',
                          }}
                        />
                      </Tooltip>

                      {/* Copy Url */}
                      <Tooltip
                        label={videoUrlCopied ? 'Copied' : 'Copy Video URL'}
                        hasArrow
                        bg='teal.400'
                      >
                        <IconButton
                          variant='ghost'
                          color='brand.1'
                          fontSize={'1.5rem'}
                          icon={<MdOutlineFileCopy />}
                          zIndex={2}
                          _hover={{
                            transform: 'scale(1.2)',
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(cardInfo?.review)
                            setVideoUrlCopied(true)
                            setTimeout(() => {
                              setVideoUrlCopied(false)
                            }, 5000)
                          }}
                        />
                      </Tooltip>

                      {/* Share */}
                      <Tooltip
                        label={
                          cardInfo?.approve ? (shareLink ? 'Copied' : 'Share') : 'Approve to share'
                        }
                        hasArrow
                        bg='teal.400'
                      >
                        <IconButton
                          disabled={!cardInfo?.approve}
                          icon={<MdShare />}
                          variant='ghost'
                          color={'brand.1'}
                          fontSize={'1.5rem'}
                          onClick={handleShare}
                          _hover={{
                            transform: 'scale(1.2)',
                          }}
                        />
                      </Tooltip>

                      {/* Edit */}
                      <Tooltip label='Delete' hasArrow background='red.400'>
                        <IconButton
                          icon={<MdDelete />}
                          variant='ghost'
                          color={'red.500'}
                          fontSize={'1.5rem'}
                          onClick={onOpen}
                          _hover={{
                            transform: 'scale(1.2)',
                          }}
                        />
                      </Tooltip>
                    </HStack>
                  </Stack>
                )}
              </Stack>

              <VStack spacing={2} flex={1} alignItems='flex-start' width='100%'>
                <chakra.p
                  flex={1}
                  fontWeight='400'
                  fontSize='16px'
                  fontStyle='italic'
                  lineHeight='24px'
                  color='gray.500'
                  py={2}
                  wordBreak={'break-all'}
                >
                  "{cardInfo?.reviewMessage || 'No review by customer'}"
                </chakra.p>
                <VStack spacing={'5px'} width='100%' alignItems='flex-start'>
                  {cardInfo?.email && (
                    <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='gray.500'>
                      <chakra.span fontWeight='bold' color='gray.600'>
                        Campaign Page:{' '}
                      </chakra.span>
                      {cardInfo?.email}
                    </Text>
                  )}
                  {cardInfo?.companyName && (
                    <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='gray.500'>
                      <chakra.span fontWeight='bold' color='gray.600'>
                        Company Name:{' '}
                      </chakra.span>
                      {cardInfo?.companyName}
                    </Text>
                  )}
                  {cardInfo?.jobTitle && (
                    <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='gray.500'>
                      <chakra.span fontWeight='bold' color='gray.600'>
                        Job Title:{' '}
                      </chakra.span>
                      {cardInfo?.jobTitle}
                    </Text>
                  )}
                  {cardInfo?.webPage && (
                    <Text
                      fontWeight='400'
                      fontSize='14px'
                      lineHeight='24px'
                      color='green.400'
                      cursor='pointer'
                      onClick={(_) => handleOpenLink(cardInfo?.webPage)}
                      wordBreak='break-word'
                    >
                      <chakra.span fontWeight='bold' color='gray.600'>
                        Web Page:{' '}
                      </chakra.span>
                      {cardInfo?.webPage}
                    </Text>
                  )}
                  {cardInfo?.profileURL && (
                    <Text
                      fontWeight='400'
                      fontSize='14px'
                      lineHeight='24px'
                      color='green.400'
                      cursor='pointer'
                      onClick={(_) => handleOpenLink(cardInfo?.profileURL)}
                      wordBreak='break-word'
                    >
                      <chakra.span fontWeight='bold' color='gray.600'>
                        Profile URL:{' '}
                      </chakra.span>
                      {cardInfo?.profileURL}
                    </Text>
                  )}
         
                  <Text fontWeight='400' fontSize='18px' lineHeight='24px' color='gray.500'>
                  Campaign Link:
                    <chakra.span pl='2' fontWeight='bold' color='gray.600'>
                     <a href={cardInfo?.campaignUrl}>{cardInfo?.campaignUrl}</a>
                    </chakra.span>
                    {/* {cardInfo?.date?.toDate()?.toDateString()} */}
                  </Text>
                  {cardInfo?.originalDate &&
                    <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='gray.500'>
                      <chakra.span fontWeight='bold' color='gray.600'>
                        Original Date of Review:{' '}
                      </chakra.span>
                      {format(parseISO(cardInfo?.originalDate), 'EEE MMM d yyyy')}
                    </Text>
                  }
                  {cardInfo?.videoOriginWebsite && (
                    <HStack>
                      <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='gray.500'>
                        <chakra.span fontWeight='bold' color='gray.600'>
                          Originally Uploaded At:{' '}
                        </chakra.span>
                      </Text>
                      {(cardInfo?.videoOriginWebsite?.toLowerCase() === 'other') ? (
                        <Tooltip label={getIconByLabel(cardInfo?.videoOriginWebsite, 'label')} hasArrow background='teal.400'>
                          <IconButton
                            variant={"link"}
                            icon={<BiWorld fontSize={"22px"} />}
                            cursor="default"
                            size="sm"
                            p={0}
                            color="teal.500"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip label={getIconByLabel(cardInfo?.videoOriginWebsite, 'label')} hasArrow background='teal.400'>
                          <chakra.img src={getIconByLabel(cardInfo?.videoOriginWebsite, 'icon')} />
                        </Tooltip>
                      )}
                    </HStack>
                  )}
                </VStack>
              </VStack>
              {cardInfo?.transcriptMessage &&
                <Tooltip
                  label={cardInfo?.transcriptMessage}
                  hasArrow
                  bg='teal.400'
                >
                    <IconButton
                          icon={<IoIosPaper />}
                          variant='ghost'
                          color={'gray.200'}
                          fontSize={'1.5rem'}
                          onClick={handleShare}
                          _hover={{
                            transform: 'scale(1.2)',
                          }}
                          sx={{ position: 'absolute', bottom: '6%', right: '1%' }}
                        />
                </Tooltip>
              }
              {(cardInfo?.address && user?.uid === cardInfo?.userId && !isTestimonialWall) && <AddressPopover val={cardInfo?.address} />}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
      <DeletePrompt isOpen={isDeleteOpen} onClose={closeDlt} accept={handleDlt} loading={loading} />
      <EditVideoModal isOpen={isEditVideoOpen} onClose={onCloseEditVideo} reviewDetails={cardInfo} />
    </Modal>
  )
}

export default VideoPopup

const AddressPopover = ({ val }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const { addToast } = useCustomToast()


  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement='top-end'
    >
      <PopoverTrigger>
        <IconButton
          variant={"link"}
          icon={<FaAddressCard fontSize={"22px"} />}
          cursor="pointer"
          size="sm"
          p={0}
          color="teal.500"
          onClick={onToggle}
          sx={{ position: 'absolute', bottom: '2%', right: '2%' }}
        />
      </PopoverTrigger>
      <PopoverContent _focus={{ boxShadow: 'md' }}
        borderColor='teal.300'
        bg='teal.600'
        maxW='250px'
        filter={'brightness(1.2) drop-shadow(3px 3px 6px gray)'}
      >
        <PopoverArrow
          bg='teal.600'
          borderColor={'#7ed957b5'}

        />
        <PopoverCloseButton color={'white'} />
        <PopoverBody py={0}>
          <VStack direction='row' spacing='10px' alignItems='flex-start' p={[3]} px={[0]} maxW='250px'>
            <VStack spacing={1} alignItems={'flex-start'}>
              <HStack spacing={1} alignItems={'center'}>
                <Text fontStyle={'italic'} fontWeight={'bold'} color='brand.2'>User Address</Text>
                <Box
                  height={'16px'}
                  onClick={() => {
                    navigator.clipboard.writeText(`
                    ${val?.street}, 
                    ${val?.apt ? val?.apt + "," : ''} 
                    ${val?.city},
                    ${val?.state},
                    ${val?.zip}`
                    )
                    addToast({ title: 'Address', description: 'Address Copied', status: 'success', variant: 'left-accent', })
                  }}
                  cursor={'pointer'}
                >
                  <Icon
                    fontSize={'18px'}
                    color={'white'}
                    transition={'all 0.4s ease'}
                    _hover={{
                      color: 'teal.100'
                    }}
                    as={AiFillCopy}
                  />
                </Box>
              </HStack>
              <Text fontStyle={'italic'} color='white'>
                <chakra.span>{val?.street}</chakra.span>,<br />
                {val?.apt && <><chakra.span>{val?.apt}</chakra.span>,<br /></>}
                <chakra.span>{val?.city}, {val?.state}</chakra.span>,<br />
                <chakra.span>{val?.zip}</chakra.span><br />
              </Text>
            </VStack>
            <VStack spacing={1} alignItems={'flex-start'}>
              <HStack spacing={1} alignItems={'center'}>
                <Text fontStyle={'italic'} fontWeight={'bold'} color='brand.2'>Phone</Text>
                <Box
                  height={'16px'}
                  onClick={() => {
                    navigator.clipboard.writeText(val?.phone)
                    addToast({ title: 'Phone', description: 'Phone Copied', status: 'success', variant: 'left-accent', })
                  }}
                  cursor={'pointer'}
                >
                  <Icon
                    fontSize={'18px'}
                    color={'white'}
                    transition={'all 0.4s ease'}
                    _hover={{
                      color: 'teal.100'
                    }}
                    as={AiFillCopy}
                  />
                </Box>
              </HStack>
              <Text fontStyle={'italic'} color='white'>
                {val?.phone}
              </Text>
            </VStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
