
import React, { useEffect, useState } from 'react'
import { Avatar, Box, HStack, Text, VStack, chakra, Checkbox } from '@chakra-ui/react'
import Rating from '../../../../components/Rating'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../../firebase/config'


const Card = ({
    data
}) => {
    const { createdAt, campaignDetails, avgRating, totalReviews, customName, userId } = data
    const [userState, setUserState] = useState(null)

    useEffect(() => {
        if (userId) {
            (async () => {
                const docSnap = await getDoc(doc(db, 'users', userId))
                docSnap.exists && setUserState(docSnap.data())
            })()
        }
    }, [userId])

    const [customCopied, setCustomCopied] = useState(false)

    return (
        <VStack w='full'
            p={{ base: 4, md: 4, }}
            alignItems='flex-start'
            bg="white"
            shadow="lg"
            rounded="lg"
            spacing={0}
            gap={'3'}
        >
            <VStack w='full' alignItems='center' spacing={2}>
                {userState && <Text
                    fontSize={["xl", "xl"]} textTransform='capitalize'
                    fontWeight="bold"
                    color="gray.800"
                    position={'relative'}
                    _after={{
                        content: "''",
                        width: 'full',
                        height: { base: '15%', md: '20%' },
                        position: 'absolute',
                        bottom: 1,
                        left: 0,
                        bg: 'brand.2',
                        zIndex: -1,
                    }}>
                    {userState?.firstName + userState?.lastName}
                </Text>}
                <Box w={'100px'} h='100px'>
                    <Avatar
                        w='full' h='full'
                        rounded={'md'}
                        name={campaignDetails?.publicCampaignName}
                        src={campaignDetails?.campaignLogo}
                        sx={{
                            '& img': { objectFit: 'contain', borderRadius: 'md' }
                        }}
                        {...(campaignDetails?.campaignLogo &&
                            { bg: 'transparent' }
                        )}
                    />
                </Box>
                <VStack spacing={"0px"} gap={'2'} alignItems="center" zIndex={2}>
                    <Text
                        fontSize={["xl", "xl"]} textTransform='capitalize'
                        fontWeight="bold"
                        color="gray.800"
                        position={'relative'}
                        _after={{
                            content: "''",
                            width: 'full',
                            height: { base: '15%', md: '20%' },
                            position: 'absolute',
                            bottom: 1,
                            left: 0,
                            bg: 'brand.2',
                            zIndex: -1,
                        }}>
                        {campaignDetails?.publicCampaignName ||
                            campaignDetails?.internalCampaignName ||
                            '-'
                        }
                    </Text>
                    <HStack direction="row" alignItems="center" spacing={"10px"} >
                        <Rating
                            total={5}
                            value={Math.round(avgRating) || 0}
                            size={["18px"]}
                            isStatic={true}
                            showNumber={false}
                        />
                        <Text fontSize="16px" lineHeight={0} color="gray.500" pb={2}>
                            ({Number(avgRating).toFixed(avgRating ? 2 : 0)})
                        </Text>
                    </HStack>
                    <Text fontSize='16px' color='brand.1' pb={1} fontWeight='bold'>
                        {totalReviews} Reviews
                    </Text>
                </VStack>
            </VStack>

            <VStack w='full' alignItems='flex-start' spacing={0} gap={'2'} flex={1}>
                <VStack spacing={"5px"} flex={1} alignItems="flex-start" >
                    <Text fontSize="14px" color="gray.600" noOfLines={[3]} fontStyle={'italic'} minH='40px'>
                        {campaignDetails?.campaignDescription}
                    </Text>
                </VStack>
                <Checkbox fontSize="14px" color="gray.600" defaultChecked>Completed</Checkbox>
            </VStack>

            <HStack mt={3} alignItems="center" w='full' justifyContent="space-between"
                spacing={0} gap={'2'} flexWrap='wrap'
            >
      
                <Text fontWeight="400" fontSize="14px" lineHeight="24px" color="gray.500">
                    {createdAt ? createdAt.toDate()?.toDateString() : 'NA'}
                </Text>
                <HStack>
                    {customName &&
                        <chakra.button
                            px={2} py={1}
                            fontSize="xs"
                            color="gray.600"
                            fontWeight="bold"
                            rounded="lg"
                            textTransform="uppercase"
                            bg={"teal.100"}
                            _active={{
                                transform: 'scale(0.90)',
                                boxShadow: "none",
                            }}
                            onClick={(_) => {
                                navigator.clipboard.writeText(`${window.location.origin}/wall/${customName}`)
                                setCustomCopied(true)
                                setTimeout(() => {
                                    setCustomCopied(false)
                                }, 2000)
                            }}
                        >
                            {customCopied ? 'Copied' : 'Copy Custom'}
                        </chakra.button>
                    }
                </HStack>

            </HStack>
        </VStack>
    )
}

export default Card