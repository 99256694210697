import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
  useDisclosure,
  Select,
  FormErrorMessage,
  FormControl,
  Input,
  Textarea,
  FormLabel,
  Text,
  useBreakpointValue,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  HStack,
  IconButton,
  VStack,
  Tooltip,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiSend } from 'react-icons/fi'
import { useRecoilState } from 'recoil'
import { useCollection } from '../hooks/useCollection'
import { userState } from '../recoil/atoms/auth'
import emailjs from '@emailjs/browser'
import { useCustomToast } from '../hooks/customToast'
import {
  errorToast,
  fireCanvaParticles,
} from '../utils'
import { expressSetupInfo } from '../recoil/atoms/expressSetup'
import { db } from '../firebase/config'
import { doc, updateDoc, getDoc, collection, serverTimestamp, setDoc } from 'firebase/firestore'
import { BsFacebook, BsFillTelephoneFill } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai'
import { RiWhatsappFill } from 'react-icons/ri'

function SendInvite() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [user] = useRecoilState(userState)
  const trimText = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  })
  const [expressSetup, setExpressSetup] = useRecoilState(expressSetupInfo)

  const { documents: campaignDocs } = useCollection('campaigns', ['userId', '==', user.uid])
  const { addToast } = useCustomToast()
  const [loading, setLoading] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [campId, setCampId] = useState(null)
  const [custom, setCustom] = useState(null)
  const [campaignD, setCampaignD] = useState(null)
  const initialValues = {
    campaignLink: '',
    email: '',
    phoneNumber: '',
    message: '',
    to_name: '',
    code: '+1',
  }
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialValues,
  })

  useEffect(() => {
    if (!!campaignDocs?.length && expressSetup?.shouldOpenSendInvite && expressSetup?.campaignId) {
      onOpen()
      setTimeout(() => {
        setValue('campaignLink', expressSetup.campaignId)
      }, 1000)
    }

  }, [campaignDocs])

  useEffect(() => {
    campaignDocs?.length === 1 && setValue('campaignLink', campaignDocs[0].id)
  }, [campaignDocs?.length])

  const getCustomReviewMessage = () => {
    const object = campaignDocs?.find((obj) => obj.id === watch('campaignLink'))
    try {
      setCampaignD(object)
      setCustom(campaignD?.customRequestMessage)

      if (object?.customRequestMessage?.customRequestMessage) {
        setValue(
          'message',
          object?.customRequestMessage?.customRequestMessage ||
          `${user?.firstName} has requested that you create a video review, please click the following link:`
        )
      } else {
        setValue(
          'message',
          `${user?.firstName} has requested that you create a video review, please click the following link:`
        )
      }
    } catch (err) {
      console.log('error getCustomReviewMessage: ', err)
    }
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const subCollInvites = collection(db, 'campaigns', data?.campaignLink, 'invites')
      const newInviteId = doc(subCollInvites).id

      if (campId) {
        const docRef = doc(db, 'campaigns', campId)
        let campaignDoc = await getDoc(docRef)
        if (campaignDoc.exists()) {
          let campaignData = { ...campaignDoc.data(), id: campaignDoc.campId }
          await updateDoc(docRef, {
            sentRequests: campaignData.sentRequests + 1 || 1,
          })
        }
      }

      if (data.code && !data.email) {
        const textForManual = `${data.message} ${window.location.origin}/review/${data.campaignLink}?inviteId=${newInviteId}`
        navigator.clipboard.writeText(textForManual)
        addToast({
          id: 'messageForRequestAReview',
          title: 'Copied!',
          description: 'Invite message copied',
          status: 'success',
          variant: 'left-accent',
        })
        onClose()
      } else if (data.email) {
        const name = data.email.split('@')[0]
        const templateParams = {
          to_email: data.email,
          to_name: name.charAt(0).toUpperCase() + name.slice(1),
          from_name: `${user?.firstName} ${user?.lastName}`,
          campaign_link: `<a href="${window.location.origin}/review/${data.campaignLink}?inviteId=${newInviteId}" target="_blank">${window.location.origin}/review/${data.campaignLink}?inviteId=${newInviteId}</a>`,
          message: data.message,
        }
        const response = await emailjs.send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        )
        if (response.status === 200) {
          await setDoc(doc(db, 'campaigns', data?.campaignLink, 'invites', newInviteId), {
            type: 'email',
            to: data.email,
            message: data.message,
            createdAt: serverTimestamp()
          })
          addToast({
            title: 'Email Status!',
            description: `Invite Sent Successfully`,
            status: 'success',
          })
          onClose()
          setExpressSetup({
            campaignId: null,
            shouldOpenSendInvite: false,
          })
          reset({ ...initialValues, campaignLink: data.campaignLink })
        }
      }
      setLoading(false)
      fireCanvaParticles()
    } catch (err) {
      setLoading(false)
      errorToast(err, addToast)
      console.log(err)
    }
  }

  useEffect(() => {
    getCustomReviewMessage()
    setCampId(watch('campaignLink'))
  }, [watch('campaignLink')])


  const colorProps = (name) => ({
    borderColor: `${name}.400`, bg: `${name}.500`,
    _hover: {
      bg: `${name}.400`,
    }
  })

  return (
    <>
      <Button
        variant={'ghost'}
        color={'brand.1'}
        leftIcon={<Icon as={FiSend} />}
        px={['1', '3']}
        size={['xs', 'sm', 'md']}
        py={{ base: '5' }}
        onClick={onOpen}
      >
        {trimText ? 'Request Review' : 'Request a Video Review'}
      </Button>

      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={() => {
          onClose()
          setExpressSetup({
            campaignId: null,
            shouldOpenSendInvite: false,
          })
          clearErrors()
        }}
      >
        <ModalOverlay />
        <ModalContent as={'form'} onSubmit={handleSubmit(onSubmit)} mx={['10px', '5px', 0]}>
          {/* <ModalHeader pl={4} fontSize={["20px"]} color="gray.700">
        </ModalHeader> */}
          <ModalHeader>
            <Text fontSize={['20px']} color='brand.2'>
              <chakra.span color='teal.500' fontWeight='bold' fontSize='20px'>
                Request
              </chakra.span>{' '}
              A Video Review
            </Text>
            {/* Send Invite */}
            <Text color='gray.500' fontSize='md' mt={2}>
              Send a video review request via SMS or email.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={['4', '6']}>
            <FormControl isInvalid={!!errors?.campaignLink} mb={'2'}>
              {/* <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}> */}
              {/* Select Campaign */}
              {/* </FormLabel> */}

              <Select
                bg={'gray.100'}
                placeholder={'Select Campaign'}
                color={'gray.800'}
                _placeholder={{
                  color: 'gray.500',
                }}
                name={'campaignLink'}
                {...register('campaignLink', {
                  required: 'Please select a campaign! (Required)',
                })}
              >
                {campaignDocs?.map((v, i) => (
                  <option key={i} value={v.id}>
                    {v?.campaignDetails?.internalCampaignName}
                  </option>
                ))}
              </Select>
              {errors.campaignLink && (
                <FormErrorMessage fontSize={['md']}>{errors.campaignLink.message}</FormErrorMessage>
              )}
            </FormControl>
            <Tabs onChange={(index) => setTabIndex(index)} mt={4}>
              <TabList
                gap='4px'
                justifyContent={['space-around']}
                mx={['auto', 'auto', 0]}
                sx={{
                  width: 'full',
                  border: '2px solid',
                  borderRadius: '3px',
                }}
                borderColor={(theme) => theme.colors.gray['100'] + ' !important'}
              >
                <Tab
                  w='full'
                  fontWeight='medium'
                  borderRadius='2px'
                  mb={0}
                  _selected={{ color: 'white', borderColor: 'gray.200', background: 'teal.400' }}
                >
                  Share Request
                </Tab>
                <Tab
                  w='full'
                  fontWeight='medium'
                  borderRadius='2px 0 0 2px'
                  mb={0}
                  _selected={{ color: 'white', borderColor: 'gray.100', background: 'teal.400' }}
                >
                  Email
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0} py={1.5}>
                </TabPanel>
                <TabPanel p={0} py={3}>
                  <FormControl isInvalid={!!errors?.email} mb={'2'}>
                    <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                      Email Address
                    </FormLabel>
                    <Input
                      placeholder='Email'
                      bg={'gray.100'}
                      border={0}
                      color={'gray.800'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      onKeyDown={() => {
                        getCustomReviewMessage()
                      }}
                      {...register('email', {
                        required: (!watch('phoneNumber') && tabIndex === 1) && 'This is required',
                        disabled: watch('phoneNumber')?.length > 5 && true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                    />
                    {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <FormControl isInvalid={!!errors?.message}>
              <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                Your custom request message:
              </FormLabel>
              <Textarea
                placeholder={custom}
                bg={'gray.100'}
                border={0}
                color={'gray.800'}
                _placeholder={{
                  color: 'gray.500',
                }}
                py={2}
                rows={4} resize={'none'}
                {...register('message', {
                  maxLength: {
                    value: 170,
                    message: 'should be less than 170 characters',
                  },
                })}
                defaultValue={custom}
              />
              {errors.message && <FormErrorMessage>{errors.message.message}</FormErrorMessage>}
            </FormControl>
            {tabIndex !== 1 &&
              <HStack w={'full'} spacing={4} mt={6} maxW={'380px'} justify={'space-around'} mx={'auto'} overflowX={'auto'}>
                <Tooltip label={'Copy Text'} hasArrow background='teal.400'>
                  <VStack spacing={0.5}>
                    <IconButton
                      color='white'
                      rounded='full'
                      width={['40px', '50px', '60px']}
                      height={['40px', '50px', '60px']}
                      type='submit'
                      boxShadow={'md'}
                      {...colorProps('purple')}
                    >
                      <Icon
                        as={BsFillTelephoneFill}
                        fontSize={[22, 26, 30]}
                      />
                    </IconButton>
                    <Text fontSize={['xs', 'sm']}>Phone</Text>
                  </VStack>
                </Tooltip>
                <Tooltip label={'Whatsapp'} hasArrow background='teal.400'>
                  <VStack spacing={0.5}>
                    <IconButton
                      color='white'
                      rounded='full'
                      width={['40px', '50px', '60px']}
                      height={['40px', '50px', '60px']}
                      boxShadow={'md'}
                      {...colorProps('whatsapp')}
                      onClick={() => {
                        if (!watch('campaignLink')) return setError('campaignLink', { message: 'Please select a campaign! (Required)' });
                        const subCollInvites = collection(db, 'campaigns', watch('campaignLink'), 'invites')
                        const newInviteId = doc(subCollInvites).id
                        const text = `${watch('message')} ${window.location.origin}/review/${watch('campaignLink')}?inviteId=${newInviteId}`
                        window.open(`https://wa.me/?text=${text}`)
                      }}
                    >
                      <Icon
                        as={RiWhatsappFill}
                        fontSize={[25, 29, 33]}
                      />
                    </IconButton>
                    <Text fontSize={['xs', 'sm']}>WhatsApp</Text>
                  </VStack>
                </Tooltip>
                <Tooltip label={'Facebook Messenger'} hasArrow background='teal.400'>
                  <VStack spacing={0.5}>
                    <IconButton
                      color='white'
                      rounded='full'
                      width={['40px', '50px', '60px']}
                      height={['40px', '50px', '60px']}
                      boxShadow={'md'}
                      {...colorProps('facebook')}
                      onClick={() => {
                        if (!watch('campaignLink')) return setError('campaignLink', { message: 'Please select a campaign! (Required)' });
                        const subCollInvites = collection(db, 'campaigns', watch('campaignLink'), 'invites')
                        const newInviteId = doc(subCollInvites).id
                        const text = `https://sendmea.io/review/${watch('campaignLink')}?inviteId=${newInviteId}`
                        window.FB.ui({
                          method: 'send',
                          link: text,
                          quote: watch('message'),
                        }, function (response) {
                          if (response && !response.error_message) {
                            addToast({
                              id: 'messageForRequestAReview',
                              title: 'Success!',
                              description: 'Posting completed.',
                              status: 'success',
                              variant: 'left-accent',
                            })
                          } else {
                            errorToast({ message: 'Error while posting.' }, addToast)
                          }
                        })

                      }}
                    >
                      <Icon
                        as={BsFacebook}
                        fontSize={[25, 29, 33]}
                      />
                    </IconButton>
                    <Text fontSize={['xs', 'sm']}>Facebook</Text>
                  </VStack>
                </Tooltip>
                <Tooltip label={'Twitter'} hasArrow background='teal.400'>
                  <VStack spacing={0.5}>
                    <IconButton
                      color='white'
                      rounded='full'
                      width={['40px', '50px', '60px']}
                      height={['40px', '50px', '60px']}
                      boxShadow={'md'}
                      {...colorProps('twitter')}
                      onClick={() => {
                        if (!watch('campaignLink')) return setError('campaignLink', { message: 'Please select a campaign! (Required)' });
                        const subCollInvites = collection(db, 'campaigns', watch('campaignLink'), 'invites')
                        const newInviteId = doc(subCollInvites).id
                        const text = `${watch('message')} ${window.location.origin}/review/${watch('campaignLink')}?inviteId=${newInviteId}`
                        window.open(`https://twitter.com/share?url=${text}`)
                      }}
                    >
                      <Icon
                        as={AiOutlineTwitter}
                        fontSize={[25, 29, 33]}
                      />
                    </IconButton>
                    <Text fontSize={['xs', 'sm']}>Twitter</Text>
                  </VStack>
                </Tooltip>
              </HStack>
            }

          </ModalBody>
          <ModalFooter>
            {!!tabIndex && <Button variant='solid' type='submit' isLoading={isSubmitting}>
              Send
            </Button>}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SendInvite
