import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    Image,
    Text,
    useDisclosure,
    useColorModeValue,
    Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import Slogo from '../../../assets/images/sendmea-logo.png'

const Links = [{ name: 'Contact Sendmea', path: '/contact' }];



export default function SalesNavBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
 

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <HStack width={'full'} spacing={8} alignItems={'center'} justify={'space-between'}>
                        <Stack
                            direction='row'
                        >
                            <Image maxH='50px' width={["150px", "200px"]} src={Slogo} alt='logo' />
                        </Stack>
                        <HStack
                            as={'nav'}
                            spacing={4}
                            color='teal'
                            display={{ base: 'none', md: 'flex' }}>
                            {Links.map((link) => (
                                <Link href={link.path} key={link.path}>
                                        <Text as={'b'}>{link.name}</Text>
                                </Link>
                            ))}
                        </HStack>
                    </HStack>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />


                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <Link href={link.path} key={link.name}>
                                    <Text as={'b'}>{link.name}</Text>
                                </Link>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>


        </>
    );
}