import {
  Text,
  Container,
  Flex,
  Box,
  useBreakpointValue,
  useDisclosure, Modal, ModalOverlay, Tooltip, ModalContent, ModalCloseButton, IconButton
} from '@chakra-ui/react'
import React from 'react'
import { ProfileBody } from './ProfileBody'
import { ContactModal } from '../ContactModal/ContactModal'
import { Sidebar } from './Sidebar'
import { getNameInitials } from '../../../../utils'
import { MobileBar } from '../ContactModal/MobileBar'


export const ProfileModal = ({ campaignData, disableIcon }) => {

  const { campaignDetails } = campaignData

  const { isOpen, onOpen, onClose } = useDisclosure()

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <div>
      <>
        <Tooltip
          label='Visit Profile'
          fontSize='md'
          placement='bottom'
          bg='teal.400'
          rounded='md'
          hasArrow
          closeOnPointerDown>
          <IconButton
            variant="solid"
            aria-label="Open Menu"
            color={'gray.50'}
            bg={'#78bdc0'}
            borderRadius="full"
            fontSize="1.4rem"
            onClick={() => !disableIcon && onOpen()}
            sx={{ width: '55px', height: '55px' }}
          >
            <Text>
              {getNameInitials(campaignDetails?.publicCampaignName || campaignDetails?.internalCampaignName || "-")}
            </Text>
          </IconButton>
        </Tooltip>
        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} >
          <ModalOverlay />
          <ModalContent maxW="45rem" >
            <Flex
              as="section"
              direction={{
                base: 'column',
                lg: 'row',
              }}
              minHeight="60vh"
              bg="bg-canvas"
              overflowY="auto"
            >

              {isDesktop ? <Sidebar /> : null}

              <Container py="2" flex="1">
                <ProfileBody campaignData={campaignData} />

              </Container>
              {isDesktop ? <> <Box pb={4} mr={4} alignSelf={'end'}>
                <Flex align={'center'} justify='space-between' >
                  <ContactModal />
                </Flex>

              </Box></> : <Box pb={4} mr={6} >
                <Flex align={'center'} justify='space-between' >
                  <MobileBar />
                  <ContactModal />
                </Flex>
              </Box>}


            </Flex>
            <ModalCloseButton />
          </ModalContent>
        </Modal>
      </>

    </div>
  )
}
