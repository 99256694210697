import { Box, Container, Divider, Flex, Text, VStack, chakra } from '@chakra-ui/react'
import React from 'react'

const PreviewEmbed = () => {
    return (
        <Container
            py={{ base: '0', sm: '4' }}
            my={{ base: 6, md: '8' }}
            maxW={'container.xl'}
        >
            <Flex alignItems={'center'} gap={2} mt={2}
                flexDirection={['column', 'column', 'row']}
                mx={{ base: 4, md: 6 }}
                sx={{
                    '> *': { fontFamily: 'Dosis,sans-serif' }
                }}
                marginTop={['10', '0']}
                mb={'8'}
            >
                <VStack flex={1} spacing={0} gap={2} alignItems={'flex-start'}>
                    <Text fontSize={['2xl', '3xl']} fontWeight={'bold'}>
                        Code Shock, LLC
                    </Text>
                    <Text fontSize={['xl', '2xl']} color={'#3f708e'} fontWeight={'bold'}>
                        creative + intelligent
                    </Text>
                    <Text fontSize={['xl', '2xl']} color={'#3f708e'} fontWeight={'bold'}>
                        development
                    </Text>
                    <Text fontSize={['lg', 'xl']} color={'#3f708e'}>
                        \ ˈshäk \ - noun. "Something that causes a
                        <chakra.span fontWeight={'bold'} color={'#fbb304'} mx={1}>
                            disturbance in the equilibrium or permanence of something
                        </chakra.span>
                        ." We keep your future in mind by building secure and scaleable software so that your prototype
                        can grow into a production level product. Build once, improve upon until your exit!
                    </Text>
                    <Text fontSize={['xl', '2xl']} color={'#3f708e'} mt={'16px !important'} fontWeight={'bold'} maxW={'500px'}>
                        Let us help you solve your JavaScript issues!
                    </Text>
                </VStack>
                <Flex flex={1} justifyContent={'center'}>
                    <chakra.img src='https://res.cloudinary.com/codeshock/image/upload/w_496,h_496/flame-remote-working_jaxrs1.png'
                        sx={{
                            height: 'auto', maxWidth: '100%', verticalAlign: 'middle', display: 'block'
                        }}
                    />
                </Flex>
            </Flex>
            <Text mb={10} color={'#fbb304'} fontWeight={'bold'} textAlign={'center'} fontSize={['2xl', '3xl']} px={['8']}>
                Sendmea Embedded Video Reviews
            </Text>
            <Box
                boxShadow='0px 0px 24px rgba(234, 234, 234, 0.7)'
                borderRadius={'25px'}
                padding={'1px'}
                border={'1px solid #8080803d'}
                overflow={'hidden'}
                bg={'gray.200'}
            >
                <iframe
                    src="https://sendmea.io/wall/sendmea?embeded=true"
                    width="100%"
                    height="680"
                    style={{ border: 0 }}
                    title='Sendmea Reviews'
                />
            </Box>
            <Text my={10} color={'#fbb304'} fontWeight={'bold'} textAlign={'center'} fontSize={['2xl', '3xl']} px={['8']}>
                Our Projects
            </Text>
            <Flex
                gap={6} mt={10}
                flexDirection={['column', 'column', 'row']}
                mx={{ base: 4, md: 6 }}
                alignItems={'center'}
            >
                <VStack alignItems={'flex-start'} flex={1}>
                    <Text fontSize={['lg', 'xl']} fontWeight={'500'}>
                        <chakra.span color='teal.400' fontWeight={'bold'} mr={1.5}>
                            Sendmea
                        </chakra.span>
                        simple and best platform to get video reviews fast, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand.
                        Video reviews are the most trusted and valuable of all reviews, and build consumer trust and confidence.
                        Let Sendmea help your happy customers increase your market share, improve referrals, and gain greater industry credibility.
                    </Text>
                </VStack>
                <Box flex={1}>
                    <chakra.img
                        src={require('../../assets/images/sendmea-home.png')}
                        sx={{
                            height: 'auto', maxWidth: '100%', verticalAlign: 'middle', display: 'block'
                        }}
                    />
                </Box>
            </Flex>
            <Divider my={10} />
            <Flex
                gap={6} mt={10}
                flexDirection={['column', 'column', 'row']}
                mx={{ base: 4, md: 6 }}
                alignItems={'center'}
            >
                <Box flex={1}>
                    <chakra.img
                        src={require('../../assets/images/cm.png')}
                        sx={{
                            height: 'auto', maxWidth: '100%', verticalAlign: 'middle', display: 'block'
                        }}
                    />
                </Box>
                <VStack alignItems={'flex-start'} flex={1}>
                    <Text fontSize={['lg', 'xl']} fontWeight={'500'}>
                        <chakra.span color='#493AFF' fontWeight={'bold'} mr={1.5}>
                            Cred Management
                        </chakra.span>
                        is a secure and user-friendly web application that simplifies the process of sharing project credentials and collaborating with team members.
                        Managing multiple credentials across projects can be a daunting task, but not with cred management.
                    </Text>
                </VStack>

            </Flex>
        </Container>
    )
}

export default PreviewEmbed