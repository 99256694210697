import { Avatar, AvatarGroup, Flex, HStack, Stack, Text, Tooltip, VStack, chakra, useBreakpointValue } from '@chakra-ui/react'
import { collection, getDocs, limit, onSnapshot, orderBy, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Rating from '../../../components/Rating'
import { db } from '../../../firebase/config'
import { capitalizeSentence } from '../../../utils'


const CampaignTooltip = ({ campaign, text, children }) => {

    const {
        campaignDetails: { campaignLogo, publicCampaignName, internalCampaignName },
        avgRating,
        approvedReviews
    } = campaign

    return (
        <Tooltip
            hasArrow
            border='1px solid'
            borderColor='teal.300'
            bg='teal.600'
            borderRadius='md'
            placement={'top-start'}
            label={
                <HStack direction='row' spacing='10px' alignItems='center' p={[2.5]} pb={5} px={[1]} maxW='250px'>
                    <Avatar
                        name={publicCampaignName || internalCampaignName}
                        src={campaignLogo}
                        size={'md'}
                        border={(theme) => '1px solid' + theme.colors.gray['200']}
                        bg='brand.2'
                    />
                    <VStack spacing={'4px'} alignItems='flex-start'>
                        <Text fontSize={['15px']} fontWeight='bold' color='white' whiteSpace='nowrap'>
                            {capitalizeSentence(publicCampaignName || internalCampaignName)}
                        </Text>
                        <VStack spacing={'10px'} alignItems='flex-start'>
                            <HStack direction="row" spacing={"6px"} alignItems="center">
                                <Rating
                                    total={5}
                                    value={Math.round(avgRating) || 0}
                                    size={["14px"]}
                                    isStatic={true}
                                    showNumber={false}
                                />
                                <Text fontSize="14px" fontWeight='bold' lineHeight={0} color="brand.2" pb={1}>
                                    ({Number(avgRating).toFixed(avgRating ? 2 : 0)})
                                </Text>
                            </HStack>
                            <Text fontSize="14px" fontWeight='bold' lineHeight={0} color="brand.2">
                                {approvedReviews} Reviews
                            </Text>
                        </VStack>
                    </VStack>
                </HStack>
            }
        >
            {children}
        </Tooltip>
    )
}

const Marketing = ({ sx }) => {

    const size = useBreakpointValue({ base: '44px', md: '60px' })

    const [loading, setLoading] = useState(false)
    const [campaignsData, setCampaignsData] = useState(null)

    const getCampaigns = async () => {
        setLoading(true)
        try {
            const collRef = collection(db, 'campaigns')
            const totalCount = (await getDocs(collRef)).size
            const queryRef = query(collRef, orderBy('createdAt', 'desc'), limit(8))

            onSnapshot(queryRef, campaignSnap => {
                const campaigns = campaignSnap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    avgRating: doc.data()?.avgRating || 0,
                    approvedReviews: doc.data()?.approvedReviews || 0,
                }))
                setCampaignsData({ totalCount, campaigns })
                setLoading(false)
            })

        } catch (error) {
            console.log(error.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        getCampaigns()
    }, [])

    return (
        <Stack spacing={{ base: 10, md: 10 }} py={4} px={['2', '2', '4']} sx={{ ...sx }}>
            <Text textAlign={{ base: 'center', md: 'center', lg: 'left' }} fontStyle='normal'
                fontSize={['3xl', '4xl', '5xl', '6xl']} letterSpacing="0.005px"
                lineHeight={{ base: 'short', lg: '75px' }} fontWeight="extrabold" color='gray.700'
            >
                Business Owners & Marketers {' '}
                <chakra.span
                    bg='brand.1'
                    bgClip='text' ml={1}
                >
                    Just Like You Use
                </chakra.span>{'  '}
                <chakra.span
                    bg='brand.2'
                    bgClip='text' ml={1}>
                    Sendmea!
                </chakra.span>
            </Text>
            {(campaignsData && !loading) &&
                <VStack w='full' justifyContent={{ base: 'center', lg: 'flex-start' }} alignItems={{ base: 'center', lg: 'flex-start' }}>
                    <HStack mb={8} maxW={['600px']}>
                        <Text fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }} fontWeight='500' fontStyle='italic' color='gray.500' textAlign={{ base: 'center', lg: 'left' }}>
                            {campaignsData?.totalCount}+ Campaigns already created and getting reviews, register now and next could be yours
                        </Text>
                    </HStack>
                    <Stack direction={['column', 'column', 'row']} spacing={0} gap={[2, 2, 4]}
                        flexWrap='wrap' alignItems='center'>
                        <AvatarGroup max={8}>
                            {campaignsData?.campaigns.map((campaign, i) => (
                                <CampaignTooltip key={i} campaign={campaign}>
                                    <Avatar
                                        key={campaign?.id}
                                        name={campaign?.campaignDetails?.publicCampaignName}
                                        src={campaign?.campaignDetails?.campaignLogo}
                                        width={['40px', '50px', '55px']}
                                        height={['40px', '50px', '55px']}
                                        position={'relative'}
                                        zIndex={2}
                                        _before={{
                                            content: '""',
                                            width: 'full',
                                            height: 'full',
                                            rounded: 'full',
                                            transform: 'scale(1.125)',
                                            bgGradient: 'linear(to-bl, teal.300,teal.600)',
                                            position: 'absolute',
                                            zIndex: -1,
                                            top: 0,
                                            left: 0,
                                        }}
                                    />
                                </CampaignTooltip>

                            ))}
                        </AvatarGroup>
                        <Text fontFamily={'heading'} color='teal.500' fontSize={{ base: '4xl', md: '6xl' }}>
                            +
                        </Text>
                        <Flex
                            align={'center'}
                            justify={'center'}
                            fontFamily={'heading'}
                            fontWeight={'500'}
                            fontSize={{ base: 'sm', md: 'lg' }}
                            bg={'gray.700'}
                            color={'white'}
                            rounded={'full'}
                            width={size}
                            height={size}
                            position={'relative'}
                            _before={{
                                content: '""',
                                width: 'full',
                                height: 'full',
                                rounded: 'full',
                                transform: 'scale(1.125)',
                                bgGradient: 'linear(to-bl, teal.600,gray.300)',
                                position: 'absolute',
                                zIndex: -1,
                                top: 0,
                                left: 0,
                            }}
                        >
                            YOU
                        </Flex>
                    </Stack>
                </VStack>
            }
        </Stack>
    )
}

export default Marketing