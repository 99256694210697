import React from 'react'
import { useRecoilState } from 'recoil'

import { userState } from '../../recoil/atoms/auth'
import { AffiliateProgram } from './components/Affiliate'

function Affiliate() {
  const [user, _] = useRecoilState(userState)
  return (
    <>
      <AffiliateProgram user={user} />
    </>
  )
}

export default Affiliate
