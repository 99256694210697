import React from 'react'
import {
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    chakra,
    Box,
    useBreakpointValue,
    Skeleton,
    SkeletonText,
    Tooltip,
    IconButton,
    Avatar
} from '@chakra-ui/react'
import { ProfileModal } from '../ProfileModal/ProfileModal'
import Rating from '../../../../components/Rating'
import { ContactModal } from '../ContactModal/ContactModal'
import { handleOpenLink } from '../../../../utils'
import { BsGlobe2, BsCameraVideoFill } from 'react-icons/bs'


const ForestDarkTheme = ({ avgRating, loading, campaignData, wallTheme, disableIcon }) => {

    const { campaignDetails } = campaignData

    return (
        <Stack maxW={'1450px'} w='full' minH={'20vh'} px={['3', '6']} direction={{ base: 'column-reverse', md: 'column-reverse', lg: 'row' }} gap={{ base: '2rem', lg: '3rem' }} justify='space-around' align={'center'}>

            <Skeleton startColor='brand.1' endColor='gray.300' fadeDuration={1} isLoaded={!loading}>
                <Stack spacing={0} gap={1} py='2' align={'space-between'} direction={{ base: 'column' }} alignItems={['center', 'center', 'center', 'flex-start']}>
                    <Heading maxW={'600px'}>
                        <Text
                            color={wallTheme['title']}
                            fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: useBreakpointValue({ base: '15%', md: '20%' }),
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'brand.2',
                                zIndex: -1,
                            }}>
                            {campaignDetails?.publicCampaignName ||
                                campaignDetails?.testimonialCampaignName ||
                                '-'}
                        </Text>
                    </Heading>
                    <SkeletonText startColor={'brand.1'} endColor='gray.300' fadeDuration={1} isLoaded={!loading}>
                        <Text fontSize={{ base: 'md', md: 'lg', lg: 'xl' }} fontWeight={'500'} color={wallTheme['desc']} mt={3} maxW={['480px']} textAlign={['center', 'center', 'center', 'start']}>
                            {campaignDetails?.campaignDescription || '-'}
                        </Text>
                    </SkeletonText>
                    <HStack pt='4' direction='row' align='center' spacing={'10px'}>
                        <Rating
                            total={5}
                            value={Math.round(avgRating)}
                            size={['20px']}
                            isStatic={true}
                            showNumber={false}
                        />
                        <Text fontSize='20px' color='brand.1' pb={1} fontWeight={'600'}>
                            {!!campaignData?.reviews?.length ? campaignData?.totalTestimonials : 0} Review{(campaignData?.totalTestimonials === 0 || campaignData?.totalTestimonials > 1) && 's'}
                        </Text>
                    </HStack>
                    <Box px={2} py={1} bg={wallTheme['averageRatingBg']} maxW={'280px'} rounded={'lg'} my={'15px !important'}>
                        <Text fontSize='20px' color='white' fontWeight='bold' fontStyle={'italic'}>
                            <chakra.span color='white' fontWeight='medium'>
                                Average:
                            </chakra.span>{' '}
                            {Number(avgRating).toFixed(
                                avgRating ? 2 : 0
                            )}{' '}
                            out of 5 Stars
                        </Text>
                    </Box>
                    <HStack flexWrap="wrap" spacing={0} gap={[2, 3]} mt={5} maxW={['280px']}>
                        {!campaignData?.isExpressSetup &&
                            <ProfileModal campaignData={campaignData} disableIcon={disableIcon} />
                        }
                        <ContactModal campaignEmail={campaignData?.campaignDetails?.email} disableIcon={disableIcon} />
                        <Tooltip
                            label="Visit Website"
                            fontSize='md'
                            placement='bottom'
                            bg='teal.400'
                            rounded='md'
                            hasArrow
                            closeOnPointerDown
                        >
                            <IconButton
                                variant="solid"
                                icon={<BsGlobe2 fontSize="2rem" />}
                                aria-label="Open Menu"
                                color={'gray.50'}
                                bg={'#78bdc0'}
                                borderRadius="full"
                                onClick={() => !disableIcon && handleOpenLink(campaignDetails?.reviewWallUrl || '#')}
                                width="55px"
                                height="55px"
                            />
                        </Tooltip>
                        <Tooltip
                            label="Leave Me a Review"
                            fontSize='md'
                            placement='bottom'
                            bg='teal.400'
                            rounded='md'
                            hasArrow
                            closeOnPointerDown
                        >
                            <IconButton
                                variant="solid"
                                icon={<BsCameraVideoFill fontSize="2rem" />}
                                aria-label="Open Menu"
                                color={'gray.50'}
                                bg={'#78bdc0'}
                                borderRadius="full"
                                width="55px"
                                height="55px"
                                onClick={() => !disableIcon && handleOpenLink(`${window.location.origin}/review/${campaignData?.campaignId}` || '#')}
                            />
                        </Tooltip>
                    </HStack>
                </Stack>
            </Skeleton>
            <Stack height={'full'} alignItems={'center'} justifyContent={'center'}>
                <Box w='full' height={'full'} pos={'relative'}>
                    {campaignDetails?.campaignLogo ?
                        <Avatar
                            zIndex={3}
                            alt={'Login Image'}
                            objectFit={'cover'}
                            src={campaignDetails?.campaignLogo}
                            boxSize={['200px', '250px', '300px', '350px']}
                            borderRadius={'md'}
                        /> :
                        <Image
                            zIndex={3}
                            alt={'Login Image'}
                            objectFit={'contain'}
                            src={
                                'https://res.cloudinary.com/codeshock/image/upload/v1675817432/Untitled_design_2_ielr3m.png'
                            }
                            boxSize={['200px', '250px', '300px', '350px']}
                        />
                    }
                </Box>
            </Stack>
        </Stack>
    )
}

export default ForestDarkTheme