import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
    AspectRatio,
    Box,
    Button,
    chakra,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Img,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Select,
    Text,
    VStack,
} from '@chakra-ui/react'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useRef, useState } from 'react'
import { ChromePicker } from 'react-color'
import { useForm } from 'react-hook-form'
import { BsCamera } from 'react-icons/bs'
import { MdDownload } from 'react-icons/md'
import { db } from '../firebase/config'
import { useCustomToast } from '../hooks/customToast'
import { blobToBase64, cloudfunctionsBaseURL, convertToMb, errorToast } from '../utils'
import { RangeSliderMarkExample } from './RangeSlider'

const initialInputs = {
    upperText: '', upperTextColor: '#FFFFFF', upperTextSize: 'md',
    lowerText: '', lowerTextColor: "#FFFFFF", lowerTextSize: 'md',
    logo: '', logoPosition: 't_l', start_offset: 0, end_offset: 100
}
const positionConstants = {
    t_l: {
        top: '10px', left: '10px'
    },
    t_r: {
        top: '10px', right: '10px'
    },
    b_l: {
        bottom: '10px', left: '10px'
    },
    b_r: {
        bottom: '10px', right: '10px'
    },
}

const fontSizes = {
    xs: '13px',
    sm: '16px',
    md: '18px',
    lg: '28px',
}
const EditVideoModal = ({
    isOpen,
    onClose,
    reviewDetails
}) => {
    const { addToast } = useCustomToast()
    const [verticalReview, setVerticalReview] = useState(reviewDetails?.review)
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [prevLoading, setPreLoading] = useState(false)
    const [popoverState, setPopoverState] = useState({ top: false, bottom: false })
    const uploadInputRef = useRef(null)
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ...initialInputs,
        },
    })
    const [colors, setColors] = useState({ color1: watch('upperTextColor'), color2: watch('lowerTextColor') })

    const handleImage = (e) => {
        const fileObj = e.target.files[0]
        let sizeInMB = convertToMb(fileObj)
        if (!fileObj) return
        if (!fileObj.type.includes('image')) {
            return addToast({
                title: 'Image',
                description: 'Can only upload images',
                status: 'error',
                variant: 'left-accent',
            })
        } else if (sizeInMB > 5) {
            addToast({
                title: 'Video',
                description: 'Size is greater than 5mb',
                status: 'error',
                variant: 'left-accent',
            })
        } else {
            setValue('logo', fileObj)
        }
    }

    const handlePreview = async (values) => {
        try {
            if (!values.lowerText && !values.upperText && !values.logo) {
                return setError('logo', {
                    type: "custom",
                    message: 'Atleast 1 field is required!'
                })
            }
            setLoading(true)
            const base64Image = !values.logo ? false : await blobToBase64(values.logo)
            const res = await fetch(`${cloudfunctionsBaseURL}handleEditVideo`, {
                // const res = await fetch('http://127.0.0.1:5001/sendmea-c7d45/us-central1/handleEditVideo', {
                method: 'POST',
                body: JSON.stringify({
                    url: 'sendmea/reviews/' + reviewDetails.id,
                    logo: base64Image,
                    id: reviewDetails.id,
                    upperText: values.upperText,
                    lowerText: values.lowerText,
                    logoPosition: values.logoPosition,
                    start_offset: values.start_offset,
                    end_offset: values.end_offset,
                    color1: values.upperTextColor,
                    color2: values.lowerTextColor,
                    fontSize1: values.upperTextSize,
                    fontSize2: values.lowerTextSize,
                }),
            })
            const response = await res.json()
            const resp = await fetch(response.data)
            const blob = await resp.blob()
            let anchor = document.createElement('a')
            anchor.href = URL.createObjectURL(blob)
            anchor.download = `review ${reviewDetails?.name || reviewDetails?.email}.mp4`
            anchor.click()
            anchor.remove()
            setLoading(false)
            const docRef = doc(db, 'reviews', reviewDetails.id)
            await updateDoc(docRef, { editedReview: response.data })
        } catch (error) {
            setLoading(false)
            console.log(error)
            errorToast(error, addToast)
        }
    }

    return (
        <Modal isCentered isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose} scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent m={0} maxW={['95%', null, '70%']} minHeight={'98vh'}>
                <ModalHeader pt={2} pb={2}>
                    <Text
                        fontSize={['20px', '24px']}
                        fontWeight='600'
                        lineHeight={'38px'}
                        color='#474747'
                        mt={[2]}
                    >
                        Edit <chakra.span color='brand.1'>Video</chakra.span>
                    </Text>
                </ModalHeader>
                <ModalCloseButton
                    bg={{ base: 'blackAlpha.300', sm: 'whiteAlpha.500' }}
                    color={{ base: 'white', sm: 'black' }}
                    stroke={'currentColor'}
                    mt={1}
                />
                <ModalBody as={VStack} alignItems='flex-start' >
                    <Box display={'flex'}
                        flexDirection={['column-reverse', null, 'row']}
                        justifyContent={'center'}
                        alignItems={'flex-start'} gap={5}
                        w={'100%'}
                    >
                        <Box>
                            <Box position={'relative'}>
                                {!!watch('logo') && <Img src={typeof watch('logo') === 'object'
                                    ? URL.createObjectURL(watch('logo'))
                                    : watch('logo') || ''} alt={'sendmea logo'}
                                    sx={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        width: '64px',
                                        ...positionConstants[watch('logoPosition')]
                                    }}
                                />}
                                <Text sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '5px',
                                    right: 0,
                                    left: 0,
                                    mx: 'auto',
                                    textAlign: 'center',
                                    fontSize: fontSizes[watch('upperTextSize')],
                                    color: colors.color1,
                                    px: 2
                                }}>{watch('upperText')}</Text>
                                <Text sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    bottom: '10px',
                                    right: 0,
                                    left: 0,
                                    mx: 'auto',
                                    textAlign: 'center',
                                    fontSize: fontSizes[watch('lowerTextSize')],
                                    color: colors.color2,
                                    px: 2
                                }}>{watch('lowerText')}</Text>
                                <AspectRatio ratio={9 / 16} w={['72']} sx={{
                                    'video': {
                                        objectFit: 'contain',
                                        backgroundColor: 'black'
                                    }
                                }} >
                                    <Img as={'video'}
                                        src={reviewDetails?.review?.split('#t=')?.[0] + `#t=${(watch('start_offset') * reviewDetails.duration / 100).toFixed(2)},${(watch('end_offset') * reviewDetails.duration / 100).toFixed(2)}`}
                                        controls
                                    />
                                </AspectRatio>
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} >
                                <RangeSliderMarkExample start={watch('start_offset')} end={watch('end_offset')} setValue={setValue} />
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Text>Start: {(watch('start_offset') * reviewDetails.duration / 100 / 60).toFixed(2)}</Text>
                                    <Text>End: {(watch('end_offset') * reviewDetails.duration / 100 / 60).toFixed(2)}</Text>
                                </Box>
                                <Button variant={'solid'} size={'sm'} mt={1} onClick={() => {
                                    setValue('end_offset', 100)
                                    setValue('start_offset', 0)
                                }}>Reset</Button>
                            </Box>
                        </Box>
                        <Box as={'form'} sx={{
                            'input, select, textarea': {
                                color: 'gray.700',
                            },
                            '&>div': { mb: 6 },
                            width: { md: '50%', xs: '100%' }
                        }}
                            onSubmit={handleSubmit(handlePreview)}
                        >
                            {reviewDetails?.editedReview && <FormControl>
                                <Button variant={'outline'} size={'xs'} leftIcon={<Icon as={MdDownload} />}
                                    onClick={async () => {
                                        setPreLoading(true)
                                        const resp = await fetch(reviewDetails?.editedReview)
                                        const blob = await resp.blob()
                                        let anchor = document.createElement('a')
                                        anchor.href = URL.createObjectURL(blob)
                                        anchor.download = `review ${reviewDetails?.name || reviewDetails?.email}.mp4`
                                        anchor.click()
                                        anchor.remove()
                                        setPreLoading(false)
                                    }} isLoading={prevLoading}>Last Edit.</Button>
                                <FormHelperText>Note: if you edit now new video will replace the previous one.</FormHelperText>
                            </FormControl>}

                            <FormControl
                                isInvalid={!!errors?.upperText}
                                w={'100%'}
                            >
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                    Text on top of the Video
                                </FormLabel>
                                <HStack>
                                    <Input
                                        size={['sm', null, 'md']}
                                        placeholder='Upper Text'
                                        bg={'gray.100'}
                                        border={0}
                                        color={'gray.500'}
                                        _placeholder={{
                                            color: 'gray.500',
                                        }}
                                        {...register('upperText', {
                                            maxLength: { value: 50, message: 'Maximum length should be 50' },
                                            required: (!watch('lowerText') && !watch('logo')) && "Atleast 1 field is required!"
                                        })}
                                    />
                                    <Popover isOpen={popoverState.top}>
                                        <PopoverTrigger>
                                            <chakra.span onClick={() => setPopoverState(pre => ({ ...pre, top: true }))} w={'4'} h={'4'}
                                                sx={{
                                                    backgroundColor: watch('upperTextColor'),
                                                    border: '0.5px solid black',
                                                    boxShadow: 'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px'
                                                }} borderRadius={'4px'} />
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <IconButton sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: '30px',
                                                zIndex: 10,
                                            }}
                                                size={'sm'}
                                                onClick={() => {
                                                    setColors(prev => ({ ...prev, color1: watch('upperTextColor') }))
                                                    setPopoverState(pre => ({ ...pre, top: false }))
                                                }}
                                            >
                                                <Icon as={CloseIcon} />
                                            </IconButton>
                                            <IconButton sx={{
                                                position: 'absolute',
                                                top: '35px',
                                                right: '30px',
                                                zIndex: 10,
                                            }}
                                                size={'sm'}
                                                onClick={() => {
                                                    setValue('upperTextColor', colors.color1)
                                                    setPopoverState(pre => ({ ...pre, top: false }))
                                                }}
                                            >
                                                <Icon as={CheckIcon} />
                                            </IconButton>
                                            <ChromePicker
                                                width={'80%'}
                                                color={colors.color1}
                                                onChange={(color) => {
                                                    setColors(pre => ({ ...pre, color1: color.hex }))
                                                }}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </HStack>
                                <Select
                                    size={['xs', null, 'sm']}
                                    w={'36'}
                                    my={1}
                                    bg={'gray.100'}
                                    border={0}
                                    color={'gray.500'}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                    {...register('upperTextSize')}
                                >
                                    <option value='xs'>Extra small</option>
                                    <option value='sm'>Small</option>
                                    <option value='md'>Medium</option>
                                    <option value='lg'>Large</option>
                                </Select>
                                {errors.upperText && (
                                    <FormErrorMessage>{errors.upperText.message}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl
                                isInvalid={!!errors?.lowerText}
                                w={'100%'}
                            >
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                    Text on bottom of the Video
                                </FormLabel>
                                <HStack>
                                    <Input
                                        size={['sm', null, 'md']}
                                        placeholder='Bottom Text'
                                        bg={'gray.100'}
                                        border={0}
                                        color={'gray.500'}
                                        _placeholder={{
                                            color: 'gray.500',
                                        }}
                                        {...register('lowerText', {
                                            maxLength: { value: 50, message: 'Maximum length should be 50' },
                                            required: (!watch('upperText') && !watch('logo')) && "Atleast 1 field is required!"
                                        })}
                                    />
                                    <Popover isOpen={popoverState.bottom}>
                                        <PopoverTrigger>
                                            <chakra.span onClick={() => setPopoverState(pre => ({ ...pre, bottom: true }))} w={'4'} h={'4'}
                                                sx={{
                                                    backgroundColor: watch('lowerTextColor'),
                                                    border: '0.5px solid black',
                                                    boxShadow: 'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px'
                                                }} borderRadius={'4px'} />
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <IconButton sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: '30px',
                                                zIndex: 10,
                                            }}
                                                size={'sm'}
                                                onClick={() => {
                                                    setColors(prev => ({ ...prev, color2: watch('lowerTextColor') }))
                                                    setPopoverState(pre => ({ ...pre, bottom: false }))
                                                }}
                                            >
                                                <Icon as={CloseIcon} />
                                            </IconButton>
                                            <IconButton sx={{
                                                position: 'absolute',
                                                top: '35px',
                                                right: '30px',
                                                zIndex: 10,
                                            }}
                                                size={'sm'}
                                                onClick={() => {
                                                    setValue('lowerTextColor', colors.color2)
                                                    setPopoverState(pre => ({ ...pre, bottom: false }))
                                                }}
                                            >
                                                <Icon as={CheckIcon} />
                                            </IconButton>
                                            <ChromePicker
                                                width={'80%'}
                                                color={colors.color2}
                                                onChange={(color) => {
                                                    setColors(pre => ({ ...pre, color2: color.hex }))
                                                }}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </HStack>
                                <Select
                                    size={['xs', null, 'sm']}
                                    w={'36'}
                                    my={1}
                                    bg={'gray.100'}
                                    border={0}
                                    color={'gray.500'}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                    {...register('lowerTextSize')}
                                >
                                    <option value='xs'>Extra small</option>
                                    <option value='sm'>Small</option>
                                    <option value='md'>Medium</option>
                                    <option value='lg'>Large</option>
                                </Select>
                                {errors.lowerText && (
                                    <FormErrorMessage>{errors.lowerText.message}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl
                                isInvalid={!!errors?.logo}
                                w={'100%'}
                                textAlign='center'
                                justifySelf={['center', 'center', 'stretch']}
                            >

                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                    Upload a logo
                                </FormLabel>
                                <Box
                                    width={['110px', '95px', '110px']}
                                    minH='100px'
                                    maxH={['110px', '120px']}
                                    display='flex'
                                    justifyContent={['center', 'center', 'flex-start']}
                                    alignItems='center'
                                    sx={{ position: 'relative' }}
                                    mb={[4, 0]}
                                    border='2px solid'
                                    borderColor='gray.300'
                                    bg={'rgb(0 0 0 / 5%)'}
                                    boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 4px 0px'
                                    zIndex={1}
                                    overflow='hidden'
                                    _hover={{
                                        '& > .upload': {
                                            display: 'flex',
                                            zIndex: 2,
                                            opacity: 1,
                                            transform: 'scale(1.1)'
                                        },
                                    }}
                                >
                                    {watch('logo') && (
                                        <chakra.img
                                            size='full'
                                            name='Kola Tioluwani'
                                            rounded={'none'}
                                            borderRadius='none'
                                            src={
                                                typeof watch('logo') === 'object'
                                                    ? URL.createObjectURL(watch('logo'))
                                                    : watch('logo')
                                            }
                                            width='100%'
                                            maxH={['100px', '120px']}
                                            objectFit='cover'
                                        />
                                    )}
                                    <Box
                                        className='upload'
                                        sx={{
                                            zIndex: -1,
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: 'rgb(0 0 0 / 30%)',
                                            overflow: 'hidden',
                                            width: 'full',
                                            height: 'full',
                                            transition: 'all 0.4s ease',
                                            opacity: 0.5,
                                        }}
                                    >
                                        {!(progress > 0 && progress < 100) ? (
                                            <Button
                                                color='white'
                                                variant='solid'
                                                size='small'
                                                sx={{ fontSize: '16px', display: 'flex', alignItems: 'center', gap: '5px' }}
                                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                                p={1.5}
                                                mx={4}
                                                w='90%'
                                                fontWeight='normal'
                                            >
                                                <BsCamera /> <Text fontSize={'12px'}>Upload</Text>
                                                <input
                                                    ref={uploadInputRef}
                                                    type='file'
                                                    accept='image/*'
                                                    style={{ display: 'none' }}
                                                    onChange={handleImage}
                                                />
                                            </Button>
                                        ) : (
                                            <CircularProgress value={progress} color='teal.400' />
                                        )}
                                    </Box>
                                </Box>
                            </FormControl>
                            <FormControl
                                isInvalid={!!errors?.logoPosition}
                                colSpan={[12, 9, 10]}
                                order={3}
                            >

                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                    Logo Position
                                </FormLabel>
                                <Select
                                    size={['sm', null, 'md']}
                                    placeholder='Logo Position'
                                    bg={'gray.100'}
                                    border={0}
                                    color={'gray.500'}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                    {...register('logoPosition')}
                                >
                                    <option value='t_l'>Top left</option>
                                    <option value='t_r'>Top right</option>
                                    <option value='b_l'>Bottom left</option>
                                    <option value='b_r'>Bottom right</option>
                                </Select>
                                {errors.logoPosition && (
                                    <FormErrorMessage>{errors.logoPosition.message}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl gap={2} >
                                {/* <Button variant={'solid'} type='submit'>Preview</Button> */}
                                <HStack justify={'center'}>
                                    <Button variant={'solid'} type='submit' leftIcon={<Icon as={MdDownload} />} isLoading={loading}>Download</Button>
                                </HStack>

                                {loading && <FormHelperText>Note: Be patient exporting a video can take some time depending on the system you are using. Thank you</FormHelperText>}

                            </FormControl>
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default EditVideoModal
