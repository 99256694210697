import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack } from "@chakra-ui/react"

export const WaitModal = ({ isOpen, onOpen, onClose }) => {

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'teal.50'}>
                    <ModalHeader></ModalHeader>
                    <ModalBody>
                        <Text fontSize={'lg'} textAlign={'center'}>

                            Thank you for submitting your video review, depending on internet speed it can take a up to 60 seconds to render and upload. This will close when it is done.
                        </Text>
                    </ModalBody>
                    <VStack alignItems={'center'} m={3}>
                        <Spinner color={'brand.1'} size={'lg'} />
                    </VStack>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}