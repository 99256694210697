import { chakra, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialVideoCard from "./TestimonialCard";
import { videoData } from "./VideoData";
import VideoPopup from "./VideoPopup";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1
  }
};

const VideoCarousel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [cardInfoState, setCardInfo] = useState(null)
  return (
    <chakra.div px={2} py={6} className="parent">
      <Text
        fontSize={['1.85rem', '2rem']}
        fontWeight={'600'}
        my={'4'}
        textAlign={'center'}
        pt={'6'}
      >
        <chakra.span color={'brand.1'}>Check out the reviews Send</chakra.span>
        <chakra.span color={'brand.2'}>mea </chakra.span>
        <chakra.span color={'brand.1'}>clients are recieving!</chakra.span>
      </Text>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {videoData.map((video, index) => {
          return (
            <chakra.div px={4} py={6} className="slider" key={index}>
              <TestimonialVideoCard
                cardInfo={{
                  ...video,
                  campaignName: video?.campaignDetails?.publicCampaignName,
                }}

                onOpen={onOpen}
                setCardInfo={setCardInfo}
                video={video}
              />
            </chakra.div>
          );
        })}
      </Carousel>
      <VideoPopup
        isTestimonialWall
        isOpen={isOpen}
        onClose={(e) => {
          onClose(e)
          setCardInfo(null)
        }}
        cardInfo={cardInfoState}
      />
    </chakra.div>
  );
};
export default VideoCarousel;
