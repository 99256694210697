import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  chakra,
  useDisclosure
} from '@chakra-ui/react'
import { updateEmail, updatePassword } from 'firebase/auth'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsCamera } from 'react-icons/bs'
import { useRecoilState } from 'recoil'
import { auth, db, storage } from '../../firebase/config'
import { userState } from '../../recoil/atoms/auth'
import { ConfirmationPrompt, cloudfunctionsBaseURL, convertToMb, sendNotification } from '../../utils'
import { EditIcon } from '@chakra-ui/icons'
import { useCustomToast } from '../../hooks/customToast'

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  photoURL: '',
  uid: '',
}

const Profile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { addToast } = useCustomToast()
  const [user, setUser] = useRecoilState(userState)
  const [progress, setProgress] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialValues, ...user },
  })

  useEffect(() => {
    reset({ ...initialValues, ...user })
  }, [user])

  const uploadToFirestore = async (values, photoURL, password) => {
    const docRef = doc(db, 'users', user.uid)
    const userSnap = await getDoc(docRef)
    userSnap.exists() && (await updateDoc(docRef, { ...values, photoURL }))
    setUser((prev) => ({ ...prev, ...values, photoURL }))
    if (password) {
      updatePassword(auth.currentUser, password)
        .then(() => {
          addToast({
            title: 'Password',
            description: 'Password updated too',
            status: 'success',
            variant: 'left-accent',
          })
          reset({ password: '', confirmPass: '' })
        })
        .catch((error) => {
          addToast({
            title: 'Error',
            description: error.message,
            status: 'error',
            variant: 'left-accent',
          })
        })
    }
    addToast({
      title: 'Profile',
      description: 'Updated Successfully',
      status: 'success',
      variant: 'left-accent',
    })
    setIsEditable(false)
    setIsSubmitting(false)
  }

  const onSubmit = async (state) => {
    setIsSubmitting(true)
    let { uid, password, confirmPass, email, photoURL, ...values } = state
    try {
      if (typeof photoURL === 'object') {
        uploadFile(photoURL, (e) => uploadToFirestore(values, e, password))
      } else {
        uploadToFirestore(values, photoURL, password)
      }
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setIsSubmitting(false)
    }
  }

  const handleImage = (e) => {
    const fileObj = e.target.files[0]
    let sizeInMB = convertToMb(fileObj)
    if (!fileObj) return
    if (!fileObj.type.includes('image')) {
      return addToast({
        title: 'Image',
        description: 'Can only upload images',
        status: 'error',
        variant: 'left-accent',
      })
    } else if (sizeInMB > 5) {
      addToast({
        title: 'Video',
        description: 'Size is greater than 5mb',
        status: 'error',
        variant: 'left-accent',
      })
    } else {
      setValue('photoURL', fileObj)
    }
  }

  const uploadInputRef = useRef(null)

  const uploadFile = (file, callback = () => { }) => {
    if (!file) return
    const storageRef = ref(storage, `profile_pics/${user.uid}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        setProgress(progress)
      },
      (error) => {
        addToast({
          title: 'Error',
          description: error.message,
          status: 'error',
          variant: 'left-accent',
        })
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setValue('photoURL', downloadURL)
          callback(downloadURL)
        })
      }
    )
  }

  function ChangeEmailModal({ isOpen, onOpen, onClose }) {
    const [changedEmail, setChangeEmail] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errors, setErrors] = useState(null)
    const { isOpen: confirmationIsOpen, onOpen: confirmationOnOpen, onClose: confirmationOnClose } = useDisclosure()
    const handleChangeEmail = e => {
      setChangeEmail(e.target.value)
    }

    const handleSubmit = async _ => {
      setErrors(null)
      setIsSubmitting(true)
      try {
        const docRef = doc(db, 'users', user.uid)
        const resp = await updateEmail(auth?.currentUser, changedEmail)

        await updateDoc(docRef, { email: changedEmail })
        const options = {
          method: 'POST',
          body: JSON.stringify({
            cust_id: user.uid,
            email: changedEmail
          }),
        }
        const url = cloudfunctionsBaseURL + 'modifyPromoter'
        const promoterUpdateRes = await fetch(url, options)
        const result = await promoterUpdateRes.json()
        setUser({ ...user, email: changedEmail })
        addToast({
          title: 'Profile',
          description: 'Your Email is changed!',
          status: 'success',
          variant: 'left-accent',
        })
        const notiPayload = {
          title: `Email changed`,
          message: `You changed your email from ${user?.email} to ${changedEmail}, so your business details email needs to be updated too if you don't want to use the old email for business since the review wall contact is associated to the email in the business details.`,
          sender_id: user?.uid,
          receiver_id: user?.uid,
        }
        sendNotification(notiPayload)
        setIsSubmitting(false)
        onClose()
        confirmationOnClose()
      } catch (error) {
        addToast({
          title: 'Error',
          description: error.message,
          status: 'error',
          variant: 'left-accent',
        })
        confirmationOnClose()
        setErrors(error.message)
        console.log(error)
        setIsSubmitting(false)
      }
    }

    return (
      <>
        <ConfirmationPrompt isCentered={true} isOpen={confirmationIsOpen} onClose={confirmationOnClose} message={'Are you sure!'}
          accept={() => {
            confirmationOnClose()
            handleSubmit()
          }}
        />
        <Button variant='solid'
          onClick={onOpen}
          bg={!isEditable ? 'gray' : 'teal'}
          disabled={!isEditable}
        >
          <Icon as={EditIcon} color="brand.2" fontSize={'2xl'} />
        </Button>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change your Email Address:</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={!!errors}>
                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                  Email
                </FormLabel>
                <Input
                  name="changeEmail"
                  type='email'
                  placeholder='Input your Email'
                  required
                  value={changedEmail}
                  onChange={handleChangeEmail}
                />
                {errors && <FormErrorMessage>{errors}</FormErrorMessage>}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose} type='button'>
                Close
              </Button>
              <Button variant='solid' type='button' onClick={confirmationOnOpen} disabled={!changedEmail} isLoading={isSubmitting}>Submit</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <Container maxW={['container.lg']} px={{ base: 0, sm: 2 }}>
      <Stack
        mt={10}
        bg={'gray.50'}
        rounded={'xl'}
        py={{ base: 4, sm: 6, md: 8 }}
        px={{ base: 2, sm: 4, md: 8 }}
        width='full'
        spacing={8}
      >
        <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
          Profile
        </Heading>
        <SimpleGrid
          as={'form'}
          mt={10}
          onSubmit={handleSubmit(onSubmit)}
          columns={12}
          spacing={[2, 3, 4]}
          sx={{
            'input, select, textarea': {
              color: 'gray.700',
            },
          }}
          autoComplete='off'
        >
          <FormControl
            isInvalid={!!errors?.firstName}
            as={GridItem}
            colSpan={[12, 12, 5]}
            order={[2, 2, 1]}
          >
            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
              First Name
            </FormLabel>
            <Input
              size={['sm', 'md', 'lg']}
              placeholder='First Name'
              bg={'gray.100'}
              disabled={!isEditable}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              {...register('firstName', {
                required: 'This is required',
              })}
            />
            {errors.firstName && <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>}
          </FormControl>

          <GridItem
            colSpan={[12, 12, 2]}
            order={[1, 1, 3]}
            rowSpan={[1, 1, 2]}
            textAlign='center'
            justifySelf={['center', 'center', 'stretch']}
          >
            <Box
              width={['110px', '95px', '110px']}
              minH='100px'
              maxH={['110px', '120px']}
              display='flex'
              justifyContent={['center', 'center', 'flex-start']}
              alignItems='center'
              sx={{ position: 'relative' }}
              mb={[4, 0]}
              border='2px solid'
              borderColor='gray.300'
              bg={'rgb(0 0 0 / 5%)'}
              boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 4px 0px'
              zIndex={1}
              overflow='hidden'
              _hover={{
                '& > .upload > button': {
                  display: isEditable ? 'flex' : 'none',
                  zIndex: 2,
                  opacity: 1,
                },
              }}
            >

              <Box
                className='upload'
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'rgb(0 0 0 / 30%)',
                  overflow: 'hidden',
                  width: 'full',
                  height: 'full',
                  transition: 'all 0.4s ease',
                  zIndex: 2,
                  opacity: 1,
                }}
              >
                {watch('photoURL') ? (
                  <chakra.img
                    size='full'
                    name='Kola Tioluwani'
                    rounded={'none'}
                    borderRadius='none'
                    src={
                      typeof watch('photoURL') === 'object'
                        ? URL.createObjectURL(watch('photoURL'))
                        : watch('photoURL')
                    }
                    width='100%'
                    maxH={['100px', '120px']}
                    objectFit='cover'
                    pos={'absolute'}
                  />
                ) : (
                  <Text w='full' fontSize={['1.5rem', '2rem', '2.5rem']} textAlign='center'>
                    {user.firstName.charAt(0) + ' ' + user.lastName.charAt(0)}
                  </Text>
                )}
                {!(progress > 0 && progress < 100) ? (
                  <Button
                    color='white'
                    variant='solid'
                    size='small'
                    sx={{ fontSize: '16px', display: 'flex', alignItems: 'center', gap: '5px', zIndex: -1, opacity: 0 }}
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    p={1.5}
                    mx={4}
                    w='90%'
                    fontWeight='normal'
                  >
                    <BsCamera /> <Text fontSize={'12px'}>Upload</Text>
                    <input
                      ref={uploadInputRef}
                      type='file'
                      accept='image/*'
                      style={{ display: 'none' }}
                      onChange={handleImage}
                    />
                  </Button>
                ) : (
                  <CircularProgress value={progress} color='teal.400' />
                )}
              </Box>
            </Box>
            <Text fontSize='sm' fontStyle={'italic'} color='gray.400' mt={1.5}>
              Upload
            </Text>
          </GridItem>
          <FormControl
            isInvalid={!!errors?.lastName}
            as={GridItem}
            colSpan={[12, 12, 5]}
            order={[3, 3, 2]}
          >
            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
              Last Name
            </FormLabel>
            <Input
              size={['sm', 'md', 'lg']}
              placeholder='Last Name'
              bg={'gray.100'}
              disabled={!isEditable}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              {...register('lastName')}
            />
            {errors.lastName && <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={!!errors?.email} as={GridItem} colSpan={[12, 12, 10]} order={5}>
            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
              Email
            </FormLabel>
            <InputGroup variant="custom">

              <Input
                type='email'
                size={['sm', 'md', 'lg']}
                placeholder='Email'
                disabled
                bg={'gray.200'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                {...register('email', {
                  required: 'This is required',
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: 'Invalid email address',
                  },
                })}
                end
              />
              <InputRightElement pointerEvents={'auto'} h={'100%'}>
                <ChangeEmailModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
              </InputRightElement>
            </InputGroup>
            {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors?.password} as={GridItem} colSpan={[12, 6]} order={6}>
            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
              Password
            </FormLabel>
            <Input
              size={['sm', 'md', 'lg']}
              placeholder='Password'
              bg={'gray.100'}
              disabled={!isEditable}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              type={'password'}
              autoComplete='new-password'
              {...register('password', {
                minLength: { value: 6, message: 'Minimum length should be 6' },
              })}
            />
            {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors?.confirmPass} as={GridItem} colSpan={[12, 6]} order={6}>
            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
              Confirm Password
            </FormLabel>
            <Input
              size={['sm', 'md', 'lg']}
              placeholder='Confirm Password'
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              type={'password'}
              {...register('confirmPass', {
                required: getValues('password') && 'This is required',
                validate: (val) => {
                  if (getValues('password') !== val) {
                    return 'Your passwords do no match'
                  }
                },
              })}
            />
            {errors.confirmPass && (
              <FormErrorMessage>{errors.confirmPass.message}</FormErrorMessage>
            )}
          </FormControl>
          <GridItem colSpan={[12]} order={14}>
            {!user ? (
              <Button
                type='submit'
                disabled={isSubmitting}
                mt={3}
                leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
                variant='solid'
                ml='auto'
              >
                Save
              </Button>
            ) : (
              <>
                {' '}
                {!isEditable ? (
                  <Box
                    type='button'
                    as='button'
                    name='edit'
                    mt={3}
                    w={'20%'}
                    borderRadius='8'
                    py='2'
                    bg='brand.1'
                    variant='solid'
                    ml='auto'
                    color='white'
                    fontWeight={'bold'}
                    onClick={() => setIsEditable(true)}
                  >
                    Edit
                  </Box>
                ) : (
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    mt={3}
                    leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
                    variant='solid'
                    ml='auto'
                  >
                    Update
                  </Button>
                )}
              </>
            )}
          </GridItem>
        </SimpleGrid>
      </Stack>
    </Container>
  )
}

export default Profile
