import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, chakra } from "@chakra-ui/react";
import { standardBrowserList } from "../../../utils";
import { LockIcon } from "@chakra-ui/icons";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { browserName, isMacOs, isSafari } from 'react-device-detect';
import { useCustomToast } from "../../../hooks/customToast";

export default function HelpRecorderModal({ isOpen, onClose }) {

    const { addToast } = useCustomToast()

    return (<Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        scrollBehavior='inside'
    >
        <ModalOverlay />
        <ModalContent mx={['2']}>
            <ModalHeader pb='2'>
                <Text fontSize={['20px']} color='brand.2'>
                    <chakra.span color='teal.500' fontWeight='bold' fontSize='20px'>
                        How to fix this
                    </chakra.span>{' '}
                    issue?
                </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody minHeight={['100px']}>
                This means your permissions are currently not allowing camera and microphone to be used.
                You will need to grant privileges.{' '}
                {(isMacOs && isSafari) ? (
                    <chakra.ol pl={4}>
                        {/* <chakra.li>

                        </chakra.li> */}
                        <Text as='li'>
                            <chakra.span mx={1} cursor={'pointer'} color='teal.400' onClick={() => window.location.reload()}>Click Here</chakra.span>
                            for hard refresh
                        </Text>
                        <Text as='li'>
                            If hard refresh does not work,
                            <chakra.span mx={1} cursor={'pointer'} color='teal.400' onClick={() => {
                                navigator.clipboard.writeText(window.location.href)
                                addToast({
                                    id: 'review',
                                    title: 'Copied!',
                                    description: 'Review url copied',
                                    status: 'success',
                                    variant: 'left-accent',
                                })
                            }}>
                                Copy Link
                            </chakra.span>
                            and try Chrome or Firefox
                        </Text>
                        <Text as='li'>
                            <chakra.span mx={1} cursor={'pointer'} color='teal.400' onClick={() => window.open('https://www.awesomescreenshot.com/video/23234780?key=b9c2085ff9009c8fd47e5ab439804b51', '_blank')}>Click Here</chakra.span>
                            to watch the video for how to change settings:
                        </Text>
                    </chakra.ol>
                ) :
                    standardBrowserList?.includes(browserName) ? (
                        <p>
                            {' '}
                            Click the icon on the left side of the URL
                            <LockIcon mx={1} fontSize={'15px'} display={'inline-block'} /> and you should be
                            able to grant permission.
                        </p>
                    ) : (
                        <p>
                            {' '}
                            if you're using built in browser try clicking the
                            <BiDotsHorizontalRounded
                                style={{ marginInline: '2px', display: 'inline-block' }}
                            />{' '}
                            icon in right corner {'>'} <b>open system browser or open with etc</b> if you can't
                            find option like that copy the link and open paste it in different browser.
                        </p>
                    )
                }
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </ModalContent>
    </Modal>)
}