import { Button, chakra, FormControl, FormErrorMessage, FormLabel, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Text, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { inputProps } from '../../../pages/OldReviewProcess/components/step3'
import { sendNotification, teamEmailIds } from '../../../utils'
import { userState } from '../../../recoil/atoms/auth'
import { useRecoilValue } from 'recoil'
import emailjs from '@emailjs/browser'
import { useCustomToast } from '../../../hooks/customToast'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import {
    isBrowser, isMobile, browserName,
    deviceType, engineName, engineVersion, osName, osVersion,
} from 'react-device-detect';



const ContactSupportModal = ({
    onClose,
    isOpen,
}) => {

    const [isSubmitting, setIsSubmitting] = useState(false)
    const user = useRecoilValue(userState)
    const { addToast } = useCustomToast()


    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: user?.firstName + " " + (user?.lastName || ""),
            topic: ''
        },
    })

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true)
            const resip = await fetch('https://api.ipify.org/?format=json')
            const { ip } = await resip.json()
            teamEmailIds.forEach(teamEmail => {
                const notificationPayload = {
                    title: `Contact Support`,
                    message: `
                    <b>${values?.name} (${user?.email})</b> has asked for support on
                    <b>${values?.topic}</b> with a message</br> 
                    <b>"${values?.message}"</b></br> 
                    <b>Browser: ${browserName}</b></br> 
                    <b>OS-Version: ${osName}-(${osVersion})</b></br> 
                    <b>Device: ${deviceType}</b></br> 
                    <b>IP: ${ip}</b>
                    `,
                    sender_id: user.uid,
                    receiver_id: teamEmail,
                }
                sendNotification(notificationPayload)
            })
            const templateParams = {
                to_email: 'team@sendmea.io',
                from_name: values?.name,
                from_email: user?.email,
                message: `
                <b>${values?.name} (${user?.email})</b> has asked for support on
                <b>${values?.topic}</b> with a message</br> 
                <b>"${values?.message}"</b></br> 
                <b>Browser: ${browserName}</b></br> 
                <b>OS-Version: ${osName}-(${osVersion})</b></br> 
                <b>Device: ${deviceType}</b></br> 
                <b>IP: ${ip}</b>
                `,
            }

            const response = await emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_CONTACT_SUPPORT_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_PUBLIC_KEY
            )
            if (response.status === 200) {
                addToast({
                    title: 'Email Status!',
                    description: `Email Sent Successfully`,
                    status: 'success',
                    variant: 'left-accent',
                })
                await addDoc(collection(db, 'logs'), {
                    createdAt: serverTimestamp(),
                    name: values?.name,
                    topic: values?.topic,
                    message: values?.message,
                    type: 'support',
                    userEmail: user?.email || ' ',
                    ip, isBrowser, isMobile, browserName,
                    deviceType, engineName, engineVersion, osName, osVersion,
                })
                onClose()
                reset()
            }
            setIsSubmitting(false)
        } catch (error) {
            setIsSubmitting(false)
            console.log(error)
            addToast({
                title: 'Error',
                description: error?.message || error,
                status: 'error',
                variant: 'left-accent',
            })
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            blockScrollOnMount={false}
            onClose={() => {
                onClose()
                reset()
            }}
        >
            <ModalOverlay />
            <ModalContent as={'form'} onSubmit={handleSubmit(onSubmit)} mx={['10px', '5px', 0]}>
                <ModalHeader>
                    <Text fontSize={['20px']} color='brand.2'>
                        <chakra.span color='teal.500' fontWeight='bold' fontSize='20px'>
                            Contact
                        </chakra.span>{' '}
                        Support
                    </Text>
                    {/* Send Invite */}
                    <Text color='gray.500' fontSize='md' mt={2}>
                        Send your message to us.
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody px={['4', '6']}>
                    <SimpleGrid
                        columns={12}
                        spacing={[3]}
                        sx={{
                            'input, select, textarea': {
                                color: 'gray.700',
                            },
                        }}
                    >
                        <FormControl key={'name'} isInvalid={!!errors['name']} as={GridItem} colSpan={[12]}>
                            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                                Name
                            </FormLabel>
                            <Input
                                isInvalid={!!errors['name']}
                                {...inputProps}
                                {...register('name', {
                                    required: 'This is required',
                                })}
                            />
                            {errors['name'] && <FormErrorMessage>{errors['name']?.message}</FormErrorMessage>}
                        </FormControl>
                        <FormControl key={'topic'} isInvalid={!!errors['topic']} as={GridItem} colSpan={[12]}>
                            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                                Topic
                            </FormLabel>
                            <Input
                                isInvalid={!!errors['topic']}
                                {...inputProps}
                                {...register('topic', {
                                    required: 'This is required',
                                })}
                            />
                            {errors['topic'] && <FormErrorMessage>{errors['topic']?.message}</FormErrorMessage>}
                        </FormControl>

                        <FormControl isInvalid={!!errors?.message} as={GridItem} colSpan={[12]}>
                            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                Message:
                            </FormLabel>
                            <Textarea
                                {...inputProps}
                                placeholder={'Message'}
                                py={2}
                                noOfLines={4}
                                resize={"none"}
                                {...register('message', {
                                    required: 'This is required',
                                    maxLength: {
                                        value: 170,
                                        message: 'should be less than 170 characters',
                                    },
                                })}
                            />
                            {errors.message && <FormErrorMessage>{errors.message.message}</FormErrorMessage>}
                        </FormControl>
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter>
                    <Button variant='solid' type='submit' isLoading={isSubmitting}>
                        Send
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ContactSupportModal