import React, { useEffect } from 'react'
import { HStack, Icon, Text, chakra } from '@chakra-ui/react'
import { FaStar } from 'react-icons/fa'
import { useState } from 'react'
import { memo } from 'react'

const Rating = memo(({ total, value, setValue, showNumber = true, isStatic = false, size, activeStarColor }) => {

    const [hover, setHover] = useState(0)

    useEffect(() => {
        if (!isStatic && isNaN(value)) {
            setValue(0)
        }
    }, [value])

    return (
        <HStack spacing={2} alignItems="flex-start">
            <HStack spacing={1}>
                {[...Array(total).keys()].map(no => {
                    return (
                        <label key={no + 1}>
                            <chakra.input
                                type="radio"
                                name="rating"
                                value={value}
                                onClick={() => {
                                    !isStatic && setValue(no + 1)
                                }}
                                display="none"
                            />
                            <Icon
                                as={FaStar}
                                fontSize={size || ["20px", "25px"]}
                                color={((no + 1) <= (hover || value) ? (activeStarColor || "yellow.300") : "gray.200")}
                                onMouseEnter={() => !isStatic && setHover(no + 1)}
                                onMouseLeave={() => !isStatic && setHover(0)}
                                transition="color 0.2s ease-in"
                                cursor={isStatic ? "auto" : "pointer"}
                            />
                        </label>
                    )
                })}
            </HStack>
            {showNumber &&
                <Text fontSize={["15px", "18px"]} color="gray.500" pt={0}>
                    ({value || 0})
                </Text>
            }
        </HStack>
    )
})

export default Rating